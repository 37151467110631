import React from 'react';
import styles from "./hpt.module.css"
import {Button} from "../../atoms"
import {MdCloudDownload} from 'react-icons/md';
import {AiOutlinePlus} from 'react-icons/ai';
import {useNavigate} from "react-router"
import {CSVLink} from "react-csv";
import {hospitalHeaders} from "../../utils";
import {HospitalProps} from "../HospitalTable"
import HospitalPageTopSearchBar from "../../molecules/HospitalPageTopSearchBar/HospitalPageTopSearchBar";

const HospitalPageTop = ({hospitals, onSearch}: { hospitals: HospitalProps[], onSearch: (searchTerm: string) => void }) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <p className={styles.title}>Hospital List</p>

                <div className={styles.buttonGroup}>
                    <Button onClick={() => navigate("/dashboard/hospitals/add")}><AiOutlinePlus
                        style={{fontSize: "32px"}}/> &nbsp; Add new Hospital</Button>
                    <CSVLink data={hospitals} filename={"Dokitari Hospitals"} headers={hospitalHeaders}>
                        <Button variant="outline"><MdCloudDownload style={{fontSize: "32px"}}/> &nbsp; Download
                            Excel</Button>
                    </CSVLink>
                    <HospitalPageTopSearchBar onSearch={onSearch}/>
                </div>
            </div>
        </div>
    );
};

export default HospitalPageTop;
