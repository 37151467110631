import React from 'react';
import styles from "./InfoCard.module.css"

interface Props {
	icon: React.ReactNode;
	title?: string;
	cont:string | any | undefined;
}

const InfoCard = ({icon,title, cont}: Props) => {
    return (
        <div className={styles.card}>
        	<span className={styles.icon_button}>{icon}</span>
        	<div>
        		{title ? <p className={styles.title}>{title}</p> : null }
        		<p className={styles.cont}>{cont}</p>
        	</div>
        </div>
    );
};

export default InfoCard;
