import { useState, useEffect } from "react";
import styles from "./AHF.module.css";
import {
  EditFormInput,
  FormInput,
  LogoUpload,
  OperatingLicenceUpload,
  SelectInput,
} from "../../molecules";
import { AiTwotoneMail } from "react-icons/ai";
import { BsPhoneFill, BsPersonFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { Button } from "../../atoms";
import { UploadProps } from "antd";
import { Country, State } from "country-state-city";
import { Modal } from "antd";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

type Props = {
  uploading: boolean;
  url: any;
  logo: any;
  logoUpload: boolean;
  props: UploadProps;
  prop: UploadProps;
  errors: any;
  next: () => void;
  resetForm: () => void;
  addressLocation: string;
  setAddressLocation: any;
  setLongitude: any;
  setLatitude: any;
  setPlusCode: any;
  setCountry: any;
  setCity: any;
  country: any;
};
const StepOne = ({
  setLongitude,
  setLatitude,
  uploading,
  url,
  logo,
  logoUpload,
  props,
  prop,
  errors,
  next,
  resetForm,
  addressLocation,
  setAddressLocation,
  setCity,
  setCountry,
  country,
  setPlusCode,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [stateLongitude, setStateLongitude] = useState<number>(0);
  const [stateLatitude, setStateLatitude] = useState<number>(0);
  const [countryLongitude, setCountryLongitude] = useState<number>(0);
  const [countryLatitude, setCountryLatitude] = useState<number>(0);
  

  // mapboxgl.accessToken = process.env.MAP_ACCESS_TOKEN;

  useEffect(() => {
    console.log(Country.getAllCountries());
    console.log(State.getAllStates());
  }, []);
  useEffect(() => {
    if (modalOpen && !mapLoaded) {
      const map = new mapboxgl.Map({
        container: "map-modal",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [stateLongitude, stateLatitude],
        mapBounds: [
          [countryLongitude, countryLatitude],
          [countryLongitude, countryLatitude]
        ],
        zoom: 9,
      });

      const marker = new mapboxgl.Marker({
        draggable: true,
      });

      marker.setLngLat([stateLongitude, stateLatitude]).addTo(map);
      marker.on("dragend", () => {
        console.log("dragend");
        console.log("marker", marker.getLngLat());
        const lngLat = marker.getLngLat();
        setLongitude(lngLat.lng);
        setLatitude(lngLat.lat);
        getAddress(lngLat.lng, lngLat.lat).then((result: any) => {
          setAddressLocation(result.address);
          setPlusCode(result.plusCode);
        });
      });
      setMapLoaded(true);
    }
  }, [modalOpen, mapLoaded,stateLatitude,stateLongitude,countryLongitude,countryLatitude,setAddressLocation,setLongitude]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  mapboxgl.accessToken =
    "pk.eyJ1IjoicHJvbXl6IiwiYSI6ImNsZnFycDFwMTAxYnQzdG8wa2VxMzB3NHEifQ.QjkWvkquK7y4IvwGwhdIew";
  const getAddress = async (lng, lat) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
      );
      const data = await response.json();
      let address = data.features[0].place_name;
      let plusCode = data.features[0].properties.plus_code;
      return { address, plusCode };
    } catch (error) {
      console.error("Error fetching address:", error);
      return error;
    }
  };

  return (
    <>
      <form className={styles.form}>
        <div className={styles.imgfield}>
          <div className={styles.field_section_full}>
            <p className={styles.label}>Operating License</p>
            <div className={styles.field_section_gallery}>
              <OperatingLicenceUpload
                props={props}
                disabled={uploading}
                loading={uploading}
                backgroundImage={url?.url}
              />
            </div>
          </div>
          <div className={styles.field_section_full}>
            <p className={styles.label}>Hospital Logo</p>
            <div className={styles.field_section_gallery}>
              <LogoUpload
                props={prop}
                disabled={logoUpload}
                loading={logoUpload}
                backgroundImage={logo?.url}
              />
            </div>
          </div>
        </div>
        <div className={styles.field_section}>
          <div className={styles.form_field}>
            <p className={styles.label}>Hospital name</p>
            <EditFormInput icon={<BsPersonFill />} type="text" name="name" />
          </div>
          <div className={styles.form_field}>
            <p className={styles.label}>Hospital Email</p>
            <EditFormInput icon={<AiTwotoneMail />} type="text" name="email" />
          </div>
        </div>

        <div className={styles.field_section}>
          <div className={styles.form_field}>
            <p className={styles.label}>Contact Phone</p>
            <EditFormInput icon={<BsPhoneFill />} type="tel" name="telephone" />
          </div>
          <div className={styles.form_field}>
            <p className={styles.label}>Address</p>
            <EditFormInput icon={<MdLocationOn />} type="text" name="address" />
          </div>
        </div>
        <div className={styles.field_section}>
          <div className={styles.form_field}>
            <p className={styles.label}>Country</p>
            <SelectInput
              icon={<MdLocationOn />}
              options={Country.getAllCountries().map((item: any) => {
                return {
                  value: item.isoCode,
                  label: item.name,
                  longitude: item.latitude,
                  latitude: item.longitude,
                };
              })}
              bordered={false}
              onChange={(value) => {
                setCountry(value);
                const selectedCountry =  Country.getAllCountries().find((count) => count.isoCode === value);
                if(selectedCountry && selectedCountry.latitude && selectedCountry.longitude){
                  setCountryLatitude(+selectedCountry.latitude);
                  setCountryLongitude(+selectedCountry.longitude)
                }
              }}
            />
          </div>
          <div className={styles.form_field}>
            <p className={styles.label}>State</p>
            <SelectInput
              icon={<MdLocationOn />}
              options={State.getStatesOfCountry(country).map((item: any) => {
                return {
                  value: item.name,
                  label: item.name,
                  longitude: item.latitude,
                  latitude: item.longitude,
                };
              })}
              bordered={false}
              onChange={(value) => {
                setCity(value);
                const selectedState = State.getStatesOfCountry(country).find(
                  (state) => state.name === value
                );
                if (selectedState && selectedState.longitude && selectedState.latitude) {
                  setStateLongitude(+selectedState.longitude);
                  setStateLatitude(+selectedState.latitude);
                }
              }}
            />
          </div>
        </div>
        <div className={styles.field_section}>
          <div className={styles.form_field}>
            <p className={styles.label}>Hospital Location</p>
            <FormInput
              onClick={handleModalOpen}
              value={addressLocation}
              type={"text"}
              name={"addressLocation"}
              icon={<MdLocationOn />}
              disabled={!stateLongitude || !country}
            />
          </div>
          <div className={styles.form_field}>
            <p className={styles.label}>Lincense Information</p>
            <EditFormInput
              icon={<GiReceiveMoney />}
              type="text"
              name="licenseInformation"
            />
          </div>
        </div>
        <div className={styles.field_section_full}>
          <div className={styles.form_field}>
            &nbsp; &nbsp;
            <div className={styles.button_group}>
              <Button
                type="button"
                disabled={
                  errors.name ||
                  errors.email ||
                  errors.telephone ||
                  !addressLocation ||
                  errors.licenseInformation ||
                  !url.url
                }
                onClick={next}
              >
                Next
              </Button>
              <Button variant="ash" type="reset" onClick={resetForm}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        title={""}
        open={modalOpen}
        onCancel={handleModalClose}
        centered
        footer={""}
        maskStyle={{ zIndex: 10 }}
      >
        <div
          className="map-modal"
          style={{ height: "400px", minWidth: "100%", padding: "0" }}
        >
          <div
            id="map-modal"
            style={{ height: "400px", minWidth: "100%", padding: "0" }}
          />
        </div>
      </Modal>
    </>
  );
};

export default StepOne;
