import React from "react";

const Edit = ({
	height = "30px",
	width = "30px",
	color = "#007aff",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M28.2252 6.93347L23.3335 2.0168C23.0103 1.69519 22.5728 1.51465 22.1168 1.51465C21.6609 1.51465 21.2234 1.69519 20.9002 2.0168L3.5585 19.3335L1.97517 26.1668C1.92055 26.4166 1.92242 26.6754 1.98065 26.9244C2.03888 27.1734 2.15199 27.4062 2.31172 27.6059C2.47146 27.8056 2.67377 27.967 2.9039 28.0785C3.13402 28.1899 3.38614 28.2486 3.64184 28.2501C3.76098 28.2622 3.88103 28.2622 4.00017 28.2501L10.9085 26.6668L28.2252 9.3668C28.5468 9.04356 28.7273 8.60612 28.7273 8.15014C28.7273 7.69415 28.5468 7.25672 28.2252 6.93347ZM10.0752 25.1668L3.60017 26.5251L5.07517 20.1751L18.0502 7.25014L23.0502 12.2501L10.0752 25.1668ZM24.1668 11.0418L19.1668 6.0418L22.0668 3.15847L26.9835 8.15847L24.1668 11.0418Z"
				fill={color}
			/>
		</svg>
	);
};

export default Edit;
