import React from 'react';
import styles from "./logo.module.css"

const Logo = ({collapsed}:{collapsed?:any}) => {
    return (
        <p className={collapsed ? styles.collapsed_text : styles.text}>
            Dokitari&nbsp;<sup className={collapsed ? styles.collapsed_sup : styles.sup}>SEARCH</sup>
        </p>
    );
};

export default Logo;
