export interface Action {
    id: string;
    name: string;
    link: string;
    permissions: string[];
}

export const actions: Action[] = [
    {
        id: "1",
        name: "Overview",
        link: "/dashboard/overview",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "2",
        name: "Hospitals",
        link: "/dashboard/hospitals",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR","APPOINTMENT.READ.SELF"]
    },
    {
        id: "3",
        name: "Add Hospital",
        link: "/dashboard/hospitals/add",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER","SCOPE_DOCTOR","APPOINTMENT.READ.SELF"]
    },
    {
        id: "4",
        name: "Add Doctor",
        link: "dashboard/doctors/add",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER","SCOPE_DOCTOR"]
    },
    {
        id: "5",
        name: "Doctors",
        link: "/dashboard/doctors",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "6",
        name: "Providers",
        link: "/dashboard/providers",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "7",
        name: "Add Provider",
        link: "/dashboard/providers/add",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER","SCOPE_DOCTOR"]
    },
    {
        id: "8",
        name: "Reviews",
        link: "/dashboard/reviews",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "9",
        name: "Admin Users",
        link: "/dashboard/admin-users",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER","SCOPE_DOCTOR"]
    },
    {
        id: "10",
        name: "Add Admin User",
        link: "/dashboard/admin-users/add",
        permissions: ["SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER","SCOPE_DOCTOR"]
    },
    {
        id: "11",
        name: "Roles",
        link: "/dashboard/roles",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "12",
        name: "Add Role",
        link: "/dashboard/roles/add",
        permissions: ["ADMIN", "SUPER_ADMIN", "FRONT_DESK_ADMIN", "FRONT_DESK_MANAGER", "DOCTOR","SCOPE_DOCTOR"]
    },
    {
        id: "13",
        name: "Promocodes",
        link: "/dashboard/promocodes",
        permissions: ["SUPER_ADMIN", "PROMO_CODE_ADMIN"]
    },
    {
        id: "14",
        name: "Add Promocode",
        link: "/dashboard/promocodes/add",
        permissions: ["SUPER_ADMIN", "PROMO_CODE_ADMIN"]
    },
];
function getActionsForPermissions(action: Action, permissions: string[]): boolean {
    return permissions.some(permission => action.permissions.includes(permission));
}
    export function searchActions(query: string, permissions: string[]): Action[] {
    return actions.filter((action) => {
        const allowed = getActionsForPermissions(action, permissions);
        return (
            action.name.toLowerCase().includes(query.toLowerCase()) &&
            allowed
        );
    });
}

