import React, {useState,useEffect} from 'react';
import { UserPageTop, UnauthorizedModal } from "../../organisms"
import {fetchAllUsers,loadUserData} from "../../utils"
import UsersTable from "../../organisms/UserTable";
import {Props as DataProps} from "../../organisms/UserTable";
import { UserType } from "../../types/user"
import {useSearchParams} from "react-router-dom"

const UserPage = () => {
     const [param, setParam] = useSearchParams();
   const modal = param.get("modal")
   const [ isModalVisible, setIsModalVisible] = useState(modal === null ? false : true)
    const [ current, setCurrent ] = useState<number>(1)
    const [user, setUser ] = useState<UserType[]>([])
    const [data, setData] = useState<DataProps[]>([])
    const [ reload, setReload ] = useState<boolean>(false)
    const [ loading, setLoading ] = useState<boolean>(false)

    const [ paginationDetails, setPaginationDetails ] = useState({
        isFirstPage: true,
        isLastPage: false,
        currentPageNumber: 0,
        totalNumberOfPages: 0,
        totalNumberOfResults: 0
    })
    //fetch providers
    useEffect(()=> {
        setLoading(true)
        fetchAllUsers((current - 1)).then((res:any) => {
            console.log(res)
            setUser(res)
            setPaginationDetails({
                isFirstPage: res.first,
                isLastPage: res.last,
                currentPageNumber: res.number,
                totalNumberOfPages: res.totalPages,
                totalNumberOfResults: res.totalElements
            })
            setLoading(false)
        })
    },[current,reload])


    useEffect(()=> {
        loadUserData(user).then((res:any) => {
            setData(res)
        })
    },[user])

    const onCurrentPage = (page: number) => {
        setCurrent(page)
        setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1
        })
    }


    return (
        <div>
            <UserPageTop data={data} />
            <UsersTable
                data={data}
                reload={reload}
                setReload={setReload}
                paginationDetails={paginationDetails}
                tableLoading={loading}
                onCurrentPage={onCurrentPage}
            />
            <UnauthorizedModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={()=> setParam({})}
            />
        </div>
    );
};

export default UserPage;
