import React,{ useEffect, useState,useContext } from 'react';
import { PageTop,HospitalProfile } from "../../organisms";
import { useParams } from "react-router-dom"
import { fetchSingleHospital } from "../../utils"
import { HospitalType } from "../../types/hospital"
import {TitleContext} from "../../contexts/TitleContext";

const Hospital = () => {
    const {title,setTitle} =useContext(TitleContext)
    const { id } = useParams()
    const [ data, setData ] = useState<HospitalType>({} as HospitalType)
    useEffect(()=> {


        id && fetchSingleHospital(id).then((res:any)=> {
            setData(res)

            setTitle(res.name)
            window.document.title = title + " || Dokitari Admin Dashboard"
        })
    },[id,setTitle,title])

    return (
        <div>
            <PageTop
                name={data?.name}
                id={data?.id}
                backUrl="/dashboard/hospitals"
                title="Back to hospital"
                showDelete={false}
                editUrl={`/dashboard/hospitals/${data?.id}/edit`}
                pageType="hospital"
            />
            <HospitalProfile hospital={data}/>
        </div>
    );
};

export default Hospital;
