import { ProviderType } from "./types/provider";
import { DoctorCreationType, DoctorType } from "./types/doctor";
import { PromocodesType } from "./types/promocodes";
import ProviderImg from "./assets/Rectangle 56.svg";
import DoctorImg from "./assets/doctor.svg";
import { Props as DataProps } from "./organisms/ProviderTable";
import { Props as DoctorsDataType } from "./organisms/DoctorsTable";
import { Props as UserDataType } from "./organisms/UserTable";
import { Props as PromocodesDataType } from "./organisms/PromocodesTable";
import { DataType as AppointmentsType } from "./organisms/AppointmentsTable";
import { notification } from "antd";
import { DataType as ReviewsDataType } from "./organisms/ReviewsTables/AllReviews";
import ReviewImg from "./assets/reviewImage.svg";
import moment from "moment";
import { HospitalType } from "./types/hospital";
import { UserType } from "./types/user";
import { RoleType } from "./types/role";
import { AdminUserType } from "./types/adminUser";
import { Props as RoleDataTypes } from "./organisms/RoleTable";
import { RcFile } from "antd/lib/upload";
import axios from "axios";

//////////////////////////////// PROVIDERS UTILITIES /////////////////////////////////////////
const BASE_URL = "https://admin-dev-api.dokitari.com";
export async function fetchAllProviders(x: number, y: string) {
  const res = await axios.get(BASE_URL + "/api/providers?page=" + x + y, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchSingleProvider(id: string) {
  const res = await axios.get(BASE_URL + `/api/providers/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function editSingleProvider(id: string, body: ProviderType) {
  const res = await axios.patch(
    BASE_URL + `/api/providers/${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function activateProvider(type: string, id: string) {
  const res = await axios.put(BASE_URL + `/api/${type}s/${id}/activate`, null, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function addProvider(body: ProviderType) {
  const res = await axios.post(
    BASE_URL + "/api/providers",
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function loadTableData(providers: ProviderType[]) {
  let d = [] as DataProps[];

  for (let i = 0; i < providers.length; i++) {
    const newObj = {
      key: providers[i]?.id,
      photo: providers[i]?.photo_urls[0] || ProviderImg,
      provider: providers[i]?.name || "NA",
      orgType: providers[i]?.type || "NA",
      email: providers[i]?.email || "NA ",
      phone: providers[i]?.telephone || "NA",
      address: providers[i]?.address || "NA",
      status: providers[i]?.deactivated !== true ? "active" : "inactive",
    };
    d.push(newObj);
  }
  return d;
}

export const headers = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "PHOTO",
    key: "photo",
  },
  {
    label: "PROVIDER",
    key: "provider",
  },
  {
    label: "ORG TYPE",
    key: "orgType",
  },
  {
    label: "EMAIL",
    key: "email",
  },
  {
    label: "PHONE",
    key: "phone",
  },
  {
    label: "ADDRESS",
    key: "address",
  },
  {
    label: "STATUS",
    key: "status",
  },
];

export async function fetchAllTypes() {
  const res = await axios.get(BASE_URL + "/api/providers/types", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchAllDistricts() {
  const res = await axios.get(BASE_URL + "/api/providers/districts", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

//////////////////////////////// DOCTORS UTILITIES /////////////////////////////////////////

export async function fetchAllDoctors(x: number) {
  const res = await axios.get(BASE_URL + "/api/doctors?page=" + x, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchSingleDoctor(id: string) {
  const res = await axios.get(BASE_URL + `/api/doctors/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function editSingleDoctor(id: string, body: DoctorType) {
  const res = await axios.patch(
    BASE_URL + `/api/doctors/${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function addDoctor(id: string, body: DoctorCreationType) {
  const res = await axios.post(
    BASE_URL + `/api/doctors?hospital-id=${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function loadDoctorsData(doctors: DoctorType[]) {
  let arr = [] as DoctorsDataType[];
  for (let i = 0; i < doctors?.length; i++) {
    let name = doctors[i]?.firstName + " " + doctors[i]?.lastName;
    let address = doctors[i]?.city + ", " + doctors[i]?.country;
    arr.push({
      key: doctors[i]?.id,
      photo: doctors[i]?.pictureUrl?.url || DoctorImg,
      name: name || "NA",
      email: doctors[i]?.email || "NA",
      phone: doctors[i]?.phone || "NA",
      specialty: doctors[i]?.speciality || "NA",
      department: doctors[i]?.department || "NA",
      gender: doctors[i]?.gender || "NA",
      address: address || "NA",
      status: doctors[i]?.availabilitySetup || "inactive",
    });
  }
  return arr;
}

export function fetchDoctorAppointments() {
  let arr = [] as AppointmentsType[];
  for (let i = 0; i < 6; i++) {
    arr.push({
      key: i,
      name: "Tejumade Johnson",
      email: "tejumadejohn@gmail.com",
      phone: "(25678)281-1795",
      appointment: "22 Sep 2022",
      gender: "Male",
      dob: "24 Sep 1998",
    });
  }
  return arr;
}

export const doctorsHeaders = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "FIRST & LAST NAME",
    key: "name",
  },
  {
    label: "EMAIL",
    key: "email",
  },
  {
    label: "PHONE",
    key: "phone",
  },
  {
    label: "SPECIALTY",
    key: "specialty",
  },
  {
    label: "DEPARTMENT",
    key: "department",
  },
  {
    label: "GENDER",
    key: "gender",
  },
  {
    label: "ADDRESS",
    key: "address",
  },
  {
    label: "STATUS",
    key: "status",
  },
];

////////////////////////////////// Admin User Roles //////////////////////////////////////
export async function FetchAllUserRoles(x: number) {
  const res = await axios.get(BASE_URL + "/api/roles?page=" + x, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchSingleRole(id: string) {
  const res = await axios.get(BASE_URL + `/api/roles/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function addRole(id: string, body: string[]) {
  const res = await axios.put(
    BASE_URL + `/api/roles/${id}/members`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function addNewRole(body: any) {
  const res = await axios.post(BASE_URL + "/api/roles", JSON.stringify(body), {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function loadRolesData(role: RoleType[]) {
  let arr = [] as RoleDataTypes[];
  for (let i = 0; i < role?.length; i++) {
    arr.push({
      key: role[i]?.id,
      name: role[i]?.name || "NA",
      description: role[i]?.description || "NA",
      numberOfUsers: role[i]?.numberOfUsers || "NA",
    });
  }
  return arr;
}

export const roleHeaders = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "Name",
    key: "name",
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Number Of Users",
    key: "numberOfUsers",
  },
  {
    label: "STATUS",
    key: "status",
  },
];

//////////////////////////////// REVIEWS UTILITIES /////////////////////////////////////////

export async function fetchAllReviews(x: number, type?: string) {
  if (type === "") {
    const res = await axios.get(BASE_URL + "/api/ratings?page=" + x, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return await res.data;
  } else {
    const res = await axios.get(
      BASE_URL + "/api/ratings?page=" + x + "&status=" + type,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return await res.data;
  }
}

export async function fetchSingleProviderReview(
  id: string,
  x?: number,
  type?: string
) {
  if (!x && !type) {
    const res = await axios.get(BASE_URL + `/api/providers/${id}/ratings`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return await res.data;
  } else if (!type) {
    const res = await axios.get(
      BASE_URL + `/api/providers/${id}/ratings?page=${x}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return await res.data;
  } else {
    const res = await axios.get(
      BASE_URL + `/api/providers/${id}/ratings?page=${x}&status=${type}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    return await res.data;
  }
}

export async function fetchProviderRatingsOverview(id: string) {
  const res = await axios.get(
    BASE_URL + `/api/providers/${id}/ratings-overview`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function editReviewStatus(id: string, body: string) {
  const res = await axios.put(
    BASE_URL + `/api/providers/${id}/rating-status`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function loadReviewsData(reviews: any[]) {
  let arr = [] as ReviewsDataType[];
  for (let i = 0; i < reviews.length; i++) {
    let name =
      reviews[i]?.firstName === null || reviews[i]?.lastName === null
        ? "Anonymous User"
        : `${reviews[i]?.firstName} ${reviews[i]?.lastName}`;

    arr.push({
      key: reviews[i]?.id,
      photo: validUrl(reviews[i]?.imageUrl),
      postedBy: name,
      email: reviews[i]?.email || "NA",
      rating: reviews[i]?.value || null,
      comment: reviews[i]?.content || "NA",
      provider: reviews[i]?.provider?.name || "NA",
      submission: moment(reviews[i]?.createdAt).format("LT"),
      status: reviews[i]?.ratingStatus || "unknown",
      userId: reviews[i]?.userId,
      date: moment(reviews[i]?.createdAt).calendar(),
    });
  }
  return arr;
}

/////////////////////////////////// USERS UTILITIES ////////////////////////////////////////////

export async function fetchUser() {
  try {
    const response = await axios.get(`${BASE_URL}/api/users/current-user`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user:", error);

    throw error;
  }
}

export async function fetchUserReviewCount(id: string) {
  const res = await axios.get(BASE_URL + `/api/users/${id}/reviews-count`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchAllNotifications() {
  const res = await axios.get(BASE_URL + "/api/notifications", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

//////////////////////////////// HOSPITALS UTILITIES /////////////////////////////////////////
export async function fetchAllHospital(page: number, searchTerm?: string) {
  const endpoint = searchTerm ? "/api/hospitals/search" : "/api/hospitals";
  const url =
    endpoint + "?size=10&page=" + page + (searchTerm ? "&q=" + searchTerm : "");
  const res = await axios.get(BASE_URL + url, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchAllHospitalsIdAndName() {
  const res = await axios.get(BASE_URL + "/api/hospitals/name-and-id", {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function fetchNin(nin: string) {
    try {
      const res = await axios.get(BASE_URL + "/api/users/search-unassigned-user?nin=" + nin, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      if (res.status === 400 && res.data.errorMessage) {
        console.log(res.data.errorMessage);
        console.log("res.status");
        return res.data;
      }
      return res.data;
    } catch (error: any) {
      console.error("Error fetching NIN:", error);
      throw error;
    }
  }

export async function fetchDoctorNin(x: string) {
  const res = await axios.get(
    BASE_URL + "/api/doctors/search-unassigned-doctor?nin=" + x,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function addUser(body: AdminUserType) {
  const res = await axios.post(
    BASE_URL + "/api/users",
    JSON.parse(JSON.stringify(body)),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.errorMessage;
  }
  return responseBody;
}

export async function editHospitalAdmin(id: string, body: string) {
  const res = await axios.put(
    BASE_URL + `/api/hospitals/${id}/super-admin`,
    JSON.parse(JSON.stringify(body)),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.error || "An error occurred. Please try again later";
  }
  return responseBody;
}

export async function addHospitalDoctor(id: string, body: { id: string }) {
  const res = await axios.put(
    BASE_URL + `/api/hospitals/${id}/doctors`,
    JSON.parse(JSON.stringify(body)),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.error || "An error occurred. Please try again later";
  }
  return responseBody;
}

export async function editHospitalDoctors(id: string, body: string[]) {
  const res = await axios.put(
    BASE_URL + `/api/hospitals/${id}/doctors`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.error || "An error occurred. Please try again later";
  }
  return responseBody;
}

export async function editHospitalManager(id: string, body: string[]) {
  const res = await axios.put(
    BASE_URL + `/api/hospitals/${id}/frontdesk-managers`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.error || "An error occurred. Please try again later";
  }
  return responseBody;
}

export async function addFrontdeskManager(id: string, body: { id: string }) {
  const res = await axios.patch(
    BASE_URL + `/api/hospitals/${id}/frontdesk-manager`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  const responseBody = await res.data;
  if (res.status !== 200) {
    throw responseBody.errorMessage;
  }
  return responseBody;
}

export async function fetchSingleHospital(id: string) {
  const res = await axios.get(BASE_URL + `/api/hospitals/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function editSingleHospital(id: string, body: HospitalType) {
  const res = await axios.patch(
    BASE_URL + `/api/hospitals/${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function addHospital(body: HospitalType) {
  const res = await axios.post(
    BASE_URL + "/api/hospitals",
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export const hospitalHeaders = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "hospital name",
    key: "name",
  },
  {
    label: "Contact Email",
    key: "contactEmail",
  },
  {
    label: "PHONE",
    key: "contactPhone",
  },
  {
    label: "ADDRESS",
    key: "address",
  },
  {
    label: "License Information",
    key: "licenseInformation",
  },
];

//////////////////////////////// User /////////////////////////////////////////

export async function fetchAllUsers(x?: number) {
  const url = x ? "/api/admin-users?page=" + x : "/api/admin-users";
  const res = await axios.get(BASE_URL + url, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function addAdminUser(body: UserType) {
  const res = await axios.post(
    BASE_URL + "/api/admin-users",
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function fetchSingleUser(id: string) {
  const res = await axios.get(BASE_URL + `/api/admin-users/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function editSingleUser(id: string, body: UserType) {
  const res = await axios.patch(
    BASE_URL + `/api/admin-users/${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function loadUserData(user: UserType[]) {
  let arr = [] as UserDataType[];
  for (let i = 0; i < user.length; i++) {
    arr.push({
      key: user[i]?.id,
      firstName: user[i]?.firstName || "NA",
      lastName: user[i]?.lastName || "NA",
      email: user[i]?.email || "NA",
      roles: user[i]?.roles.join(", ") || "NA",
    });
  }
  return arr;
}

export const UserHeaders = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "FirstName",
    key: "firstName",
  },
  {
    label: "LastName",
    key: "lastName",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Role",
    key: "role",
  },
];
//////////////////////////////// PROMOCODES UTILITIES /////////////////////////////////////////

export const promocodesHeaders = [
  {
    label: "ID",
    key: "key",
  },
  {
    label: "PROMO NAME",
    key: "name",
  },
  {
    label: "CONSULTATION TYPE",
    key: "consultationType",
  },
  {
    label: "NUMBER OF USAGES",
    key: "numberOfUsage",
  },
  {
    label: "PROMO STATUS",
    key: "status",
  },
  {
    label: "PERCENTAGE",
    key: "percentage",
  },
  {
    label: "END DATE",
    key: "endDate",
  },
];

export async function fetchAllPromocodes(x: number) {
  const res = await axios.get(BASE_URL + "/api/promocodes?page=" + x, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function loadPromocodesData(promocodes: PromocodesType[]) {
  let arr = [] as PromocodesDataType[];
  for (let i = 0; i < promocodes?.length; i++) {
    arr.push({
      key: promocodes[i]?.id,
      name: promocodes[i]?.name,
      endDate: promocodes[i]?.endDate,
      numberOfUsage: promocodes[i]?.numberOfUsage,
      consultationType: promocodes[i]?.consultationType,
      percentage: promocodes[i]?.percentage,
      status: hasDateExpired(promocodes[i]?.endDate) ? "inactive" : "active",
    });
  }
  return arr;
}

export async function addPromocode(body: PromocodesType) {
  const res = await axios.post(
    BASE_URL + "/api/promocodes",
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

export async function fetchSinglePromocode(id: string) {
  const res = await axios.get(BASE_URL + `/api/promocodes/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
}

export async function editPromocode(id: string, body: PromocodesType) {
  const res = await axios.patch(
    BASE_URL + `/api/promocodes/${id}`,
    JSON.stringify(body),
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
  return await res.data;
}

//////////////////////////////// GENERAL UTILITIES /////////////////////////////////////////

export const uploadImage = async (image: RcFile) => {
  const data = new FormData();
  data.append("file", image!);
  const res = await axios.post(BASE_URL + "/api/image-upload", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  return await res.data;
};

export async function deleteAction(type: string, body: any) {
  if (type === "provider") {
    return await axios.put(
      BASE_URL + `/api/${type}s/${body.id}/deactivate`,
      JSON.stringify(body),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } else if (type === "admin-user") {
    return await axios.delete(
      BASE_URL + `/api/${type}s/${body.id}/deactivate`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  } else {
    return await axios.delete(BASE_URL + `/api/${type}s/${body.id}`, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
}

function validUrl(url: any) {
  if (url === null || url?.slice(0, 5) !== "https") {
    return ReviewImg;
  } else {
    return url;
  }
}

export const parseOpenHours = (str: any) => {
  if (str === null || str === "" || str === undefined) {
    return [];
  } else if (str === "24 hours") {
    return ["00:00", "23:59"];
  } else {
    return str.split("-");
  }
};

export const hoursCheck = (arr: any) => {
  if (arr.length === 2 && arr[0] === "00:00" && arr[1] === "23:59") {
    return "24 hours";
  } else if (arr.length === 0) {
    return "";
  } else {
    return arr.join("-");
  }
};

export const openNotification = (
  type: "success" | "info" | "warning" | "error",
  placement: any,
  message: string
) => {
  notification[type]({
    message: message,
    placement,
  });
};

const hasDateExpired = (date: string) => {
  let d = moment(date).format();
  let currentDateInSeconds = new Date().getTime() / 1000;
  let dateInSeconds = new Date(d).getTime() / 1000;
  return dateInSeconds <= currentDateInSeconds;
};

export async function fetchHospitalDepartments(hospitalId: string) {
  const res = await axios.get(
    BASE_URL + `/api/hospitals/${hospitalId}/departments/names-and-ids`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
  );
  return await res.data;
}

//////////////////////////////// AUTH UTILITIES /////////////////////////////////////////

export const login = async (body: any) => {
  const res = await axios.post(
    `${BASE_URL}/api/auth/login`,
    JSON.stringify(body),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const response = res.data;
    if (response?.errorMessage) {
      openNotification("error", "topRight", response?.errorMessage);
      return false;
    }
    if (response?.accessToken && response?.refreshToken) {
      localStorage.setItem("token", response.accessToken);
      localStorage.setItem("refreshToken", response.refreshToken);
    }
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const renewAuthToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const res = await axios.post(
    BASE_URL + "/api/auth/refresh-token?token=" + refreshToken,
    null,
    {
      headers: { "Content-type": "application/json" },
    }
  );
  const response = await res.data;
  if (response?.errorMessage) {
    openNotification("error", "topRight", response?.errorMessage);
    return false;
  }
  if (response?.accessToken && response?.refreshToken) {
    localStorage.setItem("token", response.accessToken);
    localStorage.setItem("refreshToken", response.refreshToken);
  }
  return true;
};

export const requestNewPassword = async (email: string) => {
  const res = await axios.post(
    `${BASE_URL}/api/auth/request-new-password`,
    JSON.stringify({ email: email }),
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
  return res.data;
};



/////////////////////////////////////// APPOINTMENT UTILITIES /////////////////////////////////////////

const APPOINTMENT_BASE_URL = "https://appointment-service-preprod.dokitari.com"

export const fetchAllAppointmentsByDoctor = async (doctorId: string|undefined) => {
  const res = await axios.get(`${APPOINTMENT_BASE_URL}/api/appointments/doctors/${doctorId}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
  });
  return res.data;
}