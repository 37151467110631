import React, {useContext, useEffect, useState} from "react";
import styles from "./adf.module.css";
import {DatePickerInput, EditFormInput, ImageUpload, SelectInput} from "../../molecules";
import {Button} from "../../atoms";
import {AiTwotoneMail} from "react-icons/ai";
import {BsPersonFill, BsPhoneFill} from "react-icons/bs";
import {IoMdMap} from "react-icons/io";
import {FaCalendarAlt, FaHospitalAlt} from "react-icons/fa";
import {RiBriefcase4Fill} from "react-icons/ri";
import {GiReceiveMoney} from "react-icons/gi"
import {MdLocationOn} from "react-icons/md"
import {HiAcademicCap} from "react-icons/hi"
import {Form, Formik} from "formik";
import moment, {Moment} from "moment"
import * as yup from "yup";
import {
	addDoctor,
	fetchAllHospitalsIdAndName,
	fetchHospitalDepartments,
	openNotification,
	uploadImage
} from "../../utils";
import type {UploadProps} from "antd";
import {Spin} from "antd";
import UserContext from "../../contexts/UserContext"
import {useNavigate} from "react-router-dom"
import {DoctorCreationType} from "../../types/doctor";
import {EditFormInputForNin} from "../../molecules/EditFormInput";


const AddDoctorForm = () => {

	const [url, setUrl] = useState<any>({} as any);
	const [birthDate, setBirthDate] = useState<Moment | null | string | undefined>(null);
	const [inductionDate, setInductionDate] = useState<Moment | null | string | undefined>(null);
	const [gender, setGender] = useState("");
	const [uploading, setUploading] = useState<boolean>(false);
	const [city, setCity] = useState("")
	const [country, setCountry] = useState("")
	const [specialty, setSpecialty] = useState("")
	const navigate = useNavigate();
	const [hospitalNamesAndIds, setHospitalNamesAndIds] = useState<{ id: string, name: string } []>([])
	const [selectedHospitalNameAndId, setSelectedHospitalNameAndId] = useState<{ id: string, name: string } | null>(null)
	const [departmentId, setDepartmentId] = useState("")
	const [hospitalDepartments, setHospitalDepartments] = useState<{ id: string, name: string } []>([])


	const props: UploadProps = {
		accept: "image/*",
		name: "file",
		multiple: true,
		beforeUpload(file) {
			setUploading(true);
			uploadImage(file).then((res: any) => {
				setUrl({
					id: res?.asset_id,
					uid: res?.public_id,
					name: res.original_filename,
					url: res?.secure_url,
					status: "success",
					type: res?.type,
					size: res?.bytes
				});
				setUploading(false);
			});
			return false;
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const validationSchema = yup.object().shape({
		first_name: yup.string().required("First name is required"),
		last_name: yup.string().required("Last name is required"),
		telephone: yup
			.string()
			.matches(/^\+?\d{1,3}[- ]?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/, 'Invalid telephone number')
			.required("Phone number is required"),
		email: yup
			.string()
			.email("Email is invalid")
			.required("Email is required"),
		nin: yup.string(),

		consultation_cost: yup
			.number()
			.typeError("Cost must be a number")
			.required("Cost is required")


	});

	const resetExtras = () => {
		setUrl({});
		setBirthDate(null)
		setInductionDate(null)
		setGender("")
		setCity("")
		setCountry("")
		setSpecialty("")
	};

	const {user} = useContext(UserContext)
	const genderOptions = ["Male", "Female"];
	const specialtyOptions = ["Clinical Medical Officer", "Counsellor", "Dentist", "Dermatologist", "Diabetologist", "Nutrition and fitness", "General practitioner", "General surgeon", "Obstetrician & Gynaecologist", "Nurse", "Herbal Health specialist", "Oncologist", "Ophthalmologist", "Orthopedic surgeon", "Paediatrician", "Pharmacist", "Plastic surgeon", "Physician", "Radiologist"]
	const cityOptions = ["Entebbe", "Gulu", "Kampala", "Mbarara", "Wakiso"]
	const countryOptions = ["Uganda"]

	useEffect(() => {

		fetchAllHospitalsIdAndName()
			.then((res: { id: string, name: string }[]) => {
				setHospitalNamesAndIds(res)
			})
			.catch(error => console.error(error))

	}, [])
	useEffect(() => {
		if (selectedHospitalNameAndId) {
			fetchHospitalDepartments(selectedHospitalNameAndId.id)
				.then((departments) => setHospitalDepartments(departments))
				.catch((error) => console.error(error));
		}
	}, [selectedHospitalNameAndId]);



	return (
		<Formik
			initialValues={{
				first_name: "",
				last_name: "",
				telephone: "",
				email: "",
				nin: "",
				departmentId: "",
				consultation_cost: Number(""),
				place_of_work: "",
				education: "",
				higher_education: "",
				specializations: "",
			}}
			validationSchema={validationSchema}
			onSubmit={(values, {setSubmitting, resetForm}) => {
				const data: DoctorCreationType = {
					email: values.email,
					userId: user?.id,
					firstName: values.first_name,
					lastName: values.last_name,
					country: country,
					city: city,
					speciality: specialty,
					specialisations: values.specializations,
					placeOfWork: selectedHospitalNameAndId!.name,
					educationalBackground: values.education,
					higherEducations: values.higher_education,
					costCurrency: "UGX",
					inductionDate: inductionDate,
					birthDate: birthDate,
					gender: gender,
					nin: values.nin,
					phone: values.telephone,
					departmentId: departmentId,
					standardSessionCost: (values.consultation_cost).toString(),
					pictureUrl: {
						id: url?.id,
						uid: url?.uid,
						name: url.name,
						url: url?.url,
						status: "success",
						type: url?.type,
						size: url?.size
					},
					hospitalId: selectedHospitalNameAndId!.id,
					availabilitySetup: true,
					errorMessage: ""
				};

				addDoctor(selectedHospitalNameAndId!.id, data)
					.then((res: any) => {
						setSubmitting(false);
						if (res?.errorMessage && res?.errorMessage !== "") {
							openNotification(
								"error",
								"bottomRight",
								res?.errorMessage || res?.errorr
							);
						} else {
							openNotification(
								"success",
								"bottomRight",
								"Doctor updated successfully"
							);
							resetForm();
							resetExtras();
							navigate("/dashboard/doctors")
						}
					})
					.catch((err: any) => {
						console.error(err)
						setSubmitting(false);
						openNotification(
							"error",
							"bottomRight",
							"An error occured. Please try again later"
						);
					});
			}}
		>
			{({isSubmitting, resetForm}) => (
				<Form className={styles.form}>
					<div className={styles.field_section_full}>
						<p className={styles.label}>Profile Picture</p>
						<div className={styles.field_section_gallery}>
							<ImageUpload
								props={props}
								disabled={uploading}
								loading={uploading}
								backgroundImage={url?.url}
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>First name</p>
							<EditFormInput
								icon={<BsPersonFill/>}
								type="text"
								name="first_name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Last name</p>
							<EditFormInput
								icon={<BsPersonFill/>}
								type="text"
								name="last_name"
							/>
						</div>
					</div>

					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Email</p>
							<EditFormInput
								icon={<AiTwotoneMail/>}
								type="email"
								name="email"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Contact Phone</p>
							<EditFormInput
								icon={<BsPhoneFill/>}
								type="tel"
								name="telephone"
							/>
						</div>
					</div>

					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>National Identity Number</p>
							<EditFormInputForNin
								icon={<BsPersonFill/>}
								type="text"
								name="nin"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Specialty</p>
							<SelectInput
								icon={<RiBriefcase4Fill/>}
								options={specialtyOptions.map((specialtyOption: any) => {
									return {
										value: specialtyOption.toLowerCase(),
										label: specialtyOption
									}
								})}
								bordered={false}
								onChange={(value) => {
									setSpecialty(value)
								}}
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Standard online consultation cost (UGX)</p>
							<EditFormInput
								icon={<GiReceiveMoney/>}
								type="text"
								name="consultation_cost"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Birth Date</p>
							<DatePickerInput
								name="birth_date"
								icon={<FaCalendarAlt/>}
								onChange={(val: any) => {
									setBirthDate(moment(val._d).format("DD.MM.YYYY"))
								}}
								value={birthDate === null ? null : moment(birthDate, "DD.MM.YYYY")}
								bordered={false}
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Induction Date</p>
							<DatePickerInput
								name="induction_date"
								icon={<FaCalendarAlt/>}
								onChange={(val: any) => {
									setInductionDate(moment(val._d).format("DD.MM.YYYY"))
								}}
								value={inductionDate === null ? null : moment(inductionDate, "DD.MM.YYYY")}
								bordered={false}
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Gender</p>
							<SelectInput
								icon={<BsPersonFill/>}
								options={genderOptions.map((genderOption: any) => {
									return {
										value: genderOption,
										label: genderOption
									}
								})}
								bordered={false}
								onChange={(value) => {
									setGender(value)
								}}
							/>
						</div>
					</div>

					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Country</p>
							<SelectInput
								icon={<IoMdMap/>}
								options={countryOptions.map((countryOption: any) => {
									return {
										value: countryOption.toLowerCase(),
										label: countryOption
									}
								})}
								bordered={false}
								onChange={(value) => {
									setCountry(value)
								}}
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>City</p>
							<SelectInput
								icon={<MdLocationOn/>}
								options={cityOptions.map((cityOption: any) => {
									return {
										value: cityOption.toLowerCase(),
										label: cityOption
									}
								})}
								bordered={false}
								onChange={(value) => {
									setCity(value)
								}}
							/>
						</div>
					</div>

					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Place of work</p>
							<SelectInput
								icon={<FaHospitalAlt/>}
								options={hospitalNamesAndIds.map((hospitalNameAndId) => ({
									...hospitalNameAndId,
									value: hospitalNameAndId.id,
									label: hospitalNameAndId.name
								}))}
								bordered={false}
								onChange={(value, selectedHospital: any) => {
									setSelectedHospitalNameAndId({id: value, name: selectedHospital.name})
									setDepartmentId("")
								}}

							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Department</p>
							<SelectInput
								icon={<RiBriefcase4Fill/>}
								options={hospitalDepartments.map((department) => {
									return {
										value: department.id,
										label: department.name
									}
								})}
								bordered={false}
								onChange={(value) => {
									setDepartmentId(value)
								}}
								disabled={!selectedHospitalNameAndId}
							/>
						</div>
					</div>

					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Educational background (Optional)</p>
							<EditFormInput
								icon={<HiAcademicCap/>}
								type="text"
								name="education"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Higher education (Optional)</p>
							<EditFormInput
								icon={<HiAcademicCap/>}
								type="text"
								name="higher_education"
							/>
						</div>
					</div>


					<div className={styles.field_section}>

						<div className={styles.form_field}>
							<p className={styles.label}>Specializations</p>
							<EditFormInput
								icon={<RiBriefcase4Fill/>}
								type="text"
								name="specializations"
							/>
						</div>
						&nbsp; &nbsp;
						<div className={styles.button_group}>
							<Button
								variant="ghost"
								style={{
									border: "1.5px solid #007AFF",
								}}
								type="submit"
								disabled={
									false
								}

							>
								{isSubmitting ? <Spin/> : "Save"}
							</Button>
							<Button
								variant="ash"
								type="reset"
								onClick={() => {
									resetForm();
									resetExtras();
								}}
							>
								Cancel
							</Button>
						</div>

					</div>

				</Form>
			)}
		</Formik>
	);
};

export default AddDoctorForm;
