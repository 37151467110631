import React from 'react';
import styles from "./dpt.module.css"
import { Button } from "../../atoms"
import DoctorsFilterDropdown  from"../FilterDropdown/DoctorsFilter"
import { MdCloudDownload } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from "react-router"
import { CSVLink } from "react-csv"
import { Props as DataProps } from "../DoctorsTable"
import { doctorsHeaders } from "../../utils"
import filter from "../../svg/Filter";

const DoctorsListTop = ({data,setFilter}:{data:DataProps[],setFilter:any}) => {
const navigate = useNavigate();

	const applyFilters = (status:string,gender:string,speciality:string) => {
			setFilter({...filter,status:status,gender:gender,speciality:speciality})
    }

    const resetFilters = () => {
        setFilter({...filter,status:"",gender:"",speciality:""})
    }
  
    return (
        <div className={styles.container}>
        	<div className={styles.left}>
	        	<p className={styles.title}>Doctors List</p>

	        	<div className={styles.buttonGroup}>
	        		<Button onClick={()=> navigate("/dashboard/doctors/add")}><AiOutlinePlus style={{fontSize:"32px"}}/> &nbsp; Add new doctor</Button>
	        		
	        		<CSVLink data={data} filename={"Dokitari Doctors"} headers={doctorsHeaders}>
	        			<Button variant="outline"><MdCloudDownload style={{fontSize:"32px"}}/> &nbsp; Download Excel</Button>
	        		</CSVLink>
	        	</div>
        	</div>

        	<DoctorsFilterDropdown applyFilters={applyFilters} resetFilters={resetFilters}/>
        </div>

    );
};

export default DoctorsListTop;
