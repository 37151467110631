import React,{ useEffect, useState } from 'react';
import { PageTop, PromocodeInfo } from "../../organisms";
import { useParams } from "react-router-dom"
import { fetchSinglePromocode } from "../../utils"
import { PromocodesType } from "../../types/promocodes"

const 	Promocode = () => {
	const { id } = useParams()
    const [ data, setData ] = useState<PromocodesType>({} as PromocodesType)

    useEffect(()=> {   
	    id && fetchSinglePromocode(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])
    return (
    	<div>
	        <PageTop 
	            name={data?.name} 
	            id={data?.id}
	            backUrl="/dashboard/promocodes"
	            title="Back to promocodes list"
	            showDelete={false}
	            editUrl={`/dashboard/promocodes/${data.id}/edit`}
	            pageType="promocode"
	        />

	        <PromocodeInfo promocode={data}/>
        </div>
    );
};


export default 	Promocode;
