import React, { useState, useEffect } from "react";
import styles from "./EditForm.module.css";
import {
	EditFormInput,
	EditFormTextarea,
	ImageUpload,
	TimePickerInput,
	EditFormCheckbox,
	ImageCard,
} from "../../molecules";
import { Home, Filter } from "../../svg";
import { Button } from "../../atoms";
import {
	AiTwotoneMail,
	AiOutlineTwitter,
	AiFillInstagram,
} from "react-icons/ai";
import { BsPhoneFill, BsGridFill } from "react-icons/bs";
import { IoMdMap } from "react-icons/io";
import { FaCompass, FaGlobe } from "react-icons/fa";
import { RiMapPinFill, RiWhatsappFill, RiTimeFill } from "react-icons/ri";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { TbWorldLongitude, TbWorldLatitude } from "react-icons/tb";
import { ProviderType } from "../../types/provider";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
	editSingleProvider,
	uploadImage,
	parseOpenHours,
	hoursCheck,
	openNotification,
} from "../../utils";
import type { UploadProps } from "antd";
import moment from "moment";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom"

const EditForm = ({ provider }: { provider: ProviderType }) => {
	const navigate = useNavigate();
	const [urls, setUrls] = useState<string[]>([]);
	const [sundayHours, setSundayHours] = useState([]);
	const [weekdayHours, setWeekdayHours] = useState([]);
	const [saturdayHours, setSaturdayHours] = useState([]);
	const [uploading, setUploading] = useState(false);

	const dummyRequest = async (options) => {
		const { onSuccess } = options;
		setTimeout(() => {
			onSuccess("OK");
		}, 0);
	};

	const props: UploadProps = {
		accept: "image/*",
		name: "file",
		multiple: true,
		customRequest: dummyRequest,
		beforeUpload(file) {
			setUploading(true);
			uploadImage(file).then((res: any) => {
				setUrls([...urls, res?.secure_url]);
				setUploading(false);
			});
			return false;
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	useEffect(() => {
		if (provider?.id !== "") {
			setUrls(provider?.photo_urls || []);
			setSundayHours(parseOpenHours(provider?.open_hours?.sunday));
			setWeekdayHours(parseOpenHours(provider?.open_hours?.monday_friday));
			setSaturdayHours(parseOpenHours(provider?.open_hours?.saturday));
		}
	}, [provider]);

	const resetExtras = () => {
		setUrls(provider?.photo_urls || []);
		setSundayHours(parseOpenHours(provider?.open_hours?.sunday));
		setWeekdayHours(parseOpenHours(provider?.open_hours?.monday_friday));
		setSaturdayHours(parseOpenHours(provider?.open_hours?.saturday));
	}

	const re =
		/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

	const validationSchema = yup.object().shape({
		name: yup.string(),
		address: yup.string(),
		telephone: yup.string(),
		email: yup.string().email("Email is invalid"),
		type: yup.string(),
		organisation_name: yup.string(),
		organisation_type: yup.string(),
		location_plus_code: yup.string(),
		region: yup.string(),
		district: yup.string(),
		country: yup.string(),
		website: yup.string().matches(re, "URL is not valid"),
		services_offered: yup.string(),
		special_services: yup.string(),
		description: yup.string(),
		facebook: yup.string().matches(re, "URL is not valid"),
		instagram: yup.string().matches(re, "URL is not valid"),
		twitter: yup.string().matches(re, "URL is not valid"),
		linkedin: yup.string().matches(re, "URL is not valid"),
		whatsapp: yup.string().matches(re, "URL is not valid"),
		longitude: yup.number().typeError("Longitude must be a number"),
		latitude: yup.number().typeError("Latitude must be a number"),
		promoted: yup.boolean(),
		key_person_ids: yup.string(),
	});

	return (
		<Formik
			initialValues={{
				name: provider?.name || "",
				address: provider?.address || "",
				telephone: provider?.telephone || "",
				email: provider?.email || "",
				type: provider?.type || "",
				location_plus_code: provider?.location_plus_code || "",
				region: provider?.region || "",
				district: provider?.district || "",
				country: provider?.country || "",
				website: provider?.website || "",
				description: provider?.description || "",
				facebook: provider.social_media_links?.facebook || "",
				instagram: provider.social_media_links?.instagram || "",
				twitter: provider.social_media_links?.twitter || "",
				linkedin: provider.social_media_links?.linkedin || "",
				whatsapp: provider.social_media_links?.whatsapp || "",
				photo_urls: provider?.photo_urls || [],
				services_offered: provider.services_offered?.join() || "",
				organisation_name: provider?.organisation_name || "",
				organisation_type: provider?.organisation_type || "",
				special_services: provider?.special_services?.join() || "",
				sunday: provider?.open_hours?.sunday || "",
				monday_friday: provider?.open_hours?.monday_friday || "",
				saturday: provider?.open_hours?.saturday || "",
				promoted: provider?.promoted || false,
				longitude: provider?.longitude?.toString() || "",
				latitude: provider?.latitude?.toString() || "",
				key_person_ids: provider.key_person_ids?.join() || "",
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				 console.log(resetForm)
				// setSubmitting(true)
				const data:ProviderType = {
					address: values.address,
					averageRating: null,
					country: values.country,
					deactivated: null,
					description: values.description,
					district: values.district,
					dokitari_id: null,
					email: values.email,
					location_plus_code: values.location_plus_code,
					name: values.name,
					numberOfRatings: null,
					open_hours: {
						sunday: hoursCheck(sundayHours),
						monday_friday: hoursCheck(weekdayHours),
						saturday: hoursCheck(saturdayHours),
					},
					organisation_name: values.organisation_name,
					organisation_type: values.organisation_type,
					photo_urls: urls,
					region: values.region,
					services_offered: values.services_offered.split(","),
					social_media_links: {
						facebook: values.facebook,
						whatsapp: values.whatsapp,
						linkedin: values.linkedin,
						twitter: values.twitter,
						instagram: values.instagram,
					},
					special_services: values.special_services.split(","),
					telephone: values.telephone,
					type: values.type,
					website: values.website,
					promoted: values.promoted,
					longitude: +values.longitude,
					latitude: +values.latitude,
					key_person_ids: values.key_person_ids.split(","),
				};

				editSingleProvider(provider.id as string, data)
					.then((res: any) => {
						console.log(res);
						setSubmitting(false);
						if(res?.errorMessage !== "" || res?.error){
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
                                res?.errorMessage || res?.errorr
                            );
                        } else{
							openNotification(
								"success",
								"bottomRight",
								"Provider updated successfully"
							);
							navigate("/dashboard/providers")
						}
					})
					.catch((err: any) => {
						console.log(err)
						setSubmitting(false);
						openNotification(
							"error",
							"bottomRight",
							"An error occured. Please try again later"
						);
					});
			}}
		>
			{({ errors, isSubmitting, resetForm }) => (
				<Form className={styles.form}>
					{console.log(provider)}
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Provider name</p>
							<EditFormInput
								icon={<Home color="#007AFF" />}
								type="text"
								name="name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Organization type</p>
							<EditFormInput
								icon={<Filter color="#007AFF" fillOpacity="1" />}
								type="text"
								name="type"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Organisation name</p>
							<EditFormInput
								icon={<Home color="#007AFF" />}
								type="text"
								name="organisation_name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Organisation type</p>
							<EditFormInput
								icon={<Filter color="#007AFF" fillOpacity="1" />}
								type="text"
								name="organisation_type"
							/>
						</div>
					</div>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Address</p>
							<EditFormInput
								icon={<RiMapPinFill />}
								type="text"
								name="address"
							/>
						</div>
					</div>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Location plus code</p>
							<EditFormInput
								icon={<BsGridFill />}
								type="text"
								name="location_plus_code"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Country</p>
							<EditFormInput icon={<IoMdMap />} type="text" name="country" />
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Region</p>
							<EditFormInput icon={<IoMdMap />} type="text" name="region" />
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>District</p>
							<EditFormInput icon={<FaCompass />} type="text" name="district" />
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Website</p>
							<EditFormInput icon={<FaGlobe />} type="text" name="website" />
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Email</p>
							<EditFormInput
								icon={<AiTwotoneMail />}
								type="email"
								name="email"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Telephone</p>
							<EditFormInput
								icon={<BsPhoneFill />}
								type="tel"
								name="telephone"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Longitude</p>
							<EditFormInput icon={<TbWorldLongitude />} name="longitude" />
						</div>
						&nbsp; &nbsp;
						<div className={styles.form_field}>
							<p className={styles.label}>Latitude</p>
							<EditFormInput icon={<TbWorldLatitude />} name="latitude" />
						</div>
						&nbsp; &nbsp;
						<div className={styles.form_field}>
							<EditFormCheckbox
								className={styles.checkbox}
								name="promoted"
								id="promoted"
								label="Promotion on Dokitari Search?"
							/>
						</div>
					</div>
					<hr
						style={{
							border: "1px solid rgba(31, 31, 31, 0.4)",
							marginBottom: "20px",
						}}
					/>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Key persons ids(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="key_person_ids"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Services offered(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="services_offered"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Special services(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="special_services"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Description</p>
							<EditFormTextarea rows={6} name="description"></EditFormTextarea>
						</div>
					</div>

					<hr
						style={{
							border: "1px solid rgba(31, 31, 31, 0.4)",
							marginBottom: "20px",
						}}
					/>
					<div className={styles.field_section_gallery}>
						<ImageUpload
							props={props}
							disabled={urls.length === 4 || uploading ? true : false}
							loading={uploading}
						/>
						<div className={styles.image_gallery}>
							{urls.length === 0
								? null
								: urls.map((url: string, id: any) => (
										<ImageCard
											key={id}
											url={url}
											deleteImage={() => setUrls(urls.filter((x) => x !== url))}
										/>
								  ))}
						</div>
					</div>

					<div className={styles.field_section_full}>
						<p className={styles.label}>Open hours</p>
						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<p className={styles.label}>Sunday</p>
								<TimePickerInput
									name="sunday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									value={
										sundayHours.length === 0
											? [null, null]
											: [
													moment(sundayHours[0], "HH:mm"),
													moment(sundayHours[1], "HH:mm"),
											  ]
									}
									onChange={(times: any, timeStrings: any) => {
										setSundayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<p className={styles.label}>Monday - Friday</p>
								<TimePickerInput
									name="monday_friday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									value={
										weekdayHours.length === 0
											? [null, null]
											: [
													moment(weekdayHours[0], "HH:mm"),
													moment(weekdayHours[1], "HH:mm"),
											  ]
									}
									onChange={(times: any, timeStrings: any) => {
										setWeekdayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<p className={styles.label}>Saturday</p>
								<TimePickerInput
									name="saturday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									value={
										saturdayHours.length === 0
											? [null, null]
											: [
													moment(saturdayHours[0], "HH:mm"),
													moment(saturdayHours[1], "HH:mm"),
											  ]
									}
									onChange={(times: any, timeStrings: any) => {
										setSaturdayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<p className={styles.label}>Social media links</p>
						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<GrFacebookOption />}
									type="text"
									name="facebook"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<EditFormInput
									icon={<AiOutlineTwitter />}
									type="text"
									name="twitter"
								/>
							</div>
						</div>

						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<AiFillInstagram />}
									type="text"
									name="instagram"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<EditFormInput
									icon={<GrLinkedinOption />}
									type="text"
									name="linkedin"
								/>
							</div>
						</div>

						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<RiWhatsappFill />}
									type="text"
									name="whatsapp"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.button_group}>
								<Button
									variant="ghost"
									style={{
										border: "1.5px solid #007AFF",
									}}
									type="submit"
									disabled={
										Object.keys(errors).length > 0 ||
										isSubmitting ||
										sundayHours.length === 1 ||
										weekdayHours.length === 1 ||
										saturdayHours.length === 1
											? true
											: false
									}
								>
									{isSubmitting ? <Spin /> : "Save"}
								</Button>
								<Button 
									variant="ash" 
									type="reset" 
									onClick={() => {
										resetForm();
										resetExtras();
									}}>
									Cancel
								</Button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default EditForm;
