import React from 'react';
import ModalContainer from "./ModalContainer"
import { Button, PasswordInput } from "../../atoms"
import { Formik, Form } from "formik";
import * as yup from "yup";
import { deleteAction, openNotification } from "../../utils"
import { LoadingOutlined } from "@ant-design/icons"

interface Props {
		isModalVisible: boolean;
		setIsModalVisible: any;
      ids: string[];
      callback:any;
      type:string;
}

const DeleteModal = ({isModalVisible, setIsModalVisible, ids, callback, type}: Props) => {

   const validationSchema = yup.object().shape({
    password: yup
      .string()
      .min(3, "Must be at least 3 characters")
      .max(15, "Must be 15 characters or less")
      .required("Password is required"),
  });

    return (
        <ModalContainer isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
            <Formik
               initialValues={{
                 password: "",
               }}
               validationSchema={validationSchema}
               onSubmit={async ({ password },{ setSubmitting }) => {
                  for(let i=0; i<ids.length; i++){
                     let body = {
                        id: ids[i]
                     }
                     await deleteAction(type, body).then(()=>{
                        openNotification(
                          "success",
                          "bottomRight",
                          `${type} deactivated successfully`
                        );
                        setSubmitting(false)
                          setIsModalVisible(false)
                          callback()
                     })
                     .catch((err:any)=> {
                        openNotification(
                         "error",
                         "bottomRight",
                         "An error occured. Please try again later"
                       );
                        setSubmitting(false)
                          setIsModalVisible(false)
                          callback()
                     })
                    
                  }
                 
               }}
             >
               {({ errors, isSubmitting, setFieldValue }) => (
                  <Form>
                     <p style={{
                        fontWeight: "700",
                        fontSize: "28px",
                     }}> Please enter your password to proceed.</p>
                     <p style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        marginTop:"20px"
                     }}
                     >After entering your password, click on the confirm button to delete the {type}</p>
                     <PasswordInput variant="center"  label="Password" name="password"/>

                     <div style={{marginTop:"20px"}}>
                        <Button 
                           variant="secondary" 
                           style={{padding:"15px", width:"40%", margin:"auto"}} 
                           type="submit"
                        >
                        {isSubmitting ? <LoadingOutlined/> : "Confirm"}
                        </Button>
                     </div>
                  </Form>
               )}
            </Formik>
          </ModalContainer>
    );
};

export default 	DeleteModal;
