import React from 'react';
import {DatePicker } from "antd";
import styles from "./Filter.module.css"
import moment from "moment"
import { DropdownIcon } from "../../svg"

type TimeFilterProps = {
	selectedTime: string,
	setSelectedTime: any,
}

const 	TimeFilter = ({selectedTime, setSelectedTime}: TimeFilterProps) => {

     const onChange=(val)=> {
        setSelectedTime(moment(val._d).format("YYYY-MM-DD"))
     }   

    return (
        <div className={styles.filterMenu_items}>
            <div className={styles.filter_items}>
                <p>Date created</p>
                <DatePicker 
                    onChange={onChange} 
                    bordered={false} 
                    className={styles.picker} 
                    placeholder="Select date created"
                    suffixIcon={<DropdownIcon/>}
                    style={{
                        padding:"0px",
                        marginTop:"15px",


                    }}
                    value={selectedTime === null ? null : moment(selectedTime,"YYYY-MM-DD")}
                /> 
            </div>
        </div>
    );
};

	
export default 	TimeFilter;
