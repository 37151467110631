import React from "react";

const Home = ({
	height = "32px",
	width = "32px",
	color = "white",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M29.5777 15.7814L16.7059 2.91892C16.6132 2.82606 16.5031 2.75238 16.3819 2.70212C16.2607 2.65185 16.1308 2.62598 15.9996 2.62598C15.8684 2.62598 15.7385 2.65185 15.6173 2.70212C15.4961 2.75238 15.386 2.82606 15.2934 2.91892L2.42148 15.7814C2.04648 16.1564 1.83398 16.6658 1.83398 17.197C1.83398 18.3002 2.73086 19.197 3.83398 19.197H5.19023V28.3752C5.19023 28.9283 5.63711 29.3752 6.19023 29.3752H13.9996V22.3752H17.4996V29.3752H25.809C26.3621 29.3752 26.809 28.9283 26.809 28.3752V19.197H28.1652C28.6965 19.197 29.2059 18.9877 29.5809 18.6095C30.359 17.8283 30.359 16.5627 29.5777 15.7814V15.7814Z"
				fill={color}
			/>
		</svg>
	);
};

export default Home;
