import React from 'react';
import {Dropdown, Menu } from "antd";
import { TickIcon, DropdownIcon } from "../../svg"
import styles from "./Filter.module.css"

type LocationFilterProps = {
    selectedLocation: string,
    setSelectedLocation: any,
    districts: string[],
}

const  LocationFilter = ({selectedLocation, setSelectedLocation, districts}: LocationFilterProps) => {

    const locationList : string[] = districts

    const locationOptionsMenu = (
        <Menu>
            {locationList?.map((location) => {
                    return (<Menu.Item key={location}>
                        <div className={styles.menu_items} onClick={() => setSelectedLocation(location)}>
                            <span>{location}</span>
                            <span className={styles.items_tick}><TickIcon/></span>
                        </div>
                    </Menu.Item>)
                }
            )
            }
        </Menu>
    );

    return (
        <div className={styles.filterMenu_items}>
            <div className={styles.filter_items}>

                <p>Location</p>
                <Dropdown overlay={locationOptionsMenu} trigger={['click']}>
                    <div onClick={e=> e.preventDefault()} className={styles.flex}>
                    <span>{selectedLocation || "Select provider location"}</span>
                   <DropdownIcon/>
                   </div>
               </Dropdown>
                
            </div>
        </div>
    );
};

    
export default  LocationFilter;
