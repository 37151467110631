import React from "react";

const TickIcon = ({
	height = "10px",
	width = "10px",
	color = "#1f1f1f",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.5 0L4.2 10.2273L1.5 8.18225H0L4.2 15L15 0H13.5Z"
				fill={color}
				fillOpacity="0.75"
			/>
		</svg>
	);
};

export default TickIcon;
