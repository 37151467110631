import React from "react";

const Doctor = ({
	height = "39px",
	width = "35px",
	color = "currentColor",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 35 39"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.862 1.79395C13.2605 1.20066 14.647 1 15.5197 1H15.5494L15.5789 1.00328C19.7079 1.46205 21.8804 4.62681 22.4621 6.17822L22.4963 6.26934V16.593L22.493 16.6226C22.2589 18.7295 21.1333 20.2362 19.8508 21.2507C18.5773 22.2581 17.1279 22.7996 16.1616 22.9929L16.1095 23.0033H16.0563C10.5522 23.0033 8.40031 18.7785 8.015 16.6593L8.00635 16.6117V5.1508L8.07706 5.02706C9.00991 3.39457 10.4596 2.38892 11.862 1.79395ZM9.07968 5.43919V16.5135C9.43135 18.3378 11.2828 21.9029 16.0025 21.9298C16.8266 21.756 18.0844 21.2794 19.1849 20.4089C20.2934 19.532 21.2208 18.275 21.423 16.5329V6.46731C20.8832 5.12824 18.9917 2.47628 15.4902 2.07342C14.7495 2.07801 13.5226 2.25537 12.2812 2.78204C11.0642 3.29835 9.86637 4.13548 9.07968 5.43919Z"
				fill={color}
				stroke={color}
				strokeWidth="0.45"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.3484 17.0463C21.5533 16.141 21.5659 15.1993 21.3851 14.286C21.2044 13.3728 20.8349 12.5107 20.3026 11.7587C19.7704 11.0067 19.0885 10.3831 18.3046 9.92962C17.5207 9.47615 16.6531 9.2033 15.7613 9.12876C14.8695 9.05423 13.9736 9.17965 13.1346 9.49667C12.2956 9.81371 11.5325 10.3152 10.8983 10.9679C10.264 11.6206 9.77345 12.4091 9.46151 13.28C9.14955 14.1509 9.02383 15.0829 9.09352 16.0118L8.0232 16.0921C7.94232 15.0141 8.08808 13.9314 8.45104 12.9181C8.81402 11.9047 9.38589 10.984 10.1285 10.2198C10.8712 9.45559 11.7671 8.866 12.7552 8.49263C13.7433 8.11925 14.7994 7.9713 15.8507 8.05916C16.9019 8.14702 17.9224 8.46851 18.8421 9.00055C19.7617 9.53255 20.5585 10.2623 21.1787 11.1386C21.799 12.015 22.2283 13.0176 22.4381 14.0777C22.6478 15.1377 22.6333 16.2311 22.3953 17.2831L21.3484 17.0463Z"
				fill={color}
				stroke={color}
				strokeWidth="0.45"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.6784 21.7707L12.0522 22.3969L11.932 22.437C11.4413 22.6006 10.9739 22.7842 10.5286 22.9856L15.1519 27.6089L19.7252 23.0356C19.0644 22.7442 18.4805 22.5506 18.0721 22.4485L18.3324 21.4072C19.0971 21.5984 20.3246 22.0468 21.5845 22.7786C22.8434 23.5099 24.1693 24.5443 25.0888 25.9235L24.1957 26.5189C23.3979 25.3221 22.2194 24.3887 21.0454 23.7067C20.944 23.6479 20.843 23.591 20.7425 23.5362L15.6886 28.5901V34.8078H23.0323V35.8812H1L1.02973 35.3163C1.19672 32.1434 3.12441 25.5852 9.36906 22.3549C10.0228 22.0167 10.7228 21.7158 11.4716 21.4596L11.9195 21.0117L12.6784 21.7707ZM14.6153 34.8078V28.5901L9.51775 23.4926C4.34128 26.3593 2.48247 31.7654 2.1481 34.8078H14.6153ZM7.08949 30.3573L7.84845 29.5984L9.61563 31.3655L11.3828 29.5984L12.1418 30.3573L9.61563 32.8835L7.08949 30.3573Z"
				fill={color}
				stroke={color}
				strokeWidth="0.45"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M27.101 28.3981L25.1732 22.4648L23.2454 28.3981H17.0068L22.0539 32.065L20.1261 37.9982L25.1732 34.3313L30.2203 37.9982L28.2925 32.065L33.3396 28.3981H27.101ZM30.0362 29.4714H26.3212L25.1732 25.9382L24.0252 29.4714H20.3102L23.3157 31.655L22.1677 35.1882L25.1732 33.0046L28.1787 35.1882L27.0307 31.655L30.0362 29.4714Z"
				fill={color}
				stroke={color}
				strokeWidth="0.45"
			/>
		</svg>
	);
};

export default Doctor;
