import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom"
import {ModifyRoleForm,AddRoleUserTop} from "../../organisms";
import {fetchAllUsers, fetchSingleRole} from "../../utils"
import {RoleType} from "../../types/role";
import {UserType} from "../../types/user";
import styles from "../../organisms/AddUserToRole/AUTR.module.css";
import {Spin} from "antd";

const UpdateUserToRole = () => {
    const {id} = useParams()
    const [role, setRole ] = useState<RoleType>({} as RoleType)
    const [data, setData] = useState<UserType[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(()=> {
        id && fetchSingleRole(id).then((res:any)=> {
            setRole(res)
            console.log(res)
        })
    },[id])
    useEffect(() => {
        fetchAllUsers().then((res: any) => {
            console.log(res)
            setData(res)
            setLoading(false)
        })
    }, [])

    return (
        loading ? <div className={styles.loader}><Spin/></div>
            :
            <div style={{borderRadius: "15px", width: "100%", backgroundColor: "#ffffff"}}>
                <AddRoleUserTop
                    name={role.name}
                    backUrl="/dashboard/roles"
                    title="Back to Role List"
                    text={"Add user to"}
                   />
                <ModifyRoleForm data={data} role={role} loading={loading}/>
            </div>
    );
};

export default UpdateUserToRole;
