import React, { useContext } from "react";
import { FilterDoctorsProvider } from "../../contexts/FilterDoctorsContext";
import { Outlet } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized";
import {Helmet} from "react-helmet";


const Doctors = () => {
  const { user } = useContext(UserContext);


  return user.permissions?.includes("DOCTOR.READ.ALL") ||
    user.permissions?.includes("DOCTOR.CREATE.ALL") ||
    user.permissions?.includes("DOCTOR.UPDATE.ALL") ||
    user.permissions?.includes("DOCTOR.DELETE.ALL") ? (

    <FilterDoctorsProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Doctors || Dokitari Admin Dashboard</title>
        <link rel="canonical" href="/doctors" />
      </Helmet>
      <Outlet />
    </FilterDoctorsProvider>
  ) : (
    <UnauthorizedPage />
  );
};

export default Doctors;
