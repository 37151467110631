import React from "react";
import styles from "./AHF.module.css";
import { AiTwotoneMail } from "react-icons/ai";
import { BsPhoneFill, BsPersonFill } from "react-icons/bs";
import { DatePickerInput, SelectInput } from "../../molecules";
import {FaCalendarAlt} from "react-icons/fa";
import { Spin } from "antd";
import { Button } from "../../atoms";
import moment, {Moment} from "moment"
const StepTwo = ({
  handleAdminNameChange,
  handleAdminLastNameChange,
  handleAdminPhoneChange,
  handleNin,
  handleNinChange,
  handleAdminEmailChange,
  nin,
  firstName,
  lastName,
  adminNumber,
  loading,
  isNinValidated,
  adminEmail,
  id,
  ninError,
  next,
  prev,
  setGender,
  gender,
  birthDate,
  setBirthDate,
}) => {
  const shouldCreateNewUser =
    !isNinValidated && ninError === "No users by the passed NIN";
  const shouldAddExistingUser = !!id;
  const genderOptions = ["Male", "Female"];

  return (
    <form className={styles.form}>
      <div>
        <div className={styles.field_section}>
          <div className={styles.form_field}>
            <p className={styles.label}>National Identification Number</p>
            <div className={styles.nin_container}>
              <input
                className={styles.nin_input}
                type={"text"}
                value={nin}
                onChange={handleNinChange}
                name={"nin"}
              />
              <button onClick={handleNin} className={styles.nin_button}>
                {loading ? <Spin /> : "Check NIN"}
              </button>
            </div>
          </div>
        </div>
        {ninError ? (
          <p style={{ color: "red", fontSize: "15px", padding: "10px" }}>
            {ninError}
          </p>
        ) : null}
        {(shouldCreateNewUser || shouldAddExistingUser) && (
          <>
            <div className={styles.field_section}>
              <div className={styles.form_field}>
                <p className={styles.label}>First Name</p>
                <div className={styles.container}>
                  <span className={styles.icon_button}>
                    <BsPersonFill />
                  </span>
                  <input
                    className={styles.input}
                    type={"text"}
                    value={firstName}
                    onChange={handleAdminNameChange}
                    name={"firstName"}
                    disabled={shouldAddExistingUser}
                  />
                </div>
              </div>
              <div className={styles.form_field}>
                <p className={styles.label}>Last Name</p>
                <div className={styles.container}>
                  <span className={styles.icon_button}>
                    <BsPersonFill />
                  </span>
                  <input
                    className={styles.input}
                    type={"text"}
                    value={lastName}
                    onChange={handleAdminLastNameChange}
                    name={"lastName"}
                    disabled={shouldAddExistingUser}
                  />
                </div>
              </div>
            </div>
            <div className={styles.field_section}>
              <div className={styles.form_field}>
                <p className={styles.label}>Email</p>
                <div className={styles.container}>
                  <span className={styles.icon_button}>
                    <AiTwotoneMail />
                  </span>
                  <input
                    className={styles.input}
                    type={"text"}
                    value={adminEmail}
                    onChange={handleAdminEmailChange}
                    name={"adminEmail"}
                    disabled={shouldAddExistingUser}
                  />
                </div>
              </div>
              <div className={styles.form_field}>
                <p className={styles.label}>Phone Number</p>
                <div className={styles.container}>
                  <span className={styles.icon_button}>
                    <BsPhoneFill />
                  </span>
                  <input
                    className={styles.input}
                    type={"text"}
                    value={adminNumber}
                    onChange={handleAdminPhoneChange}
                    name={"adminNumber"}
                    disabled={shouldAddExistingUser}
                  />
                </div>
              </div>
            </div>
            <div className={styles.field_section}>
              <div className={styles.form_field}>
                <p className={styles.label}>Birth Date</p>
                <DatePickerInput
                  name="birth_date"
                  icon={<FaCalendarAlt />}
                  onChange={(val: any) => {
                    setBirthDate(moment(val._d).format("DD.MM.YYYY"));
                  }}
                  value={
                    birthDate === null ? null : moment(birthDate, "DD.MM.YYYY")
                  }
                  bordered={false}
                />
              </div>
              <div className={styles.form_field}>
                <p className={styles.label}>Gender</p>
                <SelectInput
                  icon={<BsPersonFill />}
                  options={genderOptions.map((genderOption: any) => {
                    return {
                      value: genderOption,
                      label: genderOption,
                    };
                  })}
                  bordered={false}
                  onChange={(value) => {
                    setGender(value);
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.field_section_full}>
        <div className={styles.form_field}>
          &nbsp; &nbsp;
          <div className={styles.button_group}>
            <Button type="button" variant="ash" onClick={prev}>
              Previous
            </Button>
            <Button
              type="button"
              disabled={!firstName || !adminEmail || !lastName}
              onClick={next}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwo;
