import React from 'react';
import styles from "./PromocodeInfo.module.css"
import { FiPercent } from 'react-icons/fi';
import { FcExpired } from 'react-icons/fc';
import { MdOutlineMeetingRoom, MdDataUsage } from "react-icons/md"
import { TbDiscount } from "react-icons/tb"
import { PromocodesType } from "../../types/promocodes"
import { InfoCard } from "../../molecules"

const PromocodeInfo = ({promocode}:{promocode:PromocodesType}) => {
    return (
        <div className={styles.container}>
	        <div className={styles.section}>
	        	<InfoCard icon={<TbDiscount />} title="Promo name" cont={promocode?.name || "NA"}/>
	        	<InfoCard icon={<MdOutlineMeetingRoom />} title="Consultation type" cont={promocode?.consultationType|| "NA"}/>
	        </div>


	        <div className={styles.section}>
	        	<InfoCard icon={<FiPercent/>} title="Percentage" cont={promocode?.percentage || "NA"}/>
	        	<InfoCard icon={<FcExpired/>} title="End Date" cont={promocode?.endDate|| "NA"}/>
	        	<InfoCard icon={<MdDataUsage/>} title="Usages" cont={(promocode?.numberOfUsage !== undefined? promocode?.numberOfUsage :"NA" ).toString() || "NA"}/>
	        </div>
	    </div>
    );
};


export default PromocodeInfo;
