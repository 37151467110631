import React from 'react';
import styles from "./ProviderInfo.module.css"
import { InfoCard } from "../../molecules"
import { Home, Filter } from "../../svg"
import { AiTwotoneMail, AiOutlineTwitter, AiFillInstagram } from "react-icons/ai"
import { BsPhoneFill, BsGridFill } from "react-icons/bs"
import { IoMdMap } from "react-icons/io"
import { FaCompass, FaGlobe } from "react-icons/fa"
import { RiMapPinFill,RiWhatsappFill } from "react-icons/ri"
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr"
import { ProviderType } from "../../types/provider"

const 	ProviderInfo = ({provider}:{provider:ProviderType}) => {
    return (
        <div className={styles.container}>
	        <div className={styles.section}>
	        	<InfoCard icon={<Home color="rgba(31, 31, 31, 0.3)"/>} title="Provider name" cont={provider.name || "NA"}/>
	        	<InfoCard icon={<Filter />} title="Organization type" cont={provider.type|| "NA"}/>
	        </div>
	        <div className={styles.section}>
	        	<InfoCard icon={<RiMapPinFill/>} title="Address" cont={provider.address || "NA"}/>
	        	<InfoCard icon={<BsGridFill/>} title="Location plus code" cont={provider.location_plus_code || "NA"}/>
	        </div>
	        <div className={styles.section}>
	        	<InfoCard icon={<IoMdMap/>} title="Country" cont={provider.country || "NA"}/>
	        	<InfoCard icon={<IoMdMap/>} title="Region" cont={provider.region || "NA"}/>
	        	<InfoCard icon={<FaCompass/>} title="District" cont={provider.district || "NA"}/>
	        </div>
	        <div className={styles.section}>
	        	<InfoCard icon={<FaGlobe/>} title="Website" cont={provider.website || "NA"}/>
	        	<InfoCard icon={<AiTwotoneMail/>} title="Email" cont={provider.email || "NA"}/>
	        	<InfoCard icon={<BsPhoneFill/>} title="Telephone" cont={provider.telephone || "NA"}/>
	        </div>

	        <hr style={{border: "1px solid rgba(31, 31, 31, 0.4)"}}/>

	        <div className={styles.social_section}>
	        	<p className={styles.social_text}>Social media links</p>
	        	<br/>
	        	<InfoCard icon={<GrFacebookOption/>}  cont={provider.social_media_links?.facebook || "NA"}/>
	        	<br/>
	        	<InfoCard icon={<AiOutlineTwitter/>} cont={provider.social_media_links?.twitter || "NA"}/>
	        	<br/>
	        	<InfoCard icon={<AiFillInstagram/>} cont={provider.social_media_links?.instagram || "NA"}/>
	        	<br/>
	        	<InfoCard icon={<GrLinkedinOption/>} cont={provider.social_media_links?.linkedin || "NA"}/>
	        	<br/>
	        	<InfoCard icon={<RiWhatsappFill/>} cont={provider.social_media_links?.whatsapp || "NA"}/>
	        </div>
        	
        </div>
    );
};

export default 	ProviderInfo;
