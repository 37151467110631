import React,{ useEffect, useState } from 'react';
import {RoleTop, RoleProfile} from "../../organisms";
import { useParams } from "react-router-dom"
import { fetchSingleRole} from "../../utils"
import { RoleType } from "../../types/role"
import styles from "../../organisms/AddUserToRole/AUTR.module.css";
import {Spin} from "antd";

const Role = () => {
    const { id } = useParams()
    const [ data, setData ] = useState<RoleType>({} as RoleType)
     const [loading,setLoading] = useState<boolean>(true)
    useEffect(()=> {
        id && fetchSingleRole(id).then((res:any)=> {
            setData(res)
            setLoading(false)
            console.log(res)
        })
    },[id])

    return (
        <div>
            <RoleTop
                name={data?.name}
                id={data?.id}
                backUrl="/dashboard/roles"
                title="Back to Role"
                showDelete={false}
                editUrl={`/dashboard/roles/${data?.id}/modify-membership`}
            />
            {loading ? <div className={styles.loader}><Spin/></div>
                :  <RoleProfile role={data}/>}
        </div>
    );
};

export default Role;


