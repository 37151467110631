import React,{ useEffect, useState } from 'react';
import { DoctorsListTop, DoctorsTable, UnauthorizedModal } from "../../organisms"
import { Props as DataProps } from "../../organisms/DoctorsTable"
import { fetchAllDoctors, loadDoctorsData } from "../../utils"
import { DoctorType } from "../../types/doctor"
import {useSearchParams} from "react-router-dom"

const DoctorsPage = () => {
    const [param, setParam] = useSearchParams();
   const modal = param.get("modal")
   const [ isModalVisible, setIsModalVisible] = useState(modal === null ? false : true)
    const [ current, setCurrent ] = useState<number>(1)
    const [ doctors, setDoctors ]= useState<DoctorType[]>([])
    const [data, setData] = useState<DataProps[]>([])
    const [ reload, setReload ] = useState<boolean>(false)
   const [ loading, setLoading ] = useState<boolean>(false)
   const [ paginationDetails, setPaginationDetails ] = useState({
     isFirstPage: true,
     isLastPage: false,
     currentPageNumber: 0,
     totalNumberOfPages: 0,
     totalNumberOfResults: 0
   })
    const [filter,setFilter] = useState({status:"",gender:"",speciality:""})

   //fetch providers
   useEffect(()=> {
      setLoading(true)
      fetchAllDoctors((current - 1)).then((res:any) => {
         setDoctors(res.content)
         setPaginationDetails({
           isFirstPage: res.first,
           isLastPage: res.last,
           currentPageNumber: res.number,
           totalNumberOfPages: res.totalPages,
           totalNumberOfResults: res.totalElements
         })
         setLoading(false)
      })  
   },[current,reload])

    useEffect(()=> {
        console.log("Filters",filter);
        let filteredDoctors = doctors;
        if(filter.gender !== "" || filter.speciality !== "" || filter.status !== ""){
            filteredDoctors = doctors.filter((doctor:DoctorType) => {
                return(
                    (filter.gender!=="" && doctor.gender!==null? filter.gender.toLowerCase()===doctor.gender.toLowerCase():true) &&
                    (filter.speciality!==""? filter.speciality.toLowerCase()===doctor.speciality.toLowerCase():true))
            })
        }
        loadDoctorsData(filteredDoctors).then((res:any) => {
            setData(res)
        })
    },[doctors,filter])

    const onCurrentPage = (page: number) => {
      setCurrent(page)
      setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1 
      })
   }

    return (
        <div>
        	<DoctorsListTop setFilter={setFilter} data={data}/>
            <DoctorsTable 
                data={data} 
                reload={reload} 
                setReload={setReload}
                paginationDetails={paginationDetails}
                tableLoading={loading}
                onCurrentPage={onCurrentPage}
            />

            <UnauthorizedModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={()=> setParam({})}
            />
        </div>
    );
};


export default DoctorsPage;
