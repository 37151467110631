import React, { useEffect, useState} from 'react';
import styles from "./pr.module.css"
import {Rate, Table, Avatar} from "antd"
import type { ColumnsType } from "antd/es/table"
import { Typography } from 'antd';
import { fetchSingleProviderReview, loadReviewsData} from "../../utils";
import {ProviderType} from "../../types/provider";


interface DataType {
	key: number | any;
	photo: string | any;
	postedBy: string | any;
	email: string | any;
	rating: number | any;
}

const ProviderReviews = ({provider}:{provider:ProviderType}) => {
	const [data, setData] = useState<DataType[]>([])
	const [reviews, setReviews] = useState([] as any[])

	useEffect(() => {
		if(provider.id){
			fetchSingleProviderReview(provider.id).then((res: any) => {
				setReviews(res.content)
			})
		}
		return () => {}
	},[provider.id])

	useEffect(() => {
		loadReviewsData(reviews).then((res: DataType[]) => {
			setData(res)
		})

	}, [reviews])
	const columns: ColumnsType<DataType> = [
		{
			title: 'PHOTO',
			dataIndex: 'photo',
			key: 'photo',
			width: '8%',
			render: (_, {photo}) => (
				<Avatar
					src={photo}
					alt="provider"
					size={36}
					className={styles.providerImage}
				/>
			),
		},
		{
			title: 'POSTED BY',
			dataIndex: 'postedBy',
			key: 'postedBy',
			width: '14%',
			render: (_, {postedBy}) => (
				<p style={{textTransform: "capitalize"}}>{postedBy}</p>
			)
		},
		{
			title: 'EMAIL',
			dataIndex: 'email',
			key: 'email',
			width: '14%'
		},
		{
			title: 'RATING',
			key: 'rating',
			dataIndex: 'rating',
			width: '12%',
			render: (_, {rating}) => (
				<Rate disabled defaultValue={rating} style={{fontSize: "14px"}}/>
			),
		},
		{
			title: 'SUBMITTED ON',
			key: 'submission',
			dataIndex: 'submission',
			width: '12%'
		},

	];

	const { Link } = Typography;
    return (
        <div className={styles.container}>
		<Table
				columns={columns}
				dataSource={data}
				size="middle"
				pagination={false}
				title={()=><p className={styles.title}>Reviewers</p>}
				footer={()=> {
					return !Array.isArray(reviews) || !reviews.length ? <p className={styles.footer}>No review yet</p>: <Link className={styles.footer} href={`/dashboard/providers/${provider.id}/reviews`}>View all reviews</Link>  }}
				className={styles.table}
			/>

        </div>
    );
};

export default ProviderReviews;
