import React from 'react';
import { AddPageTop, AddPromoForm } from "../../organisms"
import { useNavigate } from "react-router"

const AddPromocode = () => {
    const navigate = useNavigate()

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <AddPageTop
                onBack={()=> navigate("/dashboard/promocodes")}
                title="Back to promo codes list"
                heading="Add a new promo code"
            />
            <AddPromoForm />
        </div>
    );
};

export default AddPromocode;
