import {useEffect, useState, useContext} from "react";
import {Dropdown, Menu} from "antd";
import { RiEqualizerLine } from 'react-icons/ri';
import LocationFilter from "./LocationFilter";
import ProviderTypeFilter from "./ProviderTypeFilter";
import TimeFilter from "./TimeFilter";
import StatusFilter from "./StatusFilter";
import { Button } from "../../atoms"
import styles from "./Filter.module.css"
import FilterContext from "../../contexts/FilterContext"
import { fetchAllTypes, fetchAllDistricts } from "../../utils"

export default function FilterDropdown({applyFilters, resetFilters}:{applyFilters:any, resetFilters:any}) {
    const [visible, setVisible] = useState<boolean>(false);
    const [ types, setTypes ] = useState<string[]>([])
    const [ districts, setDistricts ] = useState<string[]>([])
    const {
        selectedLocation,
        setSelectedLocation,
        selectedProviderType,
        setSelectedProviderType,
        selectedTime,
        setSelectedTime,
        selectedStatus,
        setSelectedStatus
    } = useContext(FilterContext);

    useEffect(()=> {
        fetchAllTypes().then((res:any)=> {
            setTypes(res)
        })
        .catch((err:any)=> {
            console.log(err)
        })
    },[])

    useEffect(()=> {
        fetchAllDistricts().then((res:any)=> {
            setDistricts(res)
        })
        .catch((err:any)=> {
            console.log(err)
        })
    },[])

    const filter = (
        <Menu className={styles.filter_menu}>
            <Button 
                variant="ghost" 
                onClick={resetFilters}
                style={{
                  fontSize:"16px",
                  fontWeight:"500",
                  margin:"0px 20px 0px auto",
                  height:"auto"
                }}
            >Reset</Button>

            <StatusFilter text="Select provider status" selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
            <TimeFilter selectedTime={selectedTime} setSelectedTime={setSelectedTime}/>
            <ProviderTypeFilter types={types} selectedProviderType={selectedProviderType} setSelectedProviderType={setSelectedProviderType}/>
            <LocationFilter districts={districts} selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}/>
            
            <Button 
                style={{
                    borderRadius:"5px",
                    width:"80%",
                    margin:"16px auto 0px auto",
                    padding:"10px 0px",
                    fontSize:"16px",
                    fontWeight:"500"
                }} 
                onClick={()=> {
                    applyFilters()
                    setVisible(false)
                }}
            >Apply filter</Button>
        </Menu>
    )


    return (
        <div className={"select_filter"}>
            <Dropdown overlay={filter} placement={"bottomRight"} arrow trigger={['click']}
                      onVisibleChange={(r) => setVisible(r)} visible={visible}>
                <p className={styles.filter}><RiEqualizerLine style={{fontSize:"32px"}}/> &nbsp; Filter</p>
            </Dropdown>
        </div>

    )
}