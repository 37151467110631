import React from 'react';
import { Modal as AntModal } from "antd"
import styles from "./Modal.module.css"

interface Props {
    isModalVisible: boolean;
    setIsModalVisible:any;
    children?:any
}

const ModalContainer = ({isModalVisible, setIsModalVisible, children}:Props) => {

    const handleCancel = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setIsModalVisible(false);
    }

    return (
        <AntModal  
        	title={""} 
        	visible={isModalVisible} 
        	onCancel={handleCancel} 
        	centered 
        	footer={""}
            closable={false} 
            bodyStyle={{
                 padding: "0em"
            }} 
            style={{borderRadius: "10px"}} 
            maskStyle={{zIndex: 10}} 
        >
                <div className={styles.modal_container}>
                   {children}
                </div>
        </AntModal>
    );
};


export default ModalContainer;
