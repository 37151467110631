import React,{ useState, useEffect, useContext } from 'react';
import { ProviderListTop, ProviderTable, UnauthorizedModal } from "../../organisms";
import { fetchAllProviders, loadTableData } from "../../utils";
import { ProviderType } from "../../types/provider"
import { Props as DataProps } from "../../organisms/ProviderTable"
import FilterContext from "../../contexts/FilterContext";
import {useSearchParams} from "react-router-dom"

const ProvidersPage = () => {
   const [param, setParam] = useSearchParams();
   const modal = param.get("modal")
   const [ isModalVisible, setIsModalVisible] = useState(modal === null ? false : true)
   const [ current, setCurrent ] = useState<number>(1)
   const [ providers, setProviders ]= useState<ProviderType[]>([])
   const [ loading, setLoading ] = useState<boolean>(false)
   const [data, setData] = useState<DataProps[]>([])
   const [filterQuery, setFilterQuery] = useState<string>("")
   const [ reload, setReload ] = useState<boolean>(false)
   const [ paginationDetails, setPaginationDetails ] = useState({
     isFirstPage: true,
     isLastPage: false,
     currentPageNumber: 0,
     totalNumberOfPages: 0,
     totalNumberOfResults: 0
   })

   const {
        selectedLocation,
        setSelectedLocation,
        selectedProviderType,
        setSelectedProviderType,
        selectedTime,
        setSelectedTime,
        selectedStatus,
        setSelectedStatus
    } = useContext(FilterContext);


   //fetch providers
   useEffect(()=> {
      setLoading(true)
      fetchAllProviders((current - 1), filterQuery).then((res:any) => {
         setProviders(res.content)
         setPaginationDetails({
           isFirstPage: res.first,
           isLastPage: res.last,
           currentPageNumber: res.number,
           totalNumberOfPages: res.totalPages,
           totalNumberOfResults: res.totalElements
         })
         setLoading(false)
      })  
   },[current, filterQuery, reload])

   useEffect(() => {
    loadTableData(providers).then((res:DataProps[])=> {
      setData(res)
    })
  },[providers])
   

   const onCurrentPage = (page: number) => {
      setCurrent(page)
      setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1 
      })
   }

   const applyFilters = () => {
      let query:string[] = []
      if(selectedStatus !==""){
         if(selectedStatus.toLowerCase() === "active"){
            query.push("only_active_providers=true")
         } else {
            query.push("only_active_providers=false")
         }     
      }
      if(selectedProviderType !== ""){
         query.push("type=" + selectedProviderType)
      }
      if(selectedLocation !== ""){
         query.push("district=" + selectedLocation)
      }
      if(selectedTime !==null){
         query.push("created_at=" + selectedTime.toString())
      }

      setFilterQuery("&" + query.join("&"))
      console.log("&" + query.join("&"))
   }

   const resetFilters = () => {
        setSelectedStatus("")
        setSelectedProviderType("")
        setSelectedTime(null)
        setSelectedLocation("")
        setFilterQuery("")
    }

    return (
          <div>
             <ProviderListTop 
               data={data} 
               applyFilters={applyFilters}
               resetFilters={resetFilters}
            />
             <ProviderTable 
               paginationDetails={paginationDetails}
               onCurrentPage={onCurrentPage}
               tableLoading={loading}
               reload={reload}
               setReload={setReload}
               data={data}
            />
            <UnauthorizedModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={()=> setParam({})}
            />
                 
          </div> 
    );
};

export default ProvidersPage;
