import React, {useEffect, useState} from 'react';
import {Tabs} from 'antd';
import {FilterDropdown, ProviderReviewTable} from "../../organisms"
import {useParams, useSearchParams} from "react-router-dom"
import {fetchSingleProvider} from "../../utils";
import {ProviderType} from "../../types/provider";

const {TabPane} = Tabs;

const ProviderReviews = () => {
    const [data, setData] = useState<ProviderType>({} as ProviderType)
    const {id} = useParams()
    const [param, setParam] = useSearchParams()
    const tab = param.get("tab")
    const [currentKey, setCurrentKey] = useState(tab === null ? "" : tab)

    const onChange = async (key: string) => {
        if (key === "all") {
            await setCurrentKey("");
            setParam({})
        } else {
            await setCurrentKey(key);
            setParam({"tab": key})
        }

    };
    useEffect(()=> {
        id && fetchSingleProvider(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])


    const applyFilters = () => {
        console.log("filter")
    }

    const resetFilters = () => {
        console.log("filter")
    }

    const style = {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "700",
        color: "#000000"
    }

    const extra = <FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters}/>
    return (
        <div>
            <Tabs defaultActiveKey={currentKey} onChange={onChange} tabBarExtraContent={extra} tabBarStyle={style}>
                <TabPane tab="ALL" key="all">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
                <TabPane tab="APPROVED" key="Approved">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
                <TabPane tab="UNAPPROVED" key="Unapproved">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
                <TabPane tab="WAITING" key="Waiting">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
                <TabPane tab="TRASH" key="Trash">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
                <TabPane tab="SPAM" key="Spam">
                    <ProviderReviewTable
                        key={currentKey}
                        currentKey={currentKey}
                        provider={data}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};


export default ProviderReviews;
