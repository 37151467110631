import React, {useEffect, useState, useContext} from 'react';
import {useNavigate} from "react-router"
import {useParams} from "react-router-dom"
import {ConfirmModal, DeleteModal, EditUserForm, EditUserTop,  UnauthorizedModal} from "../../organisms";
import {fetchSingleUser} from "../../utils"
import {UserType} from "../../types/user"
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized"

const EditUsers = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [data, setData] = useState<UserType>({} as UserType)
    const { user } = useContext(UserContext)
    const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)

    const handleShowDelete = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(true)
    }

    const checkUserRole = () => {
        user?.permissions?.includes("ADMIN_USER.DELETE.ALL") ||  ( user?.permissions?.includes("ADMIN_USER.DELETE.SELF") && id === user?.id)
        ? setIsModalVisible(true) : setIsUnauthorizedModalVisible(true)
    }
    

    useEffect(() => {
        id && fetchSingleUser(id).then((res: any) => {
            setData(res)
            console.log(res)
        })
    }, [id])
    return (
        (user.permissions)?.includes("ADMIN_USER.UPDATE.ALL")=== false && (user.permissions)?.includes("ADMIN_USER.UPDATE.SELF") && id !== user?.id ?
        
        <UnauthorizedPage/>
        :
        <div style={{borderRadius: "15px", width: "100%", backgroundColor: "#ffffff"}}>
            <EditUserTop 
                handleDelete={checkUserRole}
                 name={data.firstName}
                 backUrl="/dashboard/admin-users"
                 title="Back to User list"
                 type="user"
            />
            <EditUserForm user={data}/>
            {/*confirm modal*/}
            <ConfirmModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                action={handleShowDelete}
                type="admin-user"
            />

            {/*delete modal*/}
            <DeleteModal
                isModalVisible={isDeleteModalVisible}
                setIsModalVisible={setIsDeleteModalVisible}
                ids={[data?.id as string]}
                callback={() => navigate("/")}
                type="admin-user"
            />

             <UnauthorizedModal 
                isModalVisible={isUnauthorizedModalVisible} 
                setIsModalVisible={setIsUnauthorizedModalVisible} 
                action={()=> {}}
            />

        </div>
    );
};

export default EditUsers;
