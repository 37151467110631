import React, { useState, useEffect } from "react";
import styles from "./epf.module.css";
import {
	EditFormInput,
	DatePickerInput,
	SelectInput
} from "../../molecules";
import { Button } from "../../atoms";
import { MdOutlineMeetingRoom } from "react-icons/md"
import { TbDiscount } from "react-icons/tb"
import { Formik, Form } from "formik";
import moment, { Moment } from "moment"
import * as yup from "yup";
import { Spin } from "antd";
import { FiPercent } from 'react-icons/fi';
import { FcExpired } from 'react-icons/fc';
import { editPromocode, openNotification } from "../../utils"
import { PromocodesType } from "../../types/promocodes"
import { useNavigate } from "react-router-dom"

const EditPromoForm = ({promocode}:{promocode:PromocodesType}) => {
	const navigate = useNavigate();
	const [endDate, setEndDate] = useState<Moment | null | string | undefined>(null);
	const [consultation, setConsultation] = useState<string>("")

	const validationSchema = yup.object().shape({
		name: yup.string(),
		percentage: yup.number()
					   .typeError("Percentage must be a number")
					   .positive("Percentage must be a positive number")
					   .integer("Percentage must be an integer"),
	});

	const resetExtras = () => {
		setEndDate(promocode?.endDate === null || promocode?.endDate === ""  ? null : moment(promocode?.endDate,"YYYY-MM-DD"))
		setConsultation(promocode?.consultationType)
	}

	const consultationOptions=["STANDARD", "EXPRESS", "SUPER_EXPRESS"];

	useEffect(()=> {
		setEndDate(promocode?.endDate === null || promocode?.endDate === ""  ? null : moment(promocode?.endDate,"YYYY-MM-DD"))
		setConsultation(promocode?.consultationType)
	}, [promocode])

	return (
		<Formik
			initialValues={{
				name:promocode?.name,
				percentage:promocode?.percentage,
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values, { setSubmitting }) => {
				console.log(values)
				const body = {
					name:values?.name,
					consultationType:consultation,
					percentage:(values.percentage === null ? 0 : values.percentage).toString(),
					endDate:endDate
				};
					editPromocode(promocode?.id as string, body)
					.then((res: any) => {
						setSubmitting(false);
						if(res?.errorMessage !== "" || res?.error){
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
                                res?.errorMessage || res?.errorr
                            );
                        } else{
							openNotification(
								"success",
								"bottomRight",
								"Promocode edited successfully"
							);
							navigate("/dashboard/promocodes")
						}
					})
					.catch((err: string) => {
						setSubmitting(false);
						openNotification(
							"error",
							"bottomRight",
							"An error occured. Please try again later"
						);
					});
			}}
		>
			{({ errors, isSubmitting, resetForm }) => (
				<Form className={styles.form}>
				    
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Promo name</p>
							<EditFormInput
								icon={<TbDiscount/>}
								type="text"
								name="name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Consultation type</p>
							<SelectInput
								icon={<MdOutlineMeetingRoom/>}
								options={consultationOptions.map((consultationOption:any) => {
			                          return  {value:consultationOption,
			                              label:consultationOption}
			                        } )}
								bordered={false}
								onChange={(value) => {
									setConsultation(value)
								}} 
								value={consultation}
							/>
						</div>
					</div>


					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Percentage</p>
							<EditFormInput
								icon={<FiPercent/>}
								type="text"
								name="percentage"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>End Date</p>
							<DatePickerInput
								name="end_date"
								icon={<FcExpired/>}
								onChange={(val: any) => {
									setEndDate(moment(val._d).format("YYYY-MM-DD"))
								}} 
								value={endDate === null ? null : moment(endDate,"YYYY-MM-DD")}
			                    bordered={false} 
							/>
						</div>
					</div>
					
					<div className={styles.field_section}>
					 
						<div className={styles.button_group}>
							<Button
								variant="ghost"
								style={{
									border: "1.5px solid #007AFF",
								}}
								type="submit"
								disabled={
									Object.keys(errors).length > 0 ||
									isSubmitting
										? true
										: false
								}
							>
								{isSubmitting ? <Spin /> : "Save"}
							</Button>
							<Button 
								variant="ash" 
								type="reset" 
								onClick={() => {
									resetForm();
									resetExtras();
								}}
							>
								Cancel
							</Button>
						</div>
					  
					</div>
					
				</Form>
			)}
		</Formik>
	);
};

export default EditPromoForm;
