import React, {useState} from "react";
import styles from "./ehf.module.css";
import {Button} from "../../atoms";
import {
    AiTwotoneMail,
} from "react-icons/ai";
import {
    FormInput
} from "../../molecules";
import {BsPersonFill, BsPhoneFill} from "react-icons/bs";
import {Spin} from "antd";
import {fetchNin, editHospitalAdmin, addUser, openNotification} from "../../utils";
import {HiKey} from "react-icons/hi";
import {GiHouseKeys} from "react-icons/gi";
import {useNavigate} from "react-router-dom";
import {AdminUserType} from "../../types/adminUser";


const EditHospitalAdminForm = ({hospitalAdmin}) => {
    const [nin, setNin] = useState<string>("");
    // const [id, setId] = useState<string>()
    const [ninError, setNinError] = useState("")
    const [user,setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
       phone:"",
        id:""
    })
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
     const [submitting, setSubmitting] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [isNinValidated, setIsNinValidated] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleNinChange = (e: any) => {
        setNin(e.target.value.toUpperCase())
    }
    const handlePassword = (e: any) => {
        setPassword(e.target.value)
    }
    const handleConfirmPassword = (e: any) => {
        setConfirmPassword(e.target.value)
    }
    const handleChange = (event : any) => {
        const value = event.target.value;
        setUser({
            ...user,
            [event.target.name]: value
        });
    }

    const handleNin = (e: any) => {
        e.preventDefault();
        setUser({firstName: "", lastName: "", email: "", phone:"", id: ""})
        setNinError("")
        setLoading(true)
        if (nin) {
            fetchNin(nin).then((res: any) => {
                console.log(res.id)
                if (res.id) {
                    setUser(res)
                    setIsNinValidated(true)
                    setNinError("")
                } else {
                    setNinError(res?.errorMessage)
                    setIsNinValidated(false)
                }
                setLoading(false)
            })
        }
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const { firstName, lastName, email, phone } = user;
        const userdata: AdminUserType = { nin, firstName, lastName, email, phone };
        try {
            if (!user.id) {
                const response = await addUser(userdata);
                const userId:string = response.id;
                setUser({id:userId, firstName, lastName, email, phone});
                await editHospitalAdmin(hospitalAdmin.id, userId);
                setSubmitting(true);
                openNotification("success", "bottomRight", "Hospital Admin updated successfully");
                setSubmitting(false);
                navigate(`/dashboard/hospitals/${hospitalAdmin.id}`);
            } else {
                await editHospitalAdmin(hospitalAdmin.id, user.id);
                setSubmitting(true);
                openNotification("success", "bottomRight", "Hospital added successfully");
                setSubmitting(false);
                navigate(`/dashboard/hospitals/${hospitalAdmin.id}`);
            }
        } catch (error) {
            console.log(error);
            openNotification("error", "bottomRight",  "An error occurred. Please try again later");
            setSubmitting(false);
        }
    };
    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.field_section}>
                <div className={styles.form_field}>
                    <p style={{padding: '10px'}} className={styles.label}>Enter National Identification Number</p>
                    <div className={styles.nin_container}>
                        <input className={styles.nin_input} type={'text'} value={nin}
                               onChange={handleNinChange} name={'nin'}/>
                        <button type={'button'} onClick={handleNin} className={styles.nin_button}>{loading ?
                            <Spin/> : 'Check NIN'}</button>
                    </div>
                </div>
            </div>
            {ninError ? <p style={{color: 'red', fontSize: '15px', padding: '10px'}}>{ninError}</p> : null}
            {user.id || ninError ? <>
                <div className={styles.field_section}>
                    <div className={styles.form_field}>
                        <p className={styles.label}>First Name</p>
                        <FormInput change={handleChange} value={user.firstName} type={'text'} name={'firstName'}
                                   disabled={isNinValidated} icon={<BsPersonFill/>}/>
                    </div>
                    <div className={styles.form_field}>
                        <p className={styles.label}>Last Name</p>
                        <FormInput change={handleChange} value={user.lastName} type={'text'} name={'lastName'}
                                   disabled={isNinValidated} icon={<BsPersonFill/>}/>
                    </div>
                </div>
                <div className={styles.field_section}>
                    <div className={styles.form_field}>
                        <p className={styles.label}>Email</p>
                        <FormInput change={handleChange} value={user.email} type={'text'} name={'email'}
                                   disabled={isNinValidated} icon={<AiTwotoneMail/>}/>
                    </div>
                    <div className={styles.form_field}>
                        <p className={styles.label}>Phone Number</p>
                        <FormInput change={handleChange} value={user.phone} type={'text'}
                                   name={'phone'} disabled={isNinValidated} icon={<BsPhoneFill/>}/>
                    </div>
                </div>
            </> : null}
            {ninError &&
                <div className={styles.field_section}>
                    <div className={styles.form_field}>
                        <p className={styles.label}>Password</p>
                        <FormInput change={handlePassword} value={password} type={'text'} name={'password'}
                                   disabled={isNinValidated} icon={<HiKey/>}/>
                    </div>
                    <div className={styles.form_field}>
                        <p className={styles.label}>Confirm Password</p>
                        <FormInput change={handleConfirmPassword} value={confirmPassword} type={'text'}
                                   name={'confirmPassword'} disabled={isNinValidated} icon={<GiHouseKeys/>}/>
                    </div>
                </div>}

            <div className={styles.field_section_full}>
                <div className={styles.form_field}>
                    &nbsp; &nbsp;
                    <div className={styles.button_group}>
                        <button
                            style={{
                                border: "1.5px solid #007AFF",
                            }}
                            disabled={submitting || !user.firstName || !user.email || !user.lastName}
                            type="submit"
                        >
                            {submitting ? <Spin/> : "Save"}
                        </button>
                        <Button
                            variant="ash"
                            type="reset"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </form>

    );
};
export default EditHospitalAdminForm;