import React from 'react';
import {useNavigate} from "react-router";
import {AddPageTop} from "../../organisms";
import AddFrontDeskManagerForm from "../../organisms/AddFManagerForm/AddFrontDeskManagerForm";

const AddFrontDeskManager = () => {
    const navigate = useNavigate()

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <AddPageTop
                onBack={()=> navigate(-1)}
                title="Back"
                heading="Add a new Frontdesk Manager"
            />
            <AddFrontDeskManagerForm />
        </div>
    );
};

export default AddFrontDeskManager;