import styles from "./formInput.module.css";
import React, {useState} from "react";
import {Button} from "../../atoms";
import {Spin} from "antd";

const FormInput =(props) =>{
    return(
        <>
            <div className={styles.container} onClick={props.onClick}>
                <span className={styles.icon_button}>{props.icon}</span>
                <input className={styles.input} type={props.type} value={props.value}
                       onChange={props?.change} name={props.name}
                       disabled={props.disabled}
                       readOnly={true}
                       />
            </div>
        </>
    )

};
export default FormInput;

export const CheckBox = ({ ids, setIds,user}) => {
    const [checked, setChecked] = useState<boolean>(true);

    const onCheckChange = (e) => {
        const dupSet = ids;
        const {checked} = e.target;
        if (checked) {
            setChecked((prev) => !prev);
            dupSet.add(user.id);
        } else {
            setChecked((prev) => !prev);
            dupSet.delete(user.id);
        }
        setIds(dupSet);
    }
    return (
        <div className={styles.users}>
            <label className={styles.input_label}>
                <input
                    type="checkbox"
                    defaultChecked={checked}
                    checked={checked}
                    onChange={onCheckChange}
                    name={user.name}
                    value={user.id}
                />
                {user.firstName}&nbsp;{user.lastName}
            </label>
        </div>

    )
};
export const EditButton = ({ids,submitting }) =>{
    return(
        <div className={styles.button_group}>
            <Button
                className={styles.button_margin}
                variant="ghost"
                style={{
                    border: "1.5px solid #007AFF",
                }}
                type="submit"
                disabled={submitting || !ids}
            >
                {submitting ? <Spin/> : "Save"}
            </Button>
        </div>
    )
}
