import { useState } from "react"
import {Dropdown, Menu} from "antd";
import { RiEqualizerLine } from 'react-icons/ri';
import GenderFilter from "./GenderFilter";
import SpecialtyFilter from "./SpecialtyFilter";
import StatusFilter from "./StatusFilter";
import { Button } from "../../atoms"
import styles from "./Filter.module.css"
import {useDoctorsFilter} from "../../contexts/FilterDoctorsContext"

export default function DoctorsFilterDropdown({applyFilters, resetFilters}:{applyFilters:any, resetFilters:any}) {
    const [visible, setVisible] = useState<boolean>(false);
    
    const {
        selectedGender,
        setSelectedGender,
        selectedSpecialty,
        setSelectedSpecialty,
        selectedStatus,
        setSelectedStatus
    } = useDoctorsFilter();

    const genderOptions=["Male", "Female", "Others"];
    const specialtyOptions=["Clinical Medical Officer", "Counsellor", "Dentist", "Dermatologist", "Diabetologist", "Nutrition and fitness","General practitioner", "General surgeon", "Obstetrician & Gynaecologist", "Nurse", "Herbal Health specialist", "Oncologist", "Ophthalmologist", "Orthopedic surgeon","Paediatrician","Pharmacist","Plastic surgeon", "Physician", "Radiologist"]

    const filter = (
        <Menu className={styles.filter_menu}>
            <Button 
                variant="ghost" 
                onClick={resetFilters}
                style={{
                  fontSize:"16px",
                  fontWeight:"500",
                  margin:"0px 20px 0px auto",
                  height:"auto"
                }}
            >Reset</Button>

            <StatusFilter text="Select doctor status" selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
            <GenderFilter types={genderOptions} selectedGender={selectedGender} setSelectedGender={setSelectedGender}/>
            <SpecialtyFilter types={specialtyOptions} selectedSpecialty={selectedSpecialty} setSelectedSpecialty={setSelectedSpecialty}/>
            <Button 
                style={{
                    borderRadius:"5px",
                    width:"80%",
                    margin:"16px auto 0px auto",
                    padding:"10px 0px",
                    fontSize:"16px",
                    fontWeight:"500"
                }} 
                onClick={()=> {
                    applyFilters(selectedStatus,selectedGender,selectedSpecialty)
                    setVisible(false)
                }}
            >Apply filter</Button>
        </Menu>
    )


    return (
        <div className={"select_filter"}>
            <Dropdown overlay={filter} placement={"bottomRight"} arrow trigger={['click']}
                      onVisibleChange={(r) => setVisible(r)} visible={visible}>
                <p className={styles.filter}><RiEqualizerLine style={{fontSize:"32px"}}/> &nbsp; Filter</p>
            </Dropdown>
        </div>

    )
}