import React, {useEffect,useState} from "react";
import styles from "./AUTR.module.css";

const Checked = ({user, ids, setIds,key,role}) => {
    const [checked, setChecked] = useState<boolean>(true);

    useEffect(() => {
    }, [checked]);

    const onCheckChange = (e) => {
        const {checked} = e.target;
        if (checked) {
            setChecked((prev) => !prev);
            setIds(prev => [...prev, user.id])
            console.log(ids)
        } else {
            console.log(ids)
            setChecked((prev) => !prev);
            setIds(ids.filter(x => x.id !== user.id))
        }
    }
    
    return (
        <div className={styles.users} key={key}>
            <label className={styles.input_label}>
                {user.roles.indexOf(role.name) > -1 ?
                    <input
                        type="checkbox"
                        defaultChecked={checked}
                        checked={checked}
                        onChange={onCheckChange}
                        name={user.name}
                        value={user.id}
                    />
                    : <input
                        type="checkbox"
                        defaultChecked={!checked}
                        onChange={onCheckChange}
                        name={user.name}
                        value={user.id}
                    />}
                {user.firstName}&nbsp;{user.lastName}
            </label>
        </div>

    )
};

export default Checked;
