import React from "react";
import styles from "./ImageCard.module.css"
import { IoMdCheckmarkCircle } from "react-icons/io"
import { MdCancel } from "react-icons/md"

const ImageCard = ({url, deleteImage}:{url:string, deleteImage:any}) => {
    return (
        <div 
        	style={{
                backgroundImage: `url(${url})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                maxWidth: "450px",
                borderRadius:"10px",
            }}

            className={styles.container}
        >

        	<div className={styles.check}>
    			<IoMdCheckmarkCircle style={{color:"#12B803", fontSize:"32px", margin:"auto"}}/>
        	</div>

        	<div className={styles.cancel} onClick={deleteImage}>
    			<MdCancel style={{color:"#FD4C06", fontSize:"32px", margin:"auto"}}/>
        	</div>
        	
        </div>
    );
};

export default ImageCard;
