import React, {useState, useContext, useEffect} from "react";
import styles from "../AddDoctorForm/adf.module.css";
import {
    EditFormInput,
    ImageUpload,
    DatePickerInput,
    SelectInput
} from "../../molecules";
import {Button} from "../../atoms";
import {AiTwotoneMail} from "react-icons/ai";
import {BsPhoneFill, BsPersonFill} from "react-icons/bs";

import {FaCalendarAlt, FaHospitalAlt} from "react-icons/fa";

import {Formik, Form} from "formik";
import moment, {Moment} from "moment"
import * as yup from "yup";
import {addFrontdeskManager, addUser, fetchAllHospitalsIdAndName, openNotification, uploadImage} from "../../utils";
import type {UploadProps} from "antd";
import {Spin} from "antd";
import UserContext from "../../contexts/UserContext"
import {useNavigate} from "react-router-dom"
import {EditFormInputForNin, PopoverWithInfo} from "../../molecules/EditFormInput";
import {FrontDeskManagerType} from "../../types/fdmanager";

const AddFrontDeskManagerForm = () => {
    const [url, setUrl] = useState<any>({} as any);
    const [birthDate, setBirthDate] = useState<Moment | null | string | undefined>(null);
    const navigate = useNavigate();
    const [gender, setGender] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);
    const [placeOfWork,setPlaceOfWork] = useState<any>()
    const [hospitalNamesAndIds,setHospitalNamesAndIds] = useState<{id: string,name: string} []>([])
    const [selectedHospitalNameAndId,setSelectedHospitalNameAndId] = useState<{id: string,name: string } | null >(null)

    useEffect(()=>{

        fetchAllHospitalsIdAndName()
            .then((res:{id: string,name: string}[]) =>{
                setHospitalNamesAndIds(res)
            })
            .catch(error => console.error(error))

    },[])

    const props: UploadProps = {
        accept: "image/*",
        name: "file",
        multiple: true,
        beforeUpload(file) {
            setUploading(true);
            uploadImage(file).then((res: any) => {
                setUrl({
                    id: res?.asset_id,
                    uid: res?.public_id,
                    name: res.original_filename,
                    url: res?.secure_url,
                    status: "success",
                    type: res?.type,
                    size: res?.bytes
                });
                setUploading(false);

            });
            return false;
        },

    };

    const validationSchema = yup.object().shape({
        first_name: yup.string().required("First name is required"),
        last_name: yup.string().required("Last name is required"),
        telephone: yup.string()
            .matches(/^\+?\d{1,3}[- ]?\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/, 'Invalid telephone number')
            .required('Telephone number is required'),
        email: yup
            .string()
            .email("Email is invalid")
            .required("Email is required"),
        nin: yup.string()
    });

    const resetExtras = () => {
        setUrl({});
        setBirthDate(null)
        setGender("")
    };

    const {user} = useContext(UserContext)
    const genderOptions = ["Male", "Female"];


    return (
        <Formik
            initialValues={{
                first_name: "",
                last_name: "",
                telephone: "",
                email: "",
                nin: "",
                gender: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                const data: FrontDeskManagerType = {
                    email: values.email,
                    userId: user?.id,
                    firstName: values.first_name,
                    lastName: values.last_name,
                    birthDate: birthDate,
                    gender: gender,
                    placeOfWork:placeOfWork,
                    nin: values.nin,
                    phone: values.telephone,
                    pictureUrl: {
                        id: url?.id,
                        uid: url?.uid,
                        name: url.name,
                        url: url?.url,
                        status: "success",
                        type: url?.type,
                        size: url?.size
                    },
                    errorMessage: ""
                };
                addUser(data)
                    .then((res: any) => {
                        setSubmitting(false);
                        if (res?.errorMessage || res?.errorMessage !== "") {
                            openNotification(
                                "error",
                                "bottomRight",
                                `${res.errorMessage}`
                            );
                        } else {
                            openNotification(
                                "success",
                                "bottomRight",
                                "User created successfully"
                            );
                            resetForm();
                            resetExtras();
                            addFrontdeskManager(selectedHospitalNameAndId!.id,res).then(() => {
                                openNotification(
                                    "success",
                                    "bottomRight",
                                    "Manager added successfully"
                                );
                                setSubmitting(false)
                            })
                                .catch((err: any) => {
                                    console.log(err)
                                    setSubmitting(false);
                                    openNotification(
                                        "error",
                                        "bottomRight",
                                        `${err}`
                                    );
                                })
                                .finally(() => { navigate(`/dashboard/hospitals/${selectedHospitalNameAndId!.id}`)})
                        }
                    })
                    .catch((err: any) => {
                        console.error(err)
                        setSubmitting(false);
                        openNotification(
                            "error",
                            "bottomRight",
                            `${err}`
                        );
                    });
            }}


        >
            {({isSubmitting, resetForm}) => (
                <Form className={styles.form}>
                    <div className={styles.field_section_full}>
                        <p className={styles.label}>Profile Picture</p>
                        <div className={styles.field_section_gallery}>
                            <ImageUpload
                                props={props}
                                disabled={uploading}
                                loading={uploading}
                                backgroundImage={url?.url}
                            />
                        </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>First name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="first_name"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Last name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="last_name"
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Email</p>
                            <EditFormInput
                                icon={<AiTwotoneMail/>}
                                type="email"
                                name="email"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <div className={styles.row}>
                                <p className={styles.label}>Contact Phone</p>
                                <PopoverWithInfo
                                    contentTitle="Some interesting title Example"
                                    content="We will add some information here soon!"
                                />
                            </div>

                            <EditFormInput
                                type="tel"
                                name="telephone"
                                icon={<BsPhoneFill/>}
                            />
                        </div>
                    </div>


                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>National Identity Number</p>
                            <EditFormInputForNin
                                icon={<BsPersonFill/>}
                                type="text"
                                name="nin"
                            />
                        </div>

                        <div className={styles.form_field}>
                            <p className={styles.label}>Birth Date</p>
                            <DatePickerInput
                                name="birth_date"
                                icon={<FaCalendarAlt/>}
                                onChange={(val: any) => {
                                    setBirthDate(moment(val._d).format("DD.MM.YYYY"))
                                }}
                                value={birthDate === null ? null : moment(birthDate, "DD.MM.YYYY")}
                                bordered={false}
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Gender</p>
                            <SelectInput
                                icon={<BsPersonFill/>}
                                options={genderOptions.map((genderOption: any) => {
                                    return {
                                        value: genderOption,
                                        label: genderOption
                                    }
                                })}
                                bordered={false}
                                onChange={(value) => {
                                    setGender(value)
                                }}
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Place of work</p>
                            <SelectInput
                                icon={<FaHospitalAlt/>}
                                options={ hospitalNamesAndIds.map((hospitalNameAndId) =>({
                                    ...hospitalNameAndId,
                                    value:hospitalNameAndId.id,
                                    label:hospitalNameAndId.name
                                }))}
                                bordered={false}
                                onChange={(value, selectedHospital: any) =>{
                                    setSelectedHospitalNameAndId({id:value, name:selectedHospital.name})
                                    setPlaceOfWork(selectedHospital.name)
                                }}
                            />
                        </div>
                    </div>


                    <div className={styles.field_section}>

                        &nbsp; &nbsp;
                        <div className={styles.button_group}>
                            <Button
                                variant="ghost"
                                style={{
                                    border: "1.5px solid #007AFF",
                                }}
                                type="submit"
                                disabled={
                                    birthDate === null ||
                                    gender === ""
                                }
                            >
                                {isSubmitting ? <Spin/> : "Save"}
                            </Button>
                            <Button
                                variant="ash"
                                type="reset"
                                onClick={() => {
                                    resetForm();
                                    resetExtras();
                                }}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </Form>
            )}
        </Formik>
    );
};

export default AddFrontDeskManagerForm;

