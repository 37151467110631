import React, {useState} from "react";
import styles from "./ehfdm.module.css";
import {Spin} from "antd";
import {Modal} from 'antd';
import { editHospitalManager, openNotification} from "../../utils";
import {HospitalType} from "../../types/hospital";
import AddHospitalFManagers from "../AddHospitalFManagers";
import {EditStaffForm} from "../../molecules";


const EditHospitalManager = ({
                                hospitalManager,
                                loading,
                                reloadHospitalDetails
                            }: { hospitalManager: any, loading: boolean, reloadHospitalDetails: (callback?: (hospital: HospitalType) => void) => void }) => {
    const [submitting, setSubmitting] = useState(false);
    const [ids, setIds] = useState<Set<string>>(new Set(hospitalManager?.staff?.frontdesk_managers?.map(manager => manager.id) ?? []));
    const [openModal, setOpenModal] = useState<boolean>(false);

    const showModal = () => {
        setOpenModal(true);
    };
    const handleCancel = () => {
        setOpenModal(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if(hospitalManager.id){
            editHospitalManager(hospitalManager.id, Array.from(ids))
                .then((res: any) => {
                    setSubmitting(false);
                    if (res.error) {
                        setSubmitting(false);
                    } else {
                        openNotification(
                            "success",
                            "bottomRight",
                            "Frontdesk Manager updated successfully"
                        );
                        setOpenModal(false)
                    }
                })
                .catch((err: any) => {
                    setSubmitting(false);
                    openNotification(
                        "error",
                        "bottomRight",
                        err ?? "An error occured. Please try again later"
                    );
                })
                .finally(() => {
                    resetIdsBased();
                });}
    };

    const resetIdsBased = () => {
        reloadHospitalDetails((hospital) => {
            setIds(new Set(hospital?.staff?.frontdesk_managers?.map(manager => manager.id) ?? []))
        });
    }

    return (loading ?
            <div className={styles.loader}>
                <Spin/>
            </div> :
            <>
                <EditStaffForm user={'Frontdesk Manager'} staffs={hospitalManager?.staff?.frontdesk_managers} ids={ids} setIds={setIds} showModal={showModal} submitting={submitting} submit={onSubmit}/>
                <Modal title={""} open={openModal} centered footer={""} destroyOnClose={true} bodyStyle={{
                    padding: "0em"
                }} style={{borderRadius: "15px"}} onCancel={handleCancel}>
                    <AddHospitalFManagers data={hospitalManager} cancel={handleCancel}
                                        reloadHospitalDetails={resetIdsBased}/>
                </Modal>
            </>
    )
};
export default EditHospitalManager;