import React from 'react';
import styles from "./userProfile.module.css"
import {UserType} from "../../types/user";

const UserProfile = ({user}: {user:UserType }) => {
    const profile = [
        {
            'title':'FirstName',
            'desc':user?.firstName || 'NA'
        },
        {
            'title':'LastName',
            'desc':user?.lastName || "NA"
        },
        {
            'title':'Email',
            'desc':user?.email || "NA"
        },
        {
            'title':'Roles',
            'desc':user?.roles || "NA"
        }
    ]
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                {profile.map((info:any) => (
                    <div className={styles.info_item}>
                        <p className={styles.title}>{info.title}</p>
                        <p className={styles.desc}>{info.desc}</p>
                    </div>
                ))}
            </div>

        </div>
    );
};

export default UserProfile ;

