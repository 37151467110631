import React, {useEffect, useState} from "react";
import styles from "./ehf.module.css";
import {
    EditFormInput, ImageUpload,
} from "../../molecules";
import { Home} from "../../svg";
import { Button } from "../../atoms";
import {
    AiTwotoneMail,
} from "react-icons/ai";
import { BsPhoneFill } from "react-icons/bs";
import { RiMapPinFill } from "react-icons/ri";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {Spin, UploadProps} from "antd";
import {FaCompass} from "react-icons/fa";
import { HospitalType } from "../../types/hospital"
import {editSingleHospital, openNotification, uploadImage} from "../../utils";
import {useNavigate} from 'react-router-dom';

const EditHospitalForm = ({ hospital }: { hospital: HospitalType}) => {
    const [url, setUrl] = useState<any>({} as any);
    const [uploading, setUploading] = useState<boolean>(false);
    const [logo, setLogo] = useState<any>({} as any);
    const [logoUpload, setLogoUploading] = useState<boolean>(false);
    const navigate = useNavigate();

    const dummyRequest = async (options) => {
        const { onSuccess } = options;
        setTimeout(() => {
            onSuccess("OK");
        }, 0);
    };
    const prop: UploadProps = {
        accept: "image/*",
        name: "file",
        multiple: true,
        customRequest: dummyRequest,
        beforeUpload(file) {
            setLogoUploading(true);
            uploadImage(file).then((res: any) => {

                setLogo({
                    id: res?.asset_id,
                    uid: res?.public_id,
                    name: res.original_filename,
                    url: res?.secure_url,
                    status: "success",type: res?.type,
                    size: res?.bytes
                });
                setLogoUploading(false);
                console.log(res)
            });
            return false;
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };

    const props: UploadProps = {
        accept: "image/*",
        name: "file",
        multiple: true,
        customRequest: dummyRequest,
        beforeUpload(file) {
            setUploading(true);
            uploadImage(file).then((res: any) => {
                setUrl({
                    id:res?.asset_id,
                    uid:res?.public_id,
                    name:res.original_filename,
                    url:res?.secure_url,
                    status:"success",
                    type:res?.type,
                    size:res?.bytes
                });
                setUploading(false);
            });
            return false;
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };
    const resetExtras = () => {
         setUrl(hospital?.operatingLicense);
    };
    useEffect(()=> {
        setUrl(hospital?.operatingLicense);
        setLogo(hospital?.hospitalLogo);
    }, [hospital])


    const validationSchema = yup.object().shape({
        name: yup.string(),
        telephone: yup.string(),
        email: yup
            .string()
            .email("Email is invalid"),
        address: yup.string(),
        licenseInformation: yup.string(),
    });

    return (
        <Formik
            initialValues={{
                name: hospital?.name,
                address: hospital?.address,
                telephone: hospital?.contactPhone,
                email: hospital?.contactEmail,
                licenseInformation:hospital?.licenseInformation
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values)
                    const body:HospitalType = {
                        contactEmail:values.email,
                        name:values.name,
                        contactPhone:values.telephone,
                        address:values.address,
                        licenseInformation:values.licenseInformation,
                        operatingLicense:url,
                        hospitalLogo:logo
                    };
                    editSingleHospital(hospital.id as string, body)
                        .then((res: any) => {
                            console.log(res);
                            setSubmitting(false);
                            if(res?.errorMessage){
                                setSubmitting(false);
                                openNotification(
                                    "error",
                                    "bottomRight",
                                    res?.errorMessage || res?.errorr
                                );
                            } else{
                                openNotification(
                                    "success",
                                    "bottomRight",
                                    "Hospital updated successfully"
                                );
                                navigate('/dashboard/hospitals/')
                            }
                        })
                        .catch((err: any) => {
                            console.log(err)
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
                                "An error occured. Please try again later"
                            );
                        });

                }}
        >
            {({ errors, isSubmitting, resetForm }) => (
                <Form className={styles.form}>
                    <div className={styles.imgfield}>
                        <div className={styles.field_section_full}>
                            <p className={styles.label}>Operating Lincense</p>
                            <div className={styles.field_section_gallery}>
                                <ImageUpload
                                    props={props}
                                    disabled={uploading }
                                    loading={uploading}
                                    backgroundImage={url?.url}
                                />
                            </div>
                        </div>
                       <div className={styles.field_section_full}>
                           <p className={styles.label}>Logo</p>
                           <div className={styles.field_section_gallery}>
                               <ImageUpload
                                   props={prop}
                                   disabled={logoUpload}
                                   loading={logoUpload}
                                   backgroundImage={logo?.url}
                               />
                           </div>
                       </div>

                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Hospital name</p>
                            <EditFormInput
                                icon={<Home color="#007AFF" />}
                                type="text"
                                name='name'
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Email</p>
                            <EditFormInput
                                icon={<AiTwotoneMail />}
                                type="email"
                                name="email"
                            />
                    </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Address</p>
                            <EditFormInput
                                icon={<RiMapPinFill />}
                                type="text"
                                name="address"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Telephone</p>
                            <EditFormInput
                                icon={<BsPhoneFill />}
                                type="tel"
                                name="telephone"
                            />
                        </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>License Information</p>
                            <EditFormInput icon={<FaCompass />} type="text" name="licenseInformation" />
                        </div>
                    </div>
                    <hr
                        style={{
                            border: "1px solid rgba(31, 31, 31, 0.4)",
                            marginBottom: "20px",
                        }}
                    />

                    <div className={styles.field_section_full}>
                        <div className={styles.open_hours}>
                            &nbsp; &nbsp;
                            <div className={styles.button_group}>
                                <Button
                                    variant="ghost"
                                    style={{
                                        border: "1.5px solid #007AFF",
                                    }}
                                    type="submit"
                                    disabled={
                                        Object.keys(errors).length > 0 ||
                                        isSubmitting
                                            ? true
                                            : false
                                    }
                                >
                                    {isSubmitting ? <Spin /> : "Save"}
                                </Button>
                                <Button
                                    variant="ash"
                                    type="reset"
                                    onClick={() => {
                                        resetForm();
                                        resetExtras();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
export default EditHospitalForm;
