import React from "react";

const Review = ({
	height = "32px",
	width = "32px",
	color = "currentColor",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M16 8L17.912 11.703L22 12.297L19 15L20 19L16 16.75L12 19L13 15L10 12.297L14.2 11.703L16 8Z"
				fill={color}
			/>
			<path
				d="M17.736 30L16 29L20 22H26C26.2628 22.0004 26.523 21.9489 26.7658 21.8486C27.0087 21.7482 27.2293 21.6009 27.4151 21.4151C27.6009 21.2293 27.7482 21.0087 27.8486 20.7658C27.9489 20.523 28.0004 20.2628 28 20V8C28.0004 7.73725 27.9489 7.477 27.8486 7.23417C27.7482 6.99134 27.6009 6.7707 27.4151 6.58491C27.2293 6.39911 27.0087 6.25181 26.7658 6.15144C26.523 6.05107 26.2628 5.99961 26 6H6C5.73725 5.99961 5.477 6.05107 5.23417 6.15144C4.99134 6.25181 4.7707 6.39911 4.58491 6.58491C4.39911 6.7707 4.25181 6.99134 4.15144 7.23417C4.05107 7.477 3.99961 7.73725 4 8V20C3.99961 20.2628 4.05107 20.523 4.15144 20.7658C4.25181 21.0087 4.39911 21.2293 4.58491 21.4151C4.7707 21.6009 4.99134 21.7482 5.23417 21.8486C5.477 21.9489 5.73725 22.0004 6 22H15V24H6C4.93913 24 3.92172 23.5786 3.17157 22.8284C2.42143 22.0783 2 21.0609 2 20V8C1.99987 7.47468 2.10324 6.95447 2.30421 6.46911C2.50519 5.98375 2.79982 5.54274 3.17128 5.17128C3.54274 4.79982 3.98375 4.50519 4.46911 4.30421C4.95447 4.10324 5.47468 3.99987 6 4H26C26.5253 3.99987 27.0455 4.10324 27.5309 4.30421C28.0163 4.50519 28.4573 4.79982 28.8287 5.17128C29.2002 5.54274 29.4948 5.98375 29.6958 6.46911C29.8968 6.95447 30.0001 7.47468 30 8V20C30 21.0609 29.5786 22.0783 28.8284 22.8284C28.0783 23.5786 27.0609 24 26 24H21.165L17.736 30Z"
				fill={color}
			/>
		</svg>
	);
};

export default Review;
