import React, {useEffect, useState} from 'react';
import {AddPageTop, AddRolesForm} from "../../organisms"
import { useNavigate } from "react-router"
import {fetchAllUsers} from "../../utils";
import {UserType} from "../../types/user";
import styles from "../../organisms/AddUserToRole/AUTR.module.css";
import {Spin} from "antd";

const AddRole = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<UserType[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        fetchAllUsers().then((res: any) => {
            console.log(res)
            setData(res)
            setLoading(false)
        })
    }, [])

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <AddPageTop
                onBack={()=> navigate("/dashboard/roles")}
                title="Back to role page"
                heading="Add a new role"
            />
            {loading ? <div className={styles.loader}><Spin/></div>
                :
                <AddRolesForm role={data}/>
            }
        </div>
    );
};

export default AddRole;
