import React, { useContext } from "react";
import { FilterProvider } from "../../contexts/FilterContext";
import { Outlet } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized";

const Hospitals = () => {
  const { user } = useContext(UserContext);

  return user.permissions?.includes("HOSPITAL.READ.ALL") ||
    user.permissions?.includes("HOSPITAL.UPDATE.ALL") ||
    user.permissions?.includes("HOSPITAL.CREATE.ALL") ||
    user.permissions?.includes("APPOINTMENT.READ.SELF")||
    user.permissions?.includes("HOSPITAL.DELETE.ALL") ? (
    <FilterProvider>
      <Outlet />
    </FilterProvider>
  ) : (
    <UnauthorizedPage />
  );
};

export default Hospitals;
