import React, {useEffect, useState, useContext} from 'react';
import {useNavigate} from "react-router"
import {useParams} from "react-router-dom"
import {ConfirmModal, DeleteModal, EditHospitalForm, EditHospitalTop, UnauthorizedModal} from "../../organisms";
import {fetchSingleHospital} from "../../utils"
import {HospitalType} from "../../types/hospital"
import UserContext from '../../contexts/UserContext';

const EditHospitals = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const { user } = useContext(UserContext)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)
    const [data, setData] = useState<HospitalType>({} as HospitalType)

    const handleShowDelete = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(true)
    }

    const checkUserRole = () => {
        user?.permissions?.includes("HOSPITAL.DELETE.ALL") 
        ? setIsModalVisible(true) : setIsUnauthorizedModalVisible(true)
    }

    useEffect(() => {
        id && fetchSingleHospital(id).then((res: any) => {
            setData(res)
            console.log(res)
        })
    }, [id])
    return (
        <div style={{borderRadius: "15px", width: "100%", backgroundColor: "#ffffff"}}>
            <EditHospitalTop 
                handleDelete={checkUserRole}
                 name={data.name}
                 backUrl="/dashboard/hospitals"
                 title={data.name}
                 type="hospital"
            />
            <EditHospitalForm hospital={data}/>
            {/*confirm modal*/}
            <ConfirmModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                action={handleShowDelete}
                type="hospital"
            />

            {/*delete modal*/}
            <DeleteModal
                isModalVisible={isDeleteModalVisible}
                setIsModalVisible={setIsDeleteModalVisible}
                ids={[data?.id as string]}
                callback={() => navigate("/dashboard/hospitals")}
                type="hospital"
            />

            <UnauthorizedModal 
                isModalVisible={isUnauthorizedModalVisible} 
                setIsModalVisible={setIsUnauthorizedModalVisible} 
                action={()=> {}}
            />

        </div>
    );
};

export default EditHospitals;
