import React, { useState } from "react";
import styles from "./auf.module.css";
import { EditFormInput, DatePickerInput,SelectInput } from "../../molecules";
import { Button } from "../../atoms";
import { AiTwotoneMail } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { Formik, Form } from "formik";
import { FaKey } from "react-icons/fa";
import * as yup from "yup";
import { Spin } from "antd";
import { addAdminUser, openNotification } from "../../utils";
import { useNavigate } from "react-router-dom";
import { GiHouseKeys } from "react-icons/gi";
import { UserType } from "../../types/user";
import { EditFormInputForNin } from "../../molecules/EditFormInput";
import moment, {Moment} from "moment"
import {FaCalendarAlt} from "react-icons/fa";

const AddUserForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>();
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState<Moment | null | string | undefined>(null);
  const genderOptions = ["Male", "Female"];

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    email: yup
      .string()
      .required("mail is required")
      .matches(
        /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/,
        "please enter a valid e-mail"
      ),
    nin: yup
      .string()
      .required("nin is required")
      .matches(
        /^(CF|CM)[\dA-Za-z]{12}$/,
        "NIN must start with CF or CM and be followed by 12 digits"
      ),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?.!@$%^&*-]).{8,16}$/,
        "Password must between  8 - 16 characters and include an uppercase letter, a number, and a special symbol"
      ),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        nin: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        console.log(values);
        const data: UserType = {
          firstName: values.firstName,
          lastName: values.lastName,
          password: values.password,
          mailPrefix: values.email,
          nin: values.nin,
          birthDate : birthDate,
          gender: gender
        };
        addAdminUser(data)
          .then((res: any) => {
            console.log(res);
            setSubmitting(false);
            if (res.error) {
              setSubmitting(false);
              setError(res.error);
            } else {
              openNotification(
                "success",
                "bottomRight",
                "User added successfully"
              );
              resetForm();
              navigate("/dashboard/admin-users");
            }
          })
          .catch((err: any) => {
            console.log(err);
            setSubmitting(false);
            openNotification(
              "error",
              "bottomRight",
              "An error occurred. Please try again later"
            );
          });
      }}
    >
      {({ errors, isSubmitting, resetForm }) => (
        <Form className={styles.form} autoComplete={"off"}>
          <div className={styles.field_section}>
            <div className={styles.form_field}>
              <p className={styles.label}>First Name</p>
              <EditFormInput
                icon={<BsPersonFill />}
                type="text"
                name="firstName"
              />
            </div>
            <div className={styles.form_field}>
              <p className={styles.label}>Last Name</p>
              <EditFormInput
                icon={<BsPersonFill />}
                type="text"
                name="lastName"
              />
            </div>
          </div>

          <div className={styles.field_section}>
            <div className={styles.form_field}>
              <p className={styles.label}>E-mail</p>
              <EditFormInput
                icon={<AiTwotoneMail />}
                type="text"
                name="email"
                autoComplete={"off"}
              />
            </div>
            <div className={styles.form_field}>
              <p className={styles.label}>Password</p>
              <EditFormInput
                icon={<FaKey />}
                type="password"
                name="password"
                autoComplete={"off"}
              />
            </div>
          </div>
          <div className={styles.field_section}>
          <div className={styles.form_field}>
							<p className={styles.label}>Birth Date</p>
							<DatePickerInput
								name="birth_date"
								icon={<FaCalendarAlt/>}
								onChange={(val: any) => {
									setBirthDate(moment(val._d).format("DD.MM.YYYY"))
								}}
								value={birthDate === null ? null : moment(birthDate, "DD.MM.YYYY")}
								bordered={false}
							/>
						</div>
            <div className={styles.form_field}>
							<p className={styles.label}>Gender</p>
							<SelectInput
								icon={<BsPersonFill/>}
								options={genderOptions.map((genderOption: any) => {
									return {
										value: genderOption,
										label: genderOption
									}
								})}
								bordered={false}
								onChange={(value) => {
									setGender(value)
								}}
							/>
						</div>
          </div>
          <div className={styles.field_section}>
            <div className={styles.form_field}>
              <p className={styles.label}>National Identification Number</p>
              <EditFormInputForNin
                icon={<GiHouseKeys />}
                type="text"
                name="nin"
                autoComplete={"off"}
              />
            </div>
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className={styles.field_section_full}>
            <div className={styles.form_field}>
              &nbsp; &nbsp;
              <div className={styles.button_group}>
                <Button
                  variant="ghost"
                  style={{
                    border: "1.5px solid #007AFF",
                  }}
                  type="submit"
                  disabled={
                    Object.keys(errors).length > 0 && isSubmitting && !gender && !birthDate
                      ? true
                      : false
                  }
                >
                  {isSubmitting ? <Spin /> : "Save"}
                </Button>
                <Button
                  variant="ash"
                  type="reset"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddUserForm;
