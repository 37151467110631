import React from 'react';
import { Typography } from 'antd';
import styles from "./SidebarLink.module.css"

interface Props {
	href?: string;
	children?: React.ReactNode | string;
	style?:any;
	id?:string;
}

const SidebarLink:React.FC<Props> = ({href, children, style,id}) => {

	const { Link } = Typography;

    return (
        <Link href={href} className={styles.link} style={style} id={id}>
	      {children}
	    </Link>
    );
};


export default SidebarLink;
