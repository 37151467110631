import React from 'react';
import styles from "./ImageUpload.module.css"
import type { UploadProps } from "antd"
import { Upload as AntUpload, Spin } from "antd"
import { Upload } from "../../svg"
import { Button } from "../../atoms"

const ImageUpload = ({disabled,props, loading, backgroundImage}:{disabled:boolean, props:UploadProps, loading:boolean, backgroundImage?: string}) => {
	const { Dragger } = AntUpload;

    return (
    	<div className={styles.container}>
	       <Dragger 
	       		{...props} 
	       		className={styles.dragger} 
	       		disabled={disabled} 
	       		style={{
	       			backgroundImage:`url(${backgroundImage})`,
	       			backgroundSize: "cover",
	                backgroundRepeat: "no-repeat",
	                backgroundPosition: "center",
	       		}}
	       	>
	       	  <Spin spinning={loading}>
		       	<Upload style={{margin:"auto"}}/>
		       	<br/>
		       	<p className={styles.drag_text}>Drag and drop file here</p>
		       	<br/>
		       	<div style={{display:"flex", justifyContent:"center"}}>
		       		<hr style={{border: "1px solid rgba(31, 31, 31, 0.35)", width:"30%"}}/>
		       		<p style={{marginTop:"-10px", color:"rgba(31, 31, 31, 0.35)"}}>&nbsp;Or &nbsp;</p>
		       		<hr style={{border: "1px solid rgba(31, 31, 31, 0.35)", width:"30%"}}/>
		       	</div>
		       	<br/>
				<Button variant="ghost" type="button" style={{
					border: "2px solid #007AFF",
					borderRadius: "10px",
					color: "#007AFF",
					fontWeight:"500",
					fontSize:"18px",
					width:"100%"
				}}>Select profile picture</Button>
					       	<br/>    	
				<p className={styles.support}>Supported files: JPEG, JPG, PNG</p>
			</Spin>
	       </Dragger> 
	     </div>
    );
};

export default ImageUpload;
