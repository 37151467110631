import React from 'react';
import { AddPageTop, AddForm } from "../../organisms"
import { useNavigate } from "react-router"

const AddProvider = () => {
    const navigate = useNavigate()
    
    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
        	<AddPageTop
                onBack={()=> navigate("/dashboard/providers")}
                title="Back to provider list"
                heading="Add a new provider"
            />
        	<AddForm />
        </div>
    );
};

export default AddProvider;
