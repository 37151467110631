import React from "react";
import { FilterProvider } from "../../contexts/FilterContext";
import { Outlet } from "react-router-dom";
// import UserContext from "../../contexts/UserContext";
// import UnauthorizedPage from "../Unauthorized";
import {Helmet} from "react-helmet";

const Roles = () => {
    return(
        <FilterProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Roles || Dokitari Admin Dashboard</title>
                <link rel="canonical" href="/roles  " />
            </Helmet>
            <Outlet />
        </FilterProvider>
    )
};

export default Roles;