import React from "react";
import styles from "./euf.module.css";
import {EditFormInput} from "../../molecules";
import {Home} from "../../svg";
import {Button} from "../../atoms";
import {AiTwotoneMail,} from "react-icons/ai";
import {Form, Formik} from "formik";
import * as yup from "yup";
import {Spin} from "antd";
import {UserType} from "../../types/user"
import {editSingleUser, openNotification} from "../../utils";
import {useNavigate} from 'react-router-dom';

const EditUserForm = ({user}: { user: UserType }) => {
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        firstName: yup.string(),
        lastName: yup.string(),
    });

    return (
        <Formik
            initialValues={{
                firstName: user?.firstName,
                lastName: user?.lastName,
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting}) => {
                console.log(values)
                const body = {
                    firstName: values.firstName,
                    lastName: values.lastName,
                };
                editSingleUser(user.id as string, body)
                    .then((res: any) => {
                        console.log(res);
                        setSubmitting(false);
                        if (res?.errorMessage && res?.errorMessage !== "") {
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
                                res?.errorMessage ?? 'An error occurred, please try again later.'
                            );
                        } else {
                            openNotification(
                                "success",
                                "bottomRight",
                                " updatedUser successfully"
                            );
                            navigate('/dashboard/users/')
                        }
                    })
                    .catch((err: any) => {
                        console.log(err)
                        setSubmitting(false);
                        openNotification(
                            "error",
                            "bottomRight",
                            "An error occured. Please try again later"
                        );
                    });

            }}
        >
            {({errors, isSubmitting, resetForm}) => (
                <Form className={styles.form}>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>First Name</p>
                            <EditFormInput
                                icon={<Home color="#007AFF"/>}
                                type="text"
                                name='firstName'
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Last Name</p>
                            <EditFormInput
                                icon={<AiTwotoneMail/>}
                                type="text"
                                name="lastName"
                            />
                        </div>
                    </div>
                    <hr
                        style={{
                            border: "1px solid rgba(31, 31, 31, 0.4)",
                            marginBottom: "20px",
                        }}
                    />

                    <div className={styles.field_section_full}>
                        <div className={styles.open_hours}>
                            &nbsp; &nbsp;
                            <div className={styles.button_group}>
                                <Button
                                    variant="ghost"
                                    style={{
                                        border: "1.5px solid #007AFF",
                                    }}
                                    type="submit"
                                    disabled={
                                        Object.keys(errors).length > 0 ||
                                        isSubmitting
                                            ? true
                                            : false
                                    }
                                >
                                    {isSubmitting ? <Spin/> : "Save"}
                                </Button>
                                <Button
                                    variant="ash"
                                    type="reset"
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};
export default EditUserForm;
