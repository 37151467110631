import React, {createContext, useState, useEffect} from 'react';
import {UserType} from "../types/provider"
import {fetchUser} from "../utils"
import { Spin } from 'antd';
// import {Header, Sidebar} from "../organisms";
import styles from './context.module.css'

const UserContext = createContext({} as any);

export const UserProvider = ({children}) => {
    const [loading, setLoading] = useState(true)
    const [authenticated, setAuthenticated] = useState(false)
    const [user, setUser] = useState({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
        permissions: [],
        error: "",
    } as UserType);

    useEffect(() => {
        setLoading(true);
        fetchUser()
            .then((res: any) => {
                if (res.roles) {
                    console.log("res",res);
                    const updatedRoles = res.roles.map((role: string) => {
                        return role.replace('SCOPE_', '');
                    });
                    const updatedUser = { ...res, roles: updatedRoles };
                    setUser(updatedUser);
                    setLoading(false);
                    setAuthenticated(true);
                } else {
                    setLoading(false);
                    setAuthenticated(false);
                }
            })
            .catch((err: any) => {
                console.log(err);
                setLoading(false);
                setAuthenticated(false);
            });
    }, [setUser]);

    const value: any = {
        user,
        setUser,
        authenticated,
        setAuthenticated,
    }

    return  loading || !user ?
                   <div className={styles.loader}><Spin size={'large'}/></div>
            :
            <UserContext.Provider value={value}>
                {children}
            </UserContext.Provider>
};

export default UserContext;

