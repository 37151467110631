import React from 'react';
import styles from "./Signin.module.css"
import { Button, Input, PasswordInput } from "../../atoms"
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Spin } from "antd"
import Logo from "../../assets/Group 744.svg"
import {login} from "../../utils";

const Signin = () => {
	const validationSchema = yup.object().shape({
		email: yup.string().email("Email is invalid").required("Email is required"),
	    password: yup
	      .string()
	      .min(3, "Must be at least 3 characters")
	      .max(15, "Must be 15 characters or less")
	      .required("Password is required"),
	 });
    return (
        <div className={styles.container}>
        	<div className={styles.hero}>
        		<div className={styles.logo}>
        			<img src={Logo} alt="logo"/>
        		</div>
        		<div className={styles.hero_cont}>
        			<p className={styles.heading}>Welcome Back to Dokitari Admin Dashboard!</p>
        		    <p className={styles.info}>Please sign up with your admin dashboard login details.</p>
        		</div>

        		<p className={styles.sub}>https://search-dev.dokitari.com/</p>

        	</div>
        	<div className={styles.form}>
        	    <Formik
	               initialValues={{
	               	 email:"",
	                 password: "",
	               }}
	               validationSchema={validationSchema}
	               onSubmit={({ email,password },{ setSubmitting }) => {
	               	 setSubmitting(false)
					 login({email: email, password: password}).then((res)=>{
						 if(res){
							window.location.reload();
						 }
					 })
	               }}
	             >
	               {({ errors, isSubmitting, setFieldValue }) => (
	                  <Form className={styles.form_cont}>
	                     <p className={styles.form_heading}>Login to Dokitari search as an Admin</p>
	                     <Input
	                     	type="email"
	                     	label="Email address"
	                     	name="email"
	                     />
	                     <PasswordInput
	                     	label="Password"
	                     	name="password"
	                     />

	                     <div style={{marginTop:"20px"}}>
	                        {Object.keys(errors).length > 0 ?
		                        <Button
		                           variant="ash"
		                           style={{
		                           	padding:"32px",
		                           	width:"100%",
		                           	margin:"auto",
		                           	fontWeight: "500",
									fontSize: "16px"
		                           }}
		                           type="button"
		                           disabled
		                        >
		                          Continue
		                        </Button>
		                        :
		                        <Button
		                           variant="primary"
		                           style={{
		                            padding:"32px",
		                           	width:"100%",
		                           	margin:"auto",
		                           	fontWeight: "500",
									fontSize: "16px"
		                           }}
		                           type="submit"
		                        >
		                        {isSubmitting ? <Spin/> : "Continue"}
		                        </Button>
	                        }
	                        <a href={'/forgot-password'} className={styles.forget}>Forgot your password</a>
	                        <p className={styles.help}>Get help if having trouble signing in</p>
	                     </div>
	                  </Form>
	               )}
	            </Formik>
	            <p className={styles.form_sub}>Terms of use Privacy policy</p>

        	</div>

        </div>
    );
};

export default Signin;
