import React, {useState} from "react";
import styles from "./addrole.module.css";

const Checked = ({user, ids, setIds}) => {
    const [checked, setChecked] = useState(false);
    const onCheckChange = (e) => {
        const {checked} = e.target;
        if (checked) {
            setChecked((prev) => !prev);
            console.log(user.id)
            setIds(prev => [...prev, {id: user.id}])
        } else {
            setChecked((prev) => !prev);
            setIds(ids.filter(x => x.id !== user.id))
        }
    }

    return (
                <tr key={user.id}>
                 <td className={styles.table_data}>   <input
                          type="checkbox"
                          checked={checked}
                          onChange={onCheckChange}
                          name={user.name}
                          value={user.id}
                     />
                 </td>
                    <td className={styles.table_data}>{user.firstName}</td>
                    <td className={styles.table_email}>{user.email}</td>
                </tr>
       )
};

export default Checked;
