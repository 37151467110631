import React, {useState,useEffect} from "react";
import styles from "./AUTR.module.css";
import {Button} from "../../atoms";
import {Spin} from "antd";
import {addRole, openNotification} from "../../utils";
import {useNavigate} from 'react-router-dom';
import {RoleType} from "../../types/role";
import Checkbox from "./checkbox"

const ModifyRoleForm = ({role, data, loading}: { role: RoleType, data: any, loading: boolean }) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false)
    const [ids, setIds] = useState<string[]>([])

    useEffect(() =>{
        const selectedUsers = role?.users
        let arr:string[] =[];
        for(let i = 0; i < selectedUsers?.length; i++){
             arr.push(selectedUsers[i].id)
        }
        setIds(arr)
    },[role])

    const submit =(e:any) => {
        e.preventDefault();
        setSubmitting(true)
        addRole(role.id,ids)
            .then((res: any) => {
                console.log(res)
                openNotification(
                    "success",
                    "bottomRight",
                    "Role members updated successfully"
                );
                navigate('/dashboard/roles')
            })
            .catch((err: any) => {
                console.log(err)
                setSubmitting(false);
                openNotification(
                    "error",
                    "bottomRight",
                    "An error occurred. Please try again later"
                );
            })
    }

    return(
        loading ? <div className={styles.loader}><Spin/></div>
        :
        <form className={styles.form} onSubmit={submit}>
            <div className={styles.field_section}>
                  <div className={styles.form_field}>
                    {data?.map((user: any) =>
                         <Checkbox
                             key={user.id}
                             role={role}
                           user={user}
                           ids={ids}
                           setIds={setIds}
                       />
                    )}
            </div>
            </div>
            <div className={styles.field_section_full}>
                <div className={styles.form_field}>

                    &nbsp; &nbsp;
                    <div className={styles.button_group}>
                        <Button
                            variant="ghost"
                            style={{
                                border: "1.5px solid #007AFF",
                            }}
                            type="submit"
                            disabled={submitting}
                        >
                            {submitting ? <Spin/> : "Save"}
                        </Button>
                    </div>

                </div>
            </div>
        </form>
    )
};
export default ModifyRoleForm;
