import React from 'react';
import styles from "./ppt.module.css"
import { Button } from "../../atoms"
import { useNavigate } from "react-router"
import { PageHeader } from "antd"
import { MdEdit } from "react-icons/md"
import { AiFillDelete } from "react-icons/ai"

type Props = {
    handleDelete?:any, 
    name:string, 
    id:string | any,
    backUrl:any,
    title:string,
    showDelete:boolean,
    editUrl:string,
    pageType:string,
}

const PageTop = ({handleDelete, name, id, backUrl, title, showDelete, editUrl, pageType}:Props) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <PageHeader
                    className={styles.back}
                    onBack={()=> navigate(backUrl)}
                    title={title}
                />
                <div className={styles.buttonGroup}>
                    <Button onClick={()=> navigate(editUrl)}> <MdEdit style={{fontSize:"32px"}}/> &nbsp;Edit {pageType}</Button>
                    {showDelete ? <Button variant="secondary" onClick={handleDelete}> <AiFillDelete style={{fontSize:"32px"}} />&nbsp; Delete {pageType}</Button> : null}
                </div>
            </div>

            <div className={styles.sub_bar}>
                <p/>


            </div>
        </div>
    );
};

export default PageTop;
