import React, {useContext, useMemo, useState} from 'react';
import styles from "./header.module.css"
import {Avatar, Input, Dropdown, MenuProps} from "antd";
import {Hamburger} from "../../svg";
import {SidebarLink} from "../../molecules";
import {Logo} from "../../atoms"
import UserContext from "../../contexts/UserContext"
import {useNavigate} from "react-router";
import {searchActions} from "../../action_services";
import SearchBarDropdown from "../../molecules/SearchBarDropdown/SearchBarDropdown";




const Header = ({toggleCollapse, collapsed}: { toggleCollapse: any, collapsed: boolean }) => {
    const {Search} = Input;
    const navigate = useNavigate();
    const {user,setAuthenticated,authenticated} = useContext(UserContext)
    const [searchQuery, setSearchQuery] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };
    const handleLogout = () => {
        localStorage.clear();
        setAuthenticated(false);
        console.log("AUTH", authenticated)
        navigate("/login");

    };
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLogout}
                >
                    Logout
                </a>
            ),
        },
    ];



    const returnInitials = (a: string, b: string) => {
        if (!a && !b) {
            return 'A';
        } else {
            let r = a?.slice(0, 1)
            let s = b?.slice(0, 1)
            let t = r + s;
            return t ? t?.toUpperCase() : "A"
        }
    }


    const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };


    const handleActionClick = (link: string) => {
        setSearchQuery("");
        navigate(link)
    };

    let permissions = user.roles;
    const filteredActions = useMemo(() => {
        return searchActions(searchQuery, permissions);
    }, [searchQuery, permissions]);


    return (
        <header className={styles.header}>

            {collapsed ?
                <div style={{display: "flex", alignItems: "center"}}>
                    <Hamburger onClick={toggleCollapse}/>
                    &nbsp; &nbsp;
                    <SidebarLink href="/" id="logo">
                        <Logo collapsed={collapsed}/>
                    </SidebarLink>
                </div> :
                <Hamburger onClick={toggleCollapse}/>
            }
            <Search
                placeholder="Search Dokitari Admin panel"
                value={searchQuery}
                onChange={handleSearchQueryChange}
                style={{
                    width: collapsed ? "50%" : "60%",
                    height: "34px",
                    borderRadius: "5px",
                    fontSize: "10px",
                    fontWeight: "400",
                }}
                className={styles.search}
            />
            {searchQuery && (
                <SearchBarDropdown
                    searchQuery={searchQuery}
                    filteredActions={filteredActions}
                    handleActionClick={handleActionClick}
                />
            )}

            <div className={styles.user}>
                <div style={{ marginRight: "15px" }}>
                    <p className={styles.userName}>{user?.firstName} {user?.lastName}</p>
                    <p className={styles.userRole}>{user?.roles ? user?.roles[0] : 'ROLE_USER'}</p>
                </div>
                <Dropdown
                    menu={{items}}
                    open={dropdownVisible}
                    onOpenChange={setDropdownVisible}
                    trigger={['click']}
                >
                    {user?.imageUrl ? <Avatar alt="avatar" size={44} src={user?.imageUrl} onClick={toggleDropdown} /> :
                        <Avatar size={44} onClick={toggleDropdown}>{returnInitials(user?.firstName, user?.lastName)}</Avatar>
                    }
                </Dropdown>
            </div>

        </header>
    );
};

export default Header;
