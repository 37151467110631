import React from 'react';
import {Dropdown, Menu } from "antd";
import { TickIcon, DropdownIcon } from "../../svg"
import styles from "./Filter.module.css"

type SpecialtyFilterProps = {
	selectedSpecialty: string,
	setSelectedSpecialty: any,
    types:string[],
}

const SpecialtyFilter = ({selectedSpecialty, setSelectedSpecialty, types}: SpecialtyFilterProps) => {


      const providerTypeOptionsMenu = (
        <Menu>
            {types?.map((type) => {
                    return (<Menu.Item key={type}>
                        <div className={styles.menu_items} onClick={() => setSelectedSpecialty(type)}>
                            <span>{type}</span>
                            <span className={styles.items_tick}><TickIcon/></span>
                        </div>
                    </Menu.Item>)
                }
            )
            }
        </Menu>
    );

    return (
        <div className={styles.filterMenu_items}>
            <div className={styles.filter_items}>

                <p>Specialty Type</p>
                <Dropdown overlay={providerTypeOptionsMenu} trigger={['click']}>
                    <div onClick={e=> e.preventDefault()} className={styles.flex}>
                    <span>{selectedSpecialty || "Select Specialty Type"}</span>
                   <DropdownIcon/>
                   </div>
               </Dropdown>
                
            </div>
        </div>
    );
};

	
export default 	SpecialtyFilter;
