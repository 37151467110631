import React from 'react';
import styles from "./ept.module.css"
import { Button } from "../../atoms"
import { useNavigate } from "react-router"
import { PageHeader } from "antd"
import { AiFillDelete } from "react-icons/ai"

type Props = {
    handleDelete:any;
    name:string;
    backUrl:string;
    title:string;
    type:string;
}

const EditPageTop = ({handleDelete, name, backUrl, title, type}:Props) => {
	const navigate = useNavigate()
    return (
        <div className={styles.container}>
        	<PageHeader
        		className={styles.back}
        		onBack={()=> navigate(backUrl)}
        		title={title}
        	/>
        	<div className={styles.sub_bar}>
        		<p className={styles.p_name}>Editing {name}</p>
        		<div className={styles.buttonGroup}>
        			<Button variant="secondary" onClick={handleDelete}> <AiFillDelete style={{fontSize:"32px"}} />&nbsp; Delete this {type}</Button>
        		</div>
        	</div>
            <hr style={{border: "1px solid rgba(31, 31, 31, 0.4)"}}/>
        </div>
    );
};

export default EditPageTop;
