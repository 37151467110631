import React from 'react';
import { AddPageTop} from "../../organisms"
import { useNavigate } from "react-router"
import {AddUserForm} from "../../organisms";

const AddUser = () => {
    const navigate = useNavigate()

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <AddPageTop
                onBack={()=> navigate("/dashboard/admin-users")}
                title="Back to user page"
                heading="Add a new user"
            />
            <AddUserForm/>
        </div>
    );
};

export default AddUser;
