import React, { createContext, useState } from 'react';
import { Moment } from "moment";

const FilterContext = createContext({} as any);

export const FilterProvider = ({children}) => {
    const [selectedLocation, setSelectedLocation] = useState<string>("");
    const [selectedProviderType, setSelectedProviderType] = useState<string>("");
    const [selectedTime, setSelectedTime] = useState<Moment | null | undefined>(null);
    const [selectedStatus, setSelectedStatus] = useState<string>("");

    const value:any = {
        selectedLocation,
        setSelectedLocation,
        selectedProviderType,
        setSelectedProviderType,
        selectedTime,
        setSelectedTime,
        selectedStatus,
        setSelectedStatus
    }

    return (
       <FilterContext.Provider value={value}>
       	{children} 
       </FilterContext.Provider>
    );
};

export default FilterContext;

