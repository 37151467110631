import React, {useEffect, useState} from 'react';
import styles from "./appointments.module.css"
import { Input, Tabs, DatePicker } from "antd"
import AppointmentsTable from "../AppointmentsTable"
import {fetchAllAppointmentsByDoctor} from "../../utils";
import moment from "moment";

const Appointments = ({doctorId}:{doctorId:string|undefined}) => {
	const [filter, setFilter] = useState({status:"all",patient:"",startDate:"",endDate:"",});
	const [appointments, setAppointments] = useState([]);
	const [filteredAppointments,setFilteredAppointments] = useState([]);
	useEffect(() => {
		fetchAllAppointmentsByDoctor(doctorId).then((res:any)=> {
			setAppointments(res);
		});
	}, [doctorId]);
	useEffect(() => {
		if(appointments){
			setFilteredAppointments(appointments);
		}
	}, [appointments]);
	useEffect(() => {
		if (appointments) {
			if (filter.status !== 'all') {
				setFilteredAppointments(
					appointments.filter((item:any) => {
						return (
							(filter.status !== ''
								? item.status.value === filter.status.toUpperCase()
								: false) &&
							(filter.patient !== ''
								? `${item.patient.firstName} ${item.patient.lastName}`.toLowerCase().includes(filter.patient.toLowerCase())
								: true) &&
							(filter.startDate !== ''
								? moment(item.selectedDate,"DD.MM.YYYY").isSameOrAfter(
									moment(filter.startDate,"DD.M.YYYY"),
									'days'
								)
								: true) &&
							(filter.endDate !== ''
								? moment(item.selectedDate,"DD.MM.YYYY").isSameOrBefore(
									moment(filter.endDate,"DD.MM.YYYY"),
									'days'
								)
								: true)
						);
					}).sort((a:any, b:any) => {
					return moment(a.selectedDate,"DD.MM.YYYY").isAfter(moment(b.selectedDate,"DD.MM.YYYY"),'days') ? -1 : 1;
				})
				);
			} else {
				setFilteredAppointments(
					appointments.filter((item:any) => {
						return (
							(filter.patient !== ''
								? `${item.patient.firstName} ${item.patient.lastName}`.toLowerCase().includes(filter.patient.toLowerCase())
								: true) &&
							(filter.startDate !== ''
								? moment(item.selectedDate,"DD.MM.YYYY").isSameOrAfter(
									moment(filter.startDate,"DD.M.YYYY"),
									'days'
								)
								: true) &&
							(filter.endDate !== ''
								? moment(item.selectedDate,"DD.MM.YYYY").isSameOrBefore(
									moment(filter.endDate,"DD.MM.YYYY"),
									'days'
								)
								: true)
						);
					}).sort((a:any, b:any) => {
						return moment(a.selectedDate,"DD.MM.YYYY").isAfter(moment(b.selectedDate,"DD.MM.YYYY"),'days') ? -1 : 1;
					})
				);
			}
		}
	}, [filter, appointments]);


    return (
        <div className={styles.container}>

	        <p className={styles.heading}>	Appointments</p>
	        <hr style={{backgroundColor:"black"}}/>	
	        <div className={styles.top_section}>
	        	<div>
	        		<p className={styles.criteria}>	Filter by status</p>
	        		<Tabs size="small" type="card" tabBarStyle={{
	        			backgroundColor: "#F7F7F8",
                        borderRadius: "6px",
                        padding:"8px 10px",
                        height:"40px"
	        		}} id="appointment-filter-tab"
						  onChange={(activeKey) => setFilter({ ...filter, status: activeKey })}
					>
	        			<Tabs.TabPane tab="All" key="all">	</Tabs.TabPane>
	        			<Tabs.TabPane tab="Confirmed" key="confirmed">	</Tabs.TabPane>
	        			<Tabs.TabPane tab="Concluded" key="concluded">	</Tabs.TabPane>
	        			<Tabs.TabPane tab="Cancelled" key="cancelled">	</Tabs.TabPane>
	        		</Tabs>	

	        	</div>
	        	<div>
	        		<p className={styles.criteria}>	Filter by patients</p>
	        		<Input placeholder="Filter by patients ..." onChange={(name) => setFilter({ ...filter, patient: name.target.value })}
						   className={styles.input}/>
	        	</div>
	        	<div>
	        		<p className={styles.criteria}>Date range</p>
	        		<div>
						<DatePicker
							onChange={(date) => {
								const newStartDate = date ? date.format('DD.MM.YYYY') : '';
								setFilter({ ...filter, startDate: newStartDate });
							}}
							placeholder="Start date"
							className={styles.input}
						/>
	        			&nbsp; &nbsp;
						<DatePicker
							onChange={(date) => {
								const newStartDate = date ? date.format('DD.MM.YYYY') : '';
								setFilter({ ...filter, endDate: newStartDate });
							}}
							placeholder="End date"
							className={styles.input}
						/>
	        		</div>	
	        	</div>
	        </div>

	        <AppointmentsTable appointments={filteredAppointments}/>

        </div>
    );
};

export default 	Appointments;
