import React, {useState, useEffect} from 'react';
import {HospitalPageTop, UnauthorizedModal} from "../../organisms"
import {fetchAllHospital} from "../../utils"
import HospitalsTable from "../../organisms/HospitalTable";
import {HospitalType} from "../../types/hospital"
import {useSearchParams} from "react-router-dom"
import DoctorImg from "../../assets/doctor.svg";
import {Helmet} from "react-helmet";

const HospitalPage = () => {
    const [param, setParam] = useSearchParams();
    const modal = param.get("modal")
    const [isModalVisible, setIsModalVisible] = useState(modal !== null)
    const [hospitals, setHospitals] = useState<HospitalType[]>([])
    const [reload, setReload] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState();
    const [paginationDetails, setPaginationDetails] = useState({
        isFirstPage: true,
        isLastPage: false,
        pageSize: 0,
        currentPageNumber: 0,
        totalNumberOfPages: 0,
        totalNumberOfResults: 0
    });

    useEffect(() => {
        setLoading(true)
        fetchAllHospital(paginationDetails.currentPageNumber, searchTerm).then((res: any) => {
            setHospitals(res.content)
            setPaginationDetails({
                isFirstPage: res.first,
                isLastPage: res.last,
                pageSize: res.size,
                currentPageNumber: res.number,
                totalNumberOfPages: res.totalPages,
                totalNumberOfResults: res.totalElements
            });
            setLoading(false)
        })
    }, [paginationDetails.currentPageNumber, reload, searchTerm])

    const onCurrentPage = (page: number) => {
        setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1
        })
    }

    const onSearch = (searchTerm) => {
        setPaginationDetails(paginationDetails => ({...paginationDetails, currentPageNumber: 0}))
        setSearchTerm(searchTerm)
    }

    function convertToProps(hospital) {
        return {
            key: hospital?.id,
            operatingLicense: hospital?.operatingLicense || DoctorImg,
            name: hospital?.name || "NA",
            contactEmail: hospital?.contactEmail || "NA",
            contactPhone: hospital?.contactPhone || "NA",
            address: hospital?.address || "NA",
            licenseInformation: hospital?.licenseInformation || "NA",
            errorMessage: hospital?.errorMessage || "NA",
        }
    }

    return (
        <>
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Hospitals || Dokitari Admin Dashboard</title>
                    <link rel="canonical" href="https://ng-dev.dokitari.com/" />
                </Helmet>
                <HospitalPageTop hospitals={hospitals.map(convertToProps)} onSearch={onSearch}/>
                <HospitalsTable
                    hospitals={hospitals.map(convertToProps)}
                    reload={reload}
                    setReload={setReload}
                    paginationDetails={paginationDetails}
                    tableLoading={loading}
                    onCurrentPage={onCurrentPage}/>
                <UnauthorizedModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    action={() => setParam({})}/>
            </div>
        </>

    );
};

export default HospitalPage;
