import React from 'react';
import {Dropdown, Menu } from "antd";
import { TickIcon, DropdownIcon } from "../../svg"
import styles from "./Filter.module.css"

type ProviderTypeFilterProps = {
	selectedProviderType: string,
	setSelectedProviderType: any,
    types:string[],
}

const 	ProviderTypeFilter = ({selectedProviderType, setSelectedProviderType, types}: ProviderTypeFilterProps) => {

	 const providerTypeList : string[] = types

      const providerTypeOptionsMenu = (
        <Menu>
            {providerTypeList?.map((ProviderType) => {
                    return (<Menu.Item key={ProviderType}>
                        <div className={styles.menu_items} onClick={() => setSelectedProviderType(ProviderType)}>
                            <span>{ProviderType}</span>
                            <span className={styles.items_tick}><TickIcon/></span>
                        </div>
                    </Menu.Item>)
                }
            )
            }
        </Menu>
    );

    return (
        <div className={styles.filterMenu_items}>
            <div className={styles.filter_items}>

                <p>Provider Type</p>
                <Dropdown overlay={providerTypeOptionsMenu} trigger={['click']}>
                    <div onClick={e=> e.preventDefault()} className={styles.flex}>
                    <span>{selectedProviderType || "Select Provider Type"}</span>
                   <DropdownIcon/>
                   </div>
               </Dropdown>
                
            </div>
        </div>
    );
};

	
export default 	ProviderTypeFilter;
