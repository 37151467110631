import React, {useState,useEffect} from 'react';
import { RolePageTop } from "../../organisms"
import {FetchAllUserRoles,loadRolesData} from "../../utils"
import {RoleTable} from "../../organisms";
import {Props as DataProps} from "../../organisms/RoleTable";
import { RoleType } from "../../types/role"

const AllRoles = () => {
    const [ current, setCurrent ] = useState<number>(1)
    const [role, setRoles ] = useState<RoleType[]>([])
    const [data, setData] = useState<DataProps[]>([])
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ paginationDetails, setPaginationDetails ] = useState({
        isFirstPage: true,
        isLastPage: false,
        currentPageNumber: 0,
        totalNumberOfPages: 0,
        totalNumberOfResults: 0
    })
    //fetch providers
    useEffect(()=> {
        setLoading(true)
        FetchAllUserRoles((current - 1)).then((res:any) => {
            setRoles(res.content)
            setPaginationDetails({
                isFirstPage: res.first,
                isLastPage: res.last,
                currentPageNumber: res.number,
                totalNumberOfPages: res.totalPages,
                totalNumberOfResults: res.totalElements
            })
            setLoading(false)
        })
    },[current])


    useEffect(()=> {
        loadRolesData(role).then((res:any) => {
            setData(res)
        })
    },[role])

    const onCurrentPage = (page: number) => {
        setCurrent(page)
        setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1
        })
    }


    return (
        <div>
            <RolePageTop data={data} />
            <RoleTable
                data={data}
                paginationDetails={paginationDetails}
                tableLoading={loading}
                onCurrentPage={onCurrentPage}
            />
        </div>
    );
};

export default AllRoles;
