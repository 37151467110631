import React,{ useContext } from 'react';
import { Card } from "antd"
import { AiOutlineMedicineBox, AiFillProfile } from "react-icons/ai";
import { HiOutlineUser } from "react-icons/hi";
import { MdAddBusiness } from "react-icons/md"
import { RiAdminFill } from "react-icons/ri";
import { FaFileMedical, FaClinicMedical, FaUsers } from "react-icons/fa"
import { TbDiscount, TbShoppingCartDiscount } from "react-icons/tb"
import {
  People,
  Review,
  Doctor,
} from "../../svg";
import UserContext from '../../contexts/UserContext';
import styles from "./Overview.module.css"
import { useNavigate } from "react-router-dom"
import {Helmet} from "react-helmet";


const Overview = () => {
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const roles = [
		{
			role:"ADMIN",
			properties:["View your profile","Update your profile"],
			links:[`/dashboard/admin-users/${user.id}`, `/dashboard/admin-users/${user.id}/edit`],
			icons:[<p 
							style={{
		        		backgroundColor:"rgba(132,3,252,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"

		        	}}
		        	>
	        			<HiOutlineUser style={{ fontSize: "35px",color:"#8403fc", margin:"auto"  }} />
	        		</p>,
	        		<p 
					style={{
		        		backgroundColor:"rgba(26,201,158,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px"
		        	}}
		        	>
	        			<AiFillProfile style={{ fontSize: "35px", color:"#1ac99e", margin:"auto" }}/>
	        		</p>
	        	]

		},
		{
			role:"SEARCH_REVIEWS_ADMIN",
			properties:["Reviews Overview"],
			links:["/dashboard/reviews"],
			icons:[<p 
					style={{
		        		backgroundColor:"rgba(16,263,29,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center",
		        		color:"#10ec1d"
		        	}}
		        	>
	        			<Review />
	        		</p>
	        	]
		},
		{
			role:"SEARCH_PROVIDERS_ADMIN",
			properties:["Providers Overview", "Add a new provider"],
			links:["/dashboard/providers", "/dashboard/providers/add"],
			icons:[<p 
					style={{
		        		backgroundColor:"rgba(108,26,201,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center",
		        		color:"#6c1ac9"

		        	}}
		        	>
	        			<People />
	        		</p>,
	        		<p 
					style={{
		        		backgroundColor:"rgba(252,186,3,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"
		        	}}
		        	>
	        			<MdAddBusiness style={{ fontSize: "35px", color:"#fcba03", margin:"auto" }}/>
	        		</p>
	        	]
		},
		{
			role:"DOCTOR_ADMIN",
			properties:["Doctors Overview", "Add a new doctor"],
			links:["/dashboard/doctors", "/dashboard/doctors/add"],
			icons:[<p 
					style={{
		        		backgroundColor:"rgba(252,3,3,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center",
		        		color:"#fc0303"

		        	}}
		        	>
	        			<Doctor />
	        		</p>,
	        		<p 
					style={{
		        		backgroundColor:"rgba(3,252,198,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"
		        	}}
		        	>
	        			<FaFileMedical style={{ fontSize: "35px", color:"#03fcc6", margin:"auto" }}/>
	        		</p>
	        	]
		},
		{
			role:"HOSPITAL_ADMIN",
			properties:["Hospitals Overview", "Add a new hospital"],
			links:["/dashboard/hospitals", "/dashboard/hospitals/add"],
			icons: [<p 
					style={{
		        		backgroundColor:"rgba(252,3,119,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"

		        	}}
		        	>
	        			<AiOutlineMedicineBox style={{ fontSize: "35px",color:"#fc0377", margin:"auto"  }} />
	        		</p>,
	        		<p 
					style={{
		        		backgroundColor:"rgba(3,186,252,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"
		        	}}
		        	>
	        			<FaClinicMedical style={{ fontSize: "35px", color:"#03bafc", margin:"auto" }}/>
	        		</p>
	        	]
		},

		{
			role:"PROMO_CODE_ADMIN",
			properties:["Promocodes Overview", "Add a new promocode"],
			links:["/dashboard/promocodes", "/dashboard/promocodes/add"],
			icons: [<p 
					style={{
		        		backgroundColor:"rgba(50,135,168,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"

		        	}}
		        	>
	        			<TbShoppingCartDiscount  style={{ fontSize: "35px",color:"#3287a8", margin:"auto"  }} />
	        		</p>,
	        		<p 
					style={{
		        		backgroundColor:"rgba(50,168,143,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"
		        	}}
		        	>
	        			<TbDiscount style={{ fontSize: "35px", color:"#32a88f", margin:"auto" }}/>
	        		</p>
	        	]
		},

		{
			role:"SUPER_ADMIN",
			properties:["Admins Overview", "Add a new admin user"],
			links:["/dashboard/admin-users", "/dashboard/admin-users/add"],
			icons: [<p 
					style={{
		        		backgroundColor:"rgba(128,201,26,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px"
		        	}}
		        	>
	        			<FaUsers style={{ fontSize: "35px", color:"#80c91a", margin:"auto" }}/>
	        		</p>,
	        		<p
					style={{
		        		backgroundColor:"rgba(252,3,186,0.1)",
		        		padding: "10px",
		        		borderRadius:"10px",
		        		width:"55px",
		        		textAlign:"center"
		        	}}
		        	>
	        			<RiAdminFill style={{ fontSize: "35px", color:"#fc03ba", margin:"auto" }}/>
	        		</p>
	        	]
		}
	]

    return (
        <div className={styles.container}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Overview || Dokitari Admin Dashboard</title>
				<link rel="canonical" href="https://ng-dev.dokitari.com/" />
			</Helmet>
         {user.roles?.includes("ADMIN") || user.roles?.includes("SUPER_ADMIN") ? (roles[0].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[0].links[index])}>
	        	<p>{roles[0].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("SEARCH_ADMIN") || user.roles?.includes("SUPER_ADMIN") ? (roles[1].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[1].links[index])}>
	        	<p>{roles[1].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("SEARCH_ADMIN") || user.roles?.includes("SUPER_ADMIN") ? (roles[2].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[2].links[index])}>
	        	<p>{roles[2].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("SEARCH_REVIEWS_ADMIN") || user.roles?.includes("SUPER_ADMIN") || !user.roles.includes("SEARCH_ADMIN") ? (roles[1].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[1].links[index])}>
	        	<p>{roles[1].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("SEARCH_PROVIDERS_ADMIN") || user.roles?.includes("SUPER_ADMIN") || !user.roles.includes("SEARCH_ADMIN") ? (roles[2].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[2].links[index])}>
	        	<p>{roles[2].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("DOCTOR_ADMIN") || user.roles?.includes("SUPER_ADMIN") ? (roles[3].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[3].links[index])}>
	        	<p>{roles[3].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("HOSPITAL_ADMIN") || user.roles?.includes("SUPER_ADMIN") ? (roles[4].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[4].links[index])}>
	        	<p>{roles[4].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("PROMO_CODE_ADMIN") || user.roles?.includes("SUPER_ADMIN")  ? (roles[5].properties).map((feature,index) => (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[5].links[index])}>
	        	<p>{roles[5].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }

         {user.roles?.includes("SUPER_ADMIN") ? (roles[6].properties).map((feature,index)=> (
         	<Card style={{width:"32%",maxWidth:"280px",margin:"10px", cursor:"pointer"}} key={index} onClick={()=> navigate(roles[6].links[index])}>
	        	<p>{roles[6].icons[index]}</p>
	        	<p className={styles.text}>{feature}</p>
        	</Card>
         )) : null }
        </div>
    );
};


export default Overview;
