import React from "react";
import styles from "./esf.module.css";
import {CheckBox} from "../FormInput";
import {EditButton} from "../FormInput";


const EditStaff = ({
                       ids,
                       setIds,
                       staffs,
                       showModal,
                       submitting,
                       submit,
                       user
                   }: {user:string, staffs: any, ids: Set<string>, setIds: (ids: Set<string>) => void, showModal: () => void, submitting: boolean, submit: (e: any) => void }) => {
        return (
            <>
                <form className={styles.form} onSubmit={submit}>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            {staffs.map((staff: any) =>
                                <div key={staff.id}>
                                    <CheckBox
                                        ids={ids}
                                        setIds={setIds}
                                        user={staff}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.hospitaldoctor_container}><p>Want to Add a new {user}?<span
                        className={styles.span} onClick={showModal}>click here</span></p></div>
                    <div className={styles.field_section_full}>
                        <div className={styles.form_field}>
                            <EditButton submitting={submitting} ids={ids}/>
                        </div>
                    </div>
                </form>
            </>
        )
    }
;
export default EditStaff;
