import React, { useState, useContext } from 'react';
import { Button, Table, Space, Checkbox } from 'antd';
// import { StatusTag } from "../../molecules"
import { AiOutlineDelete } from "react-icons/ai"
import { MdOutlineCloudDownload } from "react-icons/md"
import styles from "./UserTable.module.css"
import { Edit } from "../../svg";
import type { ColumnsType } from "antd/es/table"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import { useNavigate } from "react-router-dom"
import Pagination from "../Pagination"
import { Props as PaginationProps } from "../Pagination"
import ConfirmModal from "../Modal/ConfirmModal"
import DeleteModal from "../Modal/DeleteModal"
import { UserHeaders } from "../../utils"
import { CSVLink } from "react-csv"
import UserContext from '../../contexts/UserContext';
import UnauthorizedModal from '../Modal/UnauthorizedModal';

export interface Props{
    key: any;
    firstName: string | any;
    lastName:string | any;
    email: any;
    roles:string | any;
}

interface UserTableProps{
    paginationDetails: PaginationProps['paginationDetails'],
    onCurrentPage: PaginationProps['onCurrentPage'],
    tableLoading: boolean,
    reload:boolean,
    setReload:any,
    data: Props[],
}

const HospitalsTable = ({data,reload, setReload, onCurrentPage, paginationDetails, tableLoading}:UserTableProps) => {
    const navigate = useNavigate()
     const { user } = useContext(UserContext)
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked ] = useState(true)
    const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false)
    const [ isDeleteModalVisible, setIsDeleteModalVisible ] = useState<boolean>(false)
     const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)
    const [ ids, setIds ] = useState<string[]>([])

    const columns: ColumnsType<Props> = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
            width:"12%",
            render: (_, {firstName, key}) => (
                <div onClick={()=> navigate(`/dashboard/admin-users/${key}`)} style={{
                    cursor:"pointer",
                    textTransform:"capitalize",
                    display:"flex",
                    alignItems:"flex-start"
                }}
                >
                    {firstName}
                </div>
            ),
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
            width:"12%",
            render: (_, {lastName})=> (
                <div
                    style={{
                        cursor:"pointer",
                        textTransform:"capitalize",
                        display:"flex",
                        alignItems:"flex-start"
                    }}
                >
                    <p>{lastName}</p>
                </div>
            ),
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'email',
            width:"25%",
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            width:"25%",
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            width:"18%",
            render: (_, {key}) => (
                <Space size="middle">
                    <Edit
                        width="20px"
                        height="20px"
                        style={{cursor:"pointer"}}
                        onClick={()=> navigate(`/dashboard/admin-users/${key}/edit`)}
                    />
                    <AiOutlineDelete
                        style={{
                            color:"#FD4C06",
                            fontSize:"20px",
                            cursor:"pointer"
                        }}
                        onClick={()=> {
                            if(user?.permissions?.includes("ADMIN_USER.DELETE.ALL") || (user?.permissions?.includes("ADMIN_USER.DELETE.SELF") && user?.id === key)){
                                setIsModalVisible(true)
                                setIds([key])
                            } else{
                                setIsUnauthorizedModalVisible(true)
                            } 
                        }}
                    />
                </Space>
            ),
        }
        // {
        //     title: 'Disabled',
        //     dataIndex: 'disabled',
        //     key: 'disabled',
        //     width:"12%",
        //     render: (_, {disabled}) => (
        //         <Space size="middle">
        //             <StatusTag status={disabled} />
        //         </Space>
        //     ),
        // },
    ];

    const handleShowDelete = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(true)
    }


    const start = () => {
        setLoading(true); // ajax request after empty completing

        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };

    const onSelectChange = (newSelectedRowKeys: any) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked)
        setSelectedRowKeys([])

    }

    const selectedData = () => {
        let arr = [] as Props[]
        for(let i=0; i<selectedRowKeys.length; i++){
            const r = data.filter(x => x.key === selectedRowKeys[i])
            arr= arr.concat(r)
        }
        return arr
    }

    return (
        <div>
            { hasSelected ?  <div
                style={{
                    marginBottom: 16,
                    display:"flex",
                    alignItems:"center"
                }}
            >
                <Button onClick={start} loading={loading} className={styles.topBtns} >
                    <Checkbox checked={checked} onChange={onChange}>
                        {selectedRowKeys.length} hospitals selected

                    </Checkbox>
                </Button>
                {/*delete selected providers*/}
                <Button
                    loading={loading}
                    className={styles.topBtns}
                    onClick={()=> {
                        if(user?.permissions?.includes("ADMIN_USER.DELETE.ALL") || (user?.permissions?.includes("ADMIN_USER.DELETE.SELF") && selectedRowKeys.includes(user?.id as string || ''))){
                            setIsModalVisible(true)
                            setIds(selectedRowKeys)
                        } else{
                            setIsUnauthorizedModalVisible(true)
                        } 
                    }}
                >
                    <AiOutlineDelete style={{color:"#1f1f1f", fontSize:"20px"}}/>
                </Button>

                {/*// download selected providers*/}
                <CSVLink data={selectedData()} filename={"Dokitari Hospitals"} headers={UserHeaders}>
                    <Button  loading={loading} className={styles.topBtns}>
                        <MdOutlineCloudDownload style={{color:"#1f1f1f", fontSize:"20px"}}/>
                    </Button>
                </CSVLink>
            </div> :null }
            <div style={{padding:"0px 10px 20px 10px", borderRadius:"15px", backgroundColor:"#ffffff"}}>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    className={styles.adminTable}
                    pagination={false}
                    scroll={{x:"65%", y:"65%"}}
                    loading={tableLoading}
                />

                <Pagination paginationDetails={paginationDetails} onCurrentPage={onCurrentPage}/>
                {/*confirm modal*/}
                <ConfirmModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    action={handleShowDelete}
                    type="user"
                />

                {/*delete modal*/}
                <DeleteModal
                    isModalVisible={isDeleteModalVisible}
                    setIsModalVisible={setIsDeleteModalVisible}
                    ids={ids}
                    callback={() => setReload(!reload)}
                    type="admin-user"
                />

                <UnauthorizedModal 
                    isModalVisible={isUnauthorizedModalVisible} 
                    setIsModalVisible={setIsUnauthorizedModalVisible} 
                    action={()=> {}}
                />
            </div>
        </div>

    );
};

export default HospitalsTable;
