import React from 'react';
import styles from "./rpt.module.css"
import { Button } from "../../atoms"
import FilterDropdown  from"../FilterDropdown"
import { MdCloudDownload } from 'react-icons/md';
import {CSVLink} from "react-csv";
import {roleHeaders} from "../../utils";
import {AiOutlinePlus} from "react-icons/ai";
import {useNavigate} from "react-router";

const RolePageTop = ({data}: any) => {
    const navigate = useNavigate();
    const applyFilters = () => {
        console.log("filter")
    }

    const resetFilters = () => {
        console.log("filter")
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <p className={styles.title}>Role List</p>

                <div className={styles.buttonGroup}>
                    <Button onClick={()=> navigate("/dashboard/roles/add")}><AiOutlinePlus style={{fontSize:"32px"}}/> &nbsp;Create New Role</Button>
                    <CSVLink data={data} filename={"Admin Roles"} headers={roleHeaders}>
                        <Button variant="outline"><MdCloudDownload style={{fontSize:"32px"}}/> &nbsp; Download Excel</Button>
                    </CSVLink>
                </div>
            </div>
            <FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters}/>
        </div>

    );
};

export default RolePageTop;
