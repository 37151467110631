import React, {useState} from "react";
import styles from "./adfm.module.css";
import {AiTwotoneMail} from "react-icons/ai";
import {BsPhoneFill, BsPersonFill} from "react-icons/bs";
import {Spin} from "antd";
import {Button} from "../../atoms";
import {fetchNin, openNotification,addFrontdeskManager} from "../../utils";
import {
    FormInput
} from "../../molecules";
import {Link} from "react-router-dom";

const AddHospitalManager = ({data,cancel,reloadHospitalDetails}) => {
    const [submitting, setSubmitting] = useState(false);
    const [nin, setNin] = useState<string>("");
    const [user,setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone:"",
        id:""
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [isNinValidated, setIsNinValidated] = useState<boolean>(false);
    const [ninError, setNinError] = useState("");
    const handleNinChange = (e: any) => {
        setNin(e.target.value.toUpperCase())
    };
    const handleChange = (event : any) => {
        const value = event.target.value;
        setUser({
            ...user,
            [event.target.name]: value
        });
    }
    const handleNin = (e: any) => {
        e.preventDefault()
        setUser({firstName: "", lastName: "", email: "", phone:"", id:""})
        setNinError("")
        setLoading(true)
        if(nin) {
            fetchNin(nin).then((res: any) => {
                if (!res.id) {
                    setNinError(() => res?.errorMessage)
                    setIsNinValidated(() => false)
                } else {
                   setUser(res)
                    setIsNinValidated(() => true)
                }
                setLoading(() => false)
            }).catch((err: any) => {
                console.log(err)
                setLoading(false);
                openNotification(
                    "error",
                    "bottomRight",
                    "An error occured. Please try again later"
                );
            })

        }
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        setSubmitting(true)
        const body = {
            id:user.id
        };
        addFrontdeskManager(data.id,body)
            .then((res: any) => {
                console.log(res)
                openNotification(
                    "success",
                    "bottomRight",
                    "Manager added successfully"
                );
                setSubmitting(false)
            })
            .catch((err: any) => {
                console.log(err)
                setSubmitting(false);
                openNotification(
                    "error",
                    "bottomRight",
                    "An error occurred. Please try again later"
                );
            }).finally(() => {
            cancel();
            reloadHospitalDetails()
            setSubmitting(false);
        })
    }
    return (
        <form className={styles.form}>
            <div>
                <div className={styles.field_section}>
                    <div className={styles.form_field}>
                        <p style={{padding:'10px'}} className={styles.label}>Enter National Identification Number</p>
                        <div className={styles.nin_container}>
                            <input className={styles.nin_input} type={'text'} value={nin}
                                   onChange={handleNinChange} name={'nin'}/>
                            <button type={'button'} onClick={handleNin} className={styles.nin_button}>{loading ?
                                <Spin/> : 'Check NIN'}</button>
                        </div>
                    </div>
                </div>
                {ninError ? <div style={{display:'flex',alignItems:'center'}}><p style={{color:'red',fontSize:'15px',padding:'10px'}}>{ninError}</p><span><Link to={'/dashboard/hospitals/add/front-desk-manager'}>Add Frontdesk Manager</Link></span></div>: null}
                {user.id ? <>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>First Name</p>
                            <FormInput change={handleChange} value={user.firstName} type={'text'} name={'firstName'} disabled={isNinValidated} icon={<BsPersonFill/>}/>
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Last Name</p>
                            <FormInput change={handleChange} value={user.lastName} type={'text'} name={'lastName'} disabled={isNinValidated} icon={<BsPersonFill/>}/>
                        </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Email</p>
                            <FormInput change={handleChange} value={user.email} type={'text'} name={'email'} disabled={isNinValidated} icon={<AiTwotoneMail/>}/>
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Phone Number</p>
                            <FormInput change={handleChange} value={user.phone} type={'text'} name={'phone'} disabled={isNinValidated} icon={<BsPhoneFill/>}/>
                        </div>
                    </div>
                </> : null}
            </div>
            <div className={styles.field_section_full}>
                <div className={styles.form_field}>
                    &nbsp; &nbsp;
                    <div className={styles.button_group}>
                        <button
                            style={{
                                border: "1.5px solid #007AFF",
                            }}
                            onClick={(e)=>{
                                handleSubmit(e);
                                cancel(e)
                            }}
                            disabled={submitting || !user.firstName || !user.email || !user.lastName}
                            type="submit"
                        >
                            {submitting ? <Spin/> : "Save"}
                        </button>
                        <Button
                            variant="ash"
                            type="reset"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    )
        ;
};

export default AddHospitalManager;
