import React, {useState, useContext} from 'react';
import {Button, Table, Space, Avatar, Checkbox} from 'antd';
import {AiOutlineDelete, AiOutlineUserAdd} from "react-icons/ai"
import {MdOutlineCloudDownload} from "react-icons/md"
import styles from "./HospitalsTable.module.css"
import {Edit} from "../../svg";
import type {ColumnsType} from "antd/es/table"
import type {CheckboxChangeEvent} from "antd/es/checkbox"
import {useNavigate} from "react-router-dom"
import Pagination from "../Pagination"
import {Props as PaginationProps} from "../Pagination"
import ConfirmModal from "../Modal/ConfirmModal"
import DeleteModal from "../Modal/DeleteModal"
import {hospitalHeaders} from "../../utils"
import {CSVLink} from "react-csv"
import UserContext from '../../contexts/UserContext';
import UnauthorizedModal from '../Modal/UnauthorizedModal';

export interface HospitalProps {
    key: any;
    operatingLicense: string | any;
    name: string | any;
    contactEmail: any;
    contactPhone: any;
    address: string | any;
    errorMessage: any;
    licenseInformation: string | any;
}

interface HospitalTableProps {
    paginationDetails: PaginationProps['paginationDetails'],
    onCurrentPage: PaginationProps['onCurrentPage'],
    tableLoading: boolean,
    reload: boolean,
    setReload: any,
    hospitals: HospitalProps[],
}

const HospitalsTable = ({
                            hospitals,
                            reload,
                            setReload,
                            onCurrentPage,
                            paginationDetails,
                            tableLoading
                        }: HospitalTableProps) => {
    const navigate = useNavigate()
    const {user} = useContext(UserContext)

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false)
    const [isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)
    const [ids, setIds] = useState<string[]>([])

    const columns: ColumnsType<HospitalProps> = [
        {
            title: 'Operating License',
            dataIndex: 'operatingLicense',
            key: 'operatingLicense',
            width: "15%",
            render: (_, {operatingLicense, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}>
                    <Avatar
                        src={operatingLicense?.url}
                        alt={'operatingLicense'}
                        size={50}
                        className={styles.providerImage}
                    />
                </div>
            ),
        },
        {
            title: 'hospital name',
            dataIndex: 'name',
            key: 'name',
            width: "17%",
            render: (_, {name, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}
                     style={{
                         cursor: "pointer",
                         textTransform: "capitalize",
                         display: "flex",
                         alignItems: "flex-start"
                     }}
                >
                    <p>{name}</p>
                </div>
            ),
        },
        {
            title: 'EMAIL',
            dataIndex: 'contactEmail',
            key: 'contactEmail',
            width: "13%",
            render: (_, {contactEmail, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}>
                    <p>{contactEmail}</p>
                </div>
            ),
        },
        {
            title: 'PHONE',
            dataIndex: 'contactPhone',
            key: 'contactPhone',
            width: "13%",
            render: (_, {contactPhone, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}>
                    <p>{contactPhone}</p>
                </div>
            ),
        },
        {
            title: 'License Info',
            dataIndex: 'licenseInformation',
            key: 'licenseInformation',
            width: "15%",
            render: (_, {licenseInformation, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}>
                    <p>{licenseInformation}</p>
                </div>
            ),
        },
        {
            title: 'ADDRESS',
            dataIndex: 'address',
            key: 'address',
            width: "15%",
            render: (_, {address, key}) => (
                <div onClick={() => navigate(`/dashboard/hospitals/${key}`)}>
                    <p>{address}</p>
                </div>
            ),
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            width: "13%",
            render: (_, {key}) => (
                <Space size="middle">
                    <Edit
                        width="20px"
                        height="20px"
                        style={{cursor: "pointer"}}
                        onClick={() => navigate(`/dashboard/hospitals/${key}/edit`)}
                    />
                    <AiOutlineDelete
                        style={{
                            color: "#FD4C06",
                            fontSize: "20px",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            if (user?.permissions?.includes("HOSPITAL.DELETE.ALL")) {
                                setIsModalVisible(true)
                                setIds([key])
                            } else {
                                setIsUnauthorizedModalVisible(true)
                            }
                        }}
                    />
                    <AiOutlineUserAdd style={{
                        fontSize: "20px",
                        cursor: "pointer"
                    }}
                                      onClick={() => navigate(`/dashboard/hospitals/${key}/edit/hospitalAdmin`)}
                    />
                </Space>
            ),
        }
    ];

    const handleShowDelete = () => {
        setIsModalVisible(false);
        setIsDeleteModalVisible(true)
    }


    const start = () => {
        setLoading(true); // ajax request after empty completing

        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };

    const onSelectChange = (newSelectedRowKeys: any) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked)
        setSelectedRowKeys([])

    }

    const selectedData = () => {
        let arr = [] as HospitalProps[]
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const r = hospitals.filter(x => x.key === selectedRowKeys[i])
            arr = arr.concat(r)
        }
        return arr
    }

    return (
        <div>
            {hasSelected ? <div
                style={{
                    marginBottom: 16,
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <Button onClick={start} loading={loading} className={styles.topBtns}>
                    <Checkbox checked={checked} onChange={onChange}>
                        {selectedRowKeys.length} hospitals selected

                    </Checkbox>
                </Button>
                {/*delete selected providers*/}
                <Button
                    loading={loading}
                    className={styles.topBtns}
                    onClick={() => {
                        if (user?.permissions?.includes("HOSPITAL.DELETE.ALL")) {
                            setIsModalVisible(true)
                            setIds(selectedRowKeys)
                        } else {
                            setIsUnauthorizedModalVisible(true)
                        }
                    }}
                >
                    <AiOutlineDelete style={{color: "#1f1f1f", fontSize: "20px"}}/>
                </Button>

                {/*// download selected providers*/}
                <CSVLink data={selectedData()} filename={"Dokitari Hospitals"} headers={hospitalHeaders}>
                    <Button loading={loading} className={styles.topBtns}>
                        <MdOutlineCloudDownload style={{color: "#1f1f1f", fontSize: "20px"}}/>
                    </Button>
                </CSVLink>
            </div> : null}
            <div style={{padding: "0px 10px 20px 10px", borderRadius: "15px", backgroundColor: "#ffffff"}}>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={hospitals}
                    className={styles.adminTable}
                    pagination={false}
                    scroll={{x: "80%", y: "80%"}}
                    loading={tableLoading}
                />

                <Pagination paginationDetails={paginationDetails} onCurrentPage={onCurrentPage}/>
                {/*confirm modal*/}
                <ConfirmModal
                    isModalVisible={isModalVisible}
                    setIsModalVisible={setIsModalVisible}
                    action={handleShowDelete}
                    type="hospital"
                />

                {/*delete modal*/}
                <DeleteModal
                    isModalVisible={isDeleteModalVisible}
                    setIsModalVisible={setIsDeleteModalVisible}
                    ids={ids}
                    callback={() => setReload(!reload)}
                    type="hospital"
                />

                <UnauthorizedModal
                    isModalVisible={isUnauthorizedModalVisible}
                    setIsModalVisible={setIsUnauthorizedModalVisible}
                    action={() => {
                    }}
                />
            </div>
        </div>

    );
};

export default HospitalsTable;
