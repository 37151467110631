import React, { useContext } from "react";
import { FilterProvider } from "../../contexts/FilterContext";
import { Outlet } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized";
import {Helmet} from "react-helmet";


const Providers = () => {
    const { user } = useContext(UserContext);


    return user.permissions?.includes("SEARCH.PROVIDERS.READ.ALL") ||
        user.permissions?.includes("SEARCH.PROVIDERS.UPDATE.ALL") ||
        user.permissions?.includes("SEARCH.PROVIDERS.DELETE.ALL") ||
        user.permissions?.includes("SEARCH.PROVIDERS.CREATE.ALL") ? (
        <FilterProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Providers || Dokitari Admin Dashboard</title>
                <link rel="canonical" href="/providers" />
            </Helmet>
            <Outlet />
        </FilterProvider>
    ) : (
        <UnauthorizedPage />
    );
};

export default Providers;
