import React, {useState, useEffect, useContext} from 'react';
import { useParams } from "react-router-dom"
import { UserType } from "../../types/user"
import { fetchSingleUser} from "../../utils";
import { PageTop, UserProfile} from "../../organisms";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized"

const User = () => {
    const { id } = useParams()
    const [ data, setData ] = useState<UserType>({} as UserType)
    const { user } = useContext(UserContext)

    useEffect(()=> {
        id && fetchSingleUser(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])

    return (
        (user.permissions)?.includes("ADMIN_USER.READ.ALL")=== false && (user.permissions)?.includes("ADMIN_USER.READ.SELF") && id !== user?.id ?
        
        <UnauthorizedPage/>
        :
        <div>
            <PageTop
                name={data?.firstName}
                id={data?.id}
                backUrl="/dashboard/admin-users"
                title="Back to user page"
                showDelete={false}
                editUrl={`/dashboard/admin-users/${data?.id}/edit`}
                pageType="user"
            />
            <UserProfile user={data}/>
        </div>
    );
};

export default User;

