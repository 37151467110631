import React from 'react';
import ModalContainer from "./ModalContainer"
import { Button } from "../../atoms"

interface Props {
		isModalVisible: boolean;
		setIsModalVisible: any;
      action: any;
      type:string;
}

const 	ConfirmModal = ({isModalVisible, setIsModalVisible, action, type}:Props) => {
    return (
        <ModalContainer isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
            <p style={{
               fontWeight: "700",
               fontSize: "28px",
            }}> Are you sure you want to delete this {type}?</p>
            <p style={{
               fontWeight: "400",
               fontSize: "16px",
               marginTop:"20px"
            }}
            >If you delete your this {type}, it will be automatically removed from the list.</p>

            <div style={{display:"flex", justifyContent:"space-around", marginTop:"20px"}}>
               <Button style={{padding:"15px", width:"40%"}} onClick={()=> setIsModalVisible(false)}>No</Button>
               <Button variant="secondary" style={{padding:"15px", width:"40%"}} onClick={action}>Yes</Button>
            </div>
          </ModalContainer>
    );
};

export default 	ConfirmModal;
