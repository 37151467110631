import React from 'react';
import styles from "./button.module.css"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement>{
	variant?: "primary" | "outline" | "secondary" | "ghost" | "ash";
	children?: React.ReactNode | string;
	style?:any;
	onClick?:() => void;
}

const Button:React.FC<Props> = ({variant="primary", children,style, onClick,...props }) => {

    return (
        <button className={styles[variant]} style={style} onClick={onClick} {...props}>
	      {children}
	    </button>
    );
}

export default Button;
