import React, { useState } from 'react';
import {Button, Table, Checkbox, Space} from 'antd';
import { MdOutlineCloudDownload } from "react-icons/md"
import styles from "./rtd.module.css"
import type { ColumnsType } from "antd/es/table"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import { useNavigate } from "react-router-dom"
import Pagination from "../Pagination"
import { Props as PaginationProps } from "../Pagination"
import { roleHeaders } from "../../utils"
import { CSVLink } from "react-csv"
import { Edit } from "../../svg";
// import UserContext from '../../contexts/UserContext';
// import UnauthorizedModal from '../Modal/UnauthorizedModal';

export interface Props{
    key: any;
    name:string | any;
    description:string | any,
    numberOfUsers:number | any
}

interface RolesTableProps{
    paginationDetails: PaginationProps['paginationDetails'],
    onCurrentPage: PaginationProps['onCurrentPage'],
    tableLoading: boolean,
    data: Props[],
}

const RolesTable = ({data, onCurrentPage, paginationDetails, tableLoading}:RolesTableProps) => {
    const navigate = useNavigate()

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked ] = useState(true)

    const columns: ColumnsType<Props> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width:"25%",
            render: (_, {name,key})=> (
                <div onClick={()=> navigate(`/dashboard/roles/${key}`)}>
                <div
                    style={{
                        cursor:"pointer",
                        textTransform:"capitalize",
                        display:"flex",
                        alignItems:"flex-start"
                    }}
                >
                    <p>{name}</p>
                </div>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width:"45%",
        },
        {
            title: 'Number of users',
            dataIndex: 'numberOfUsers',
            key: 'numberOfUsers',
            width:"20%",
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            width:"15%",
            render: (_, {key}) => (
                <Space size="middle">
                    <Edit
                        width="20px"
                        height="20px"
                        style={{cursor:"pointer"}}
                        onClick={()=> navigate(`/dashboard/roles/${key}/modify-membership`)}
                    />
                </Space>
            ),
        }
    ];


    const start = () => {
        setLoading(true); // ajax request after empty completing

        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };

    const onSelectChange = (newSelectedRowKeys: any) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked)
        setSelectedRowKeys([])

    }

    const selectedData = () => {
        let arr = [] as Props[]
        for(let i=0; i<selectedRowKeys.length; i++){
            const r = data.filter(x => x.key === selectedRowKeys[i])
            arr= arr.concat(r)
        }
        return arr
    }

    return (
        <div>
            { hasSelected ?  <div
                style={{
                    marginBottom: 16,
                    display:"flex",
                    alignItems:"center"
                }}
            >
                <Button onClick={start} loading={loading} className={styles.topBtns} >
                    <Checkbox checked={checked} onChange={onChange}>
                        {selectedRowKeys.length}role selected

                    </Checkbox>
                </Button>
                <CSVLink data={selectedData()} filename={"Admin Roles"} headers={roleHeaders}>
                    <Button  loading={loading} className={styles.topBtns}>
                        <MdOutlineCloudDownload style={{color:"#1f1f1f", fontSize:"20px"}}/>
                    </Button>
                </CSVLink>
            </div> :null }
            <div style={{padding:"0px 10px 20px 10px", borderRadius:"15px", backgroundColor:"#ffffff"}}>
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    className={styles.adminTable}
                    pagination={false}
                    scroll={{x:"100%", y:"100%"}}
                    loading={tableLoading}
                />

                <Pagination paginationDetails={paginationDetails} onCurrentPage={onCurrentPage}/>
                {/*<UnauthorizedModal*/}
                {/*    isModalVisible={isUnauthorizedModalVisible}*/}
                {/*    setIsModalVisible={setIsUnauthorizedModalVisible}*/}
                {/*    action={()=> {}}*/}
                {/*/>*/}
            </div>
        </div>

    );
};

export default RolesTable;
