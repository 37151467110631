import React, {useState, createContext, useEffect} from "react";
import {fetchSingleHospital} from "../utils";
import {useParams} from "react-router-dom";

export const TitleContext = createContext({} as any);

export function TitleProvider({ children }) {
    const [title, setTitle] = useState("");
    const { id } = useParams()

    useEffect(() => {

       id && fetchSingleHospital(id).then((res: any) => {
            setTitle(res.name);
            console.log(res.name)

        })
            .catch((err: any) => console.log(err))
    }, [setTitle,id])
    const value: any = {
        title,
        setTitle
    }
    console.log(title)

    return (
        <TitleContext.Provider value={value}>
            {children}
        </TitleContext.Provider>
    );
}