import {useState} from "react";
import {Dropdown, Menu} from "antd";
import { RiEqualizerLine } from 'react-icons/ri';
import ConsultationFilter from "./ConsultationFilter";
import StatusFilter from "./StatusFilter";
import { Button } from "../../atoms"
import styles from "./Filter.module.css"
import {usePromocodesFilter} from "../../contexts/FilterPromocodesContext";

export default function DoctorsFilterDropdown({applyFilters, resetFilters}:{applyFilters:any, resetFilters:any}) {
    const [visible, setVisible] = useState<boolean>(false);
    
    const {
        selectedConsultation,
        setSelectedConsultation,
        selectedStatus,
        setSelectedStatus
    } = usePromocodesFilter();

    const consultationOptions=["STANDARD", "EXPRESS", "SUPER_EXPRESS"];

    const filter = (
        <Menu className={styles.filter_menu}>
            <Button 
                variant="ghost" 
                onClick={resetFilters}
                style={{
                  fontSize:"16px",
                  fontWeight:"500",
                  margin:"0px 20px 0px auto",
                  height:"auto"
                }}
            >Reset</Button>

            <StatusFilter text="Select promocode status" selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus}/>
            <ConsultationFilter types={consultationOptions} selectedConsultation={selectedConsultation} setSelectedConsultation={setSelectedConsultation}/>
            <Button 
                style={{
                    borderRadius:"5px",
                    width:"80%",
                    margin:"16px auto 0px auto",
                    padding:"10px 0px",
                    fontSize:"16px",
                    fontWeight:"500"
                }} 
                onClick={()=> {
                    applyFilters()
                    setVisible(false)
                }}
            >Apply filter</Button>
        </Menu>
    )


    return (
        <div className={"select_filter"}>
            <Dropdown overlay={filter} placement={"bottomRight"} arrow trigger={['click']}
                      onVisibleChange={(r) => setVisible(r)} visible={visible}>
                <p className={styles.filter}><RiEqualizerLine style={{fontSize:"32px"}}/> &nbsp; Filter</p>
            </Dropdown>
        </div>

    )
}