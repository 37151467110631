import React, { useContext } from "react";
import { FilterUsersProvider } from "../../contexts/FilterUsersContext";
import { Outlet } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized";
import {Helmet} from "react-helmet";


const Users = () => {
    const { user } = useContext(UserContext);


    return user.permissions?.includes("ADMIN_USER.READ.ALL") ||
        user.permissions?.includes("ADMIN_USER.CREATE.ALL") ||
        user.permissions?.includes("ADMIN_USER.UPDATE.ALL") ||
        user.permissions?.includes("ADMIN_USER.DELETE.ALL") ||
        user.permissions?.includes("ADMIN_USER.READ.SELF") ||
        user.permissions?.includes("ADMIN_USER.CREATE.SELF") ||
        user.permissions?.includes("ADMIN_USER.UPDATE.SELF") ||
        user.permissions?.includes("ADMIN_USER.DELETE.SELF") ? (
        <FilterUsersProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Admin Users || Dokitari Admin Dashboard</title>
                <link rel="canonical" href="/admin-users/" />
            </Helmet>
            <Outlet />
        </FilterUsersProvider>
    ) : (
        <UnauthorizedPage />
    );
};

export default Users;
