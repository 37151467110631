import React from "react";

const Filter = ({
	height = "32px",
	width = "32px",
	color = "#1f1f1f",
	fillOpacity="0.3",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M11.9999 18C12.8868 17.9998 13.7487 18.2944 14.45 18.8374C15.1513 19.3805 15.6522 20.1412 15.8739 21H26.9999C27.2498 20.9995 27.4908 21.0927 27.6755 21.261C27.8601 21.4294 27.9751 21.6608 27.9976 21.9096C28.0202 22.1585 27.9488 22.4068 27.7974 22.6057C27.6461 22.8045 27.4258 22.9395 27.1799 22.984L26.9999 23L15.8739 23.002C15.6529 23.8615 15.1523 24.6231 14.451 25.167C13.7497 25.7108 12.8874 26.0059 11.9999 26.0059C11.1124 26.0059 10.2501 25.7108 9.54879 25.167C8.84745 24.6231 8.34689 23.8615 8.12589 23.002L4.99989 23C4.75 23.0005 4.50899 22.9073 4.32432 22.739C4.13966 22.5706 4.02472 22.3392 4.00214 22.0904C3.97956 21.8415 4.05098 21.5932 4.20233 21.3943C4.35368 21.1955 4.574 21.0605 4.81989 21.016L4.99989 21H8.12589C8.34763 20.1412 8.84852 19.3805 9.54979 18.8374C10.2511 18.2944 11.1129 17.9998 11.9999 18ZM19.9999 6C20.8868 5.99977 21.7487 6.29435 22.45 6.83741C23.1513 7.38047 23.6522 8.14121 23.8739 9H26.9999C27.2498 8.99954 27.4908 9.09266 27.6755 9.26102C27.8601 9.42938 27.9751 9.66077 27.9976 9.90964C28.0202 10.1585 27.9488 10.4068 27.7974 10.6057C27.6461 10.8045 27.4258 10.9395 27.1799 10.984L26.9999 11L23.8739 11.002C23.6529 11.8615 23.1523 12.6231 22.451 13.167C21.7497 13.7108 20.8874 14.0059 19.9999 14.0059C19.1124 14.0059 18.2501 13.7108 17.5488 13.167C16.8475 12.6231 16.3469 11.8615 16.1259 11.002L4.99989 11C4.75 11.0005 4.50899 10.9073 4.32432 10.739C4.13966 10.5706 4.02472 10.3392 4.00214 10.0904C3.97956 9.84149 4.05098 9.59318 4.20233 9.39434C4.35368 9.19549 4.574 9.06052 4.81989 9.016L4.99989 9H16.1259C16.3476 8.14121 16.8485 7.38047 17.5498 6.83741C18.2511 6.29435 19.1129 5.99977 19.9999 6Z"
				fill={color}
				fillOpacity={fillOpacity}
			/>
		</svg>
	);
};

export default Filter;
