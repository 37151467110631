import React from 'react';
import {AddDoctorForm, AddPageTop} from "../../organisms"
import { useNavigate } from "react-router"


const AddDoctor = () => {
    const navigate = useNavigate()

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <AddPageTop
                onBack={()=> navigate(-1)}
                title="Back to doctors list"
                heading="Add a new Doctor"
            />
            <AddDoctorForm />
        </div>
    );
};

export default AddDoctor;
