import React from 'react';
import {Pagination as Paginate} from 'antd';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import "./pagination.css"

export type Props = {
    paginationDetails: {
        isFirstPage: boolean,
        isLastPage: boolean,
        currentPageNumber: number,
        totalNumberOfPages: number,
        pageSize?: number,
        totalNumberOfResults: number
    },
    onCurrentPage: (pageNumber: number) => void
}

const Pagination = ({paginationDetails, onCurrentPage}: Props) => {

    const  ItemRender = (current:number, type:any, originalElement: any ) => {
        if (type === 'prev') {
            return <p className="prev"><span><BsChevronLeft style={{fontSize:"25px", marginRight:"10px"}}/></span> <span> Previous </span></p>;
        }

        if (type === 'next') {
            return <p className="next"><span>Next</span> <span><BsChevronRight style={{fontSize:"25px", marginLeft:"10px"}}/></span></p>;
        }

            return originalElement;

    }




    return (
        
        <Paginate
            defaultCurrent={1}
            onChange={onCurrentPage}
            current={paginationDetails?.currentPageNumber + 1}
            total={paginationDetails?.totalNumberOfResults}
            showSizeChanger={false}
            pageSize={paginationDetails?.pageSize ?? 20}
            showLessItems={true}
            itemRender={ItemRender}
            responsive
            className="paginate"
        />
    );
};

export default Pagination;
