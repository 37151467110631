import React from 'react';
import styles from './SearchBar.module.css';
import SearchIcon from "../../assets/icon_search.svg";
import {Action} from "../../action_services";

type Props = {
    searchQuery: string;
    filteredActions: Action[];
    handleActionClick: (link: string) => void;
};

const SearchBarDropdown: React.FC<Props> = ({ searchQuery, filteredActions, handleActionClick }) => {
    return (
        <div className={styles.searchBarDropdown}>
            {filteredActions.map((action) => (
                <div onClick={() => handleActionClick(action.link)}>
                    <img id="search-icon" width={18} height={18} src={SearchIcon} alt="Search Icon" />
                    <div key={action.id}>
                        {action.name.split(new RegExp(`(${searchQuery})`, 'gi')).map((part, index) =>
                            part.toLowerCase() === searchQuery.toLowerCase() ? (
                                <mark className={styles.highlighted} key={index}>
                                    {part}
                                </mark>
                            ) : (
                                <span key={index}>{part}</span>
                            )
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SearchBarDropdown;
