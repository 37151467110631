import React from 'react';
import styles from "./apt.module.css"
import { PageHeader } from "antd"

type Props = {
    onBack:any;
    title: string;
    heading:string;
}

const AddPageTop = ({ onBack, title, heading}: Props) => {
	
    return (
        <div className={styles.container}>
        	<PageHeader
        		className={styles.back}
        		onBack={onBack}
        		title={title}
        	/>
        	<div className={styles.sub_bar}>
        		<p className={styles.p_name}>{heading}</p>
        	</div>
            <hr style={{border: "1px solid rgba(31, 31, 31, 0.4)"}}/>
        </div>
    );
};

export default AddPageTop;
