import React, {useEffect, useState} from "react";
import styles from "./edf.module.css";
import {DatePickerInput, EditFormInput, ImageUpload, SelectInput} from "../../molecules";
import {Button} from "../../atoms";
import {AiTwotoneMail} from "react-icons/ai";
import {BsPersonFill, BsPhoneFill} from "react-icons/bs";
import {IoMdMap} from "react-icons/io";
import {FaCalendarAlt, FaHospitalAlt} from "react-icons/fa";
import {RiBriefcase4Fill} from "react-icons/ri";
import {GiReceiveMoney} from "react-icons/gi"
import {MdLocationOn} from "react-icons/md"
import {HiAcademicCap} from "react-icons/hi"
import {Form, Formik} from "formik";
import moment, {Moment} from "moment"
import * as yup from "yup";
import {editSingleDoctor, openNotification, uploadImage} from "../../utils";
import type {UploadProps} from "antd";
import {Spin} from "antd";
import {DoctorType} from "../../types/doctor"
import {useNavigate} from "react-router-dom"
import {EditFormInputForNin} from "../../molecules/EditFormInput";

const EditDoctorForm = ({doctor}: { doctor: DoctorType }) => {
    const navigate = useNavigate()
    const [url, setUrl] = useState<any>({} as any);
    const [birthDate, setBirthDate] = useState<Moment | null | string | undefined>(null);
    const [inductionDate, setInductionDate] = useState<Moment | null | string | undefined>(null);
    const [gender, setGender] = useState("");
    const [uploading, setUploading] = useState(false);
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [specialty, setSpecialty] = useState("")

    const dummyRequest = async (options) => {
        const {onSuccess} = options;
        setTimeout(() => {
            onSuccess("OK");
        }, 0);
    };
    const props: UploadProps = {
        accept: "image/*",
        name: "file",
        multiple: true,
        customRequest: dummyRequest,
        beforeUpload(file) {
            setUploading(true);
            uploadImage(file).then((res: any) => {
                setUrl({
                    id: res?.asset_id,
                    uid: res?.public_id,
                    name: res.original_filename,
                    url: res?.secure_url,
                    status: "success",
                    type: res?.type,
                    size: res?.bytes
                });
                setUploading(false);
            });
            return false;
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };

    const validationSchema = yup.object().shape({
        first_name: yup.string(),
        last_name: yup.string(),
        telephone: yup.string(),
        email: yup
            .string()
            .email("Email is invalid"),
        nin: yup.string(),
        department: yup.string(),
        consultation_cost: yup
            .number()
            .typeError("Cost must be a number"),
        place_of_work: yup.string(),
        education: yup.string(),
        higher_education: yup.string(),
        specializations: yup.string(),
    });

    const resetExtras = () => {
        setUrl(doctor?.pictureUrl);
        setBirthDate(doctor?.birthDate === "" || doctor?.birthDate === null ? null : moment(doctor?.birthDate, "DD.MM.YYYY"))
        setInductionDate(doctor?.inductionDate === "" || doctor?.inductionDate === null ? null : moment(doctor?.inductionDate, "DD.MM.YYYY"))
        setGender(doctor?.gender)
        setCity(doctor?.city)
        setCountry(doctor?.country)
        setSpecialty(doctor?.speciality)
    };

    useEffect(() => {
        setUrl(doctor?.pictureUrl);
        setBirthDate(doctor?.birthDate === "" || doctor?.birthDate === null ? null : moment(doctor?.birthDate, "DD.MM.YYYY"))
        setInductionDate(doctor?.inductionDate === "" || doctor?.inductionDate === null ? null : moment(doctor?.inductionDate, "DD.MM.YYYY"))
        setGender(doctor?.gender)
        setCity(doctor?.city)
        setCountry(doctor?.country)
        setSpecialty(doctor?.speciality)
    }, [doctor])

    const genderOptions = ["Male", "Female", "Others"];
    const specialtyOptions = ["Clinical Medical Officer", "Counsellor", "Dentist", "Dermatologist", "Diabetologist", "Nutrition and fitness", "General practitioner", "General surgeon", "Obstetrician & Gynaecologist", "Nurse", "Herbal Health specialist", "Oncologist", "Ophthalmologist", "Orthopedic surgeon", "Paediatrician", "Pharmacist", "Plastic surgeon", "Physician", "Radiologist"]
    const cityOptions = ["Entebbe", "Gulu", "Kampala", "Mbarara", "Wakiso"]
    const countryOptions = ["Uganda"]

    return (
        <Formik
            initialValues={{
                first_name: doctor?.firstName || "",
                last_name: doctor?.lastName || "",
                telephone: doctor?.phone || "",
                email: doctor?.email || "",
                nin: doctor?.nin || "",
                department: doctor?.department || "",
                consultation_cost: doctor?.standardSessionCost || "",
                place_of_work: doctor?.placeOfWork || "",
                education: doctor.educationalBackground || "",
                higher_education: doctor?.higherEducations || "",
                specializations: doctor?.specialisations || "",
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting}) => {
                console.log(values)
                const body: DoctorType = {
                    email: values.email,
                    userId: doctor?.userId,
                    firstName: values.first_name,
                    lastName: values.last_name,
                    country: country,
                    city: city,
                    speciality: specialty,
                    specialisations: values.specializations,
                    placeOfWork: values.place_of_work,
                    educationalBackground: values.education,
                    higherEducations: values.higher_education,
                    costCurrency: doctor?.costCurrency,
                    inductionDate: moment(inductionDate).format("DD.MM.YYYY"),
                    birthDate: moment(birthDate).format("DD.MM.YYYY"),
                    gender: gender,
                    nin: (values.nin),
                    phone: values.telephone,
                    department: values.department,
                    standardSessionCost: (values.consultation_cost).toString(),
                    pictureUrl: url,
                    availabilitySetup: doctor?.availabilitySetup,
                    errorMessage: doctor?.errorMessage
                };

                editSingleDoctor(doctor.id as string, body)
                    .then((res: any) => {
                        setSubmitting(false);
                        if (res?.errorMessage && res?.errorMessage !== "") {
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
								res?.errorMessage ?? 'An error occurred, please try again later.'
                            );
                        } else {
                            openNotification(
                                "success",
                                "bottomRight",
                                "Doctor updated successfully"
                            )
                            navigate("/dashboard/doctors");
                        }
                    })
                    .catch((err: any) => {
                        setSubmitting(false);
                        console.log(err)
                        openNotification(
                            "error",
                            "bottomRight",
                            "An error occured. Please try again later"
                        );
                    });

            }}
        >
            {({errors, isSubmitting, resetForm}) => (
                <Form className={styles.form}>
                    <div className={styles.field_section_full}>
                        <p className={styles.label}>Profile Picture</p>
                        <div className={styles.field_section_gallery}>
                            <ImageUpload
                                props={props}
                                disabled={uploading}
                                loading={uploading}
                                backgroundImage={url?.url}
                            />
                        </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>First name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="first_name"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Last name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="last_name"
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Email</p>
                            <EditFormInput
                                icon={<AiTwotoneMail/>}
                                type="email"
                                name="email"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Contact Phone</p>
                            <EditFormInput
                                icon={<BsPhoneFill/>}
                                type="tel"
                                name="telephone"
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>National Identity Number</p>
                            <EditFormInputForNin
                                icon={<BsPersonFill/>}
                                type="text"
                                name="nin"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Department</p>
                            <EditFormInput
                                icon={<RiBriefcase4Fill/>}
                                type="text"
                                name="department"
                            />
                        </div>
                    </div>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Standard online consultation cost (UGX)</p>
                            <EditFormInput
                                icon={<GiReceiveMoney/>}
                                type="text"
                                name="consultation_cost"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Birth Date</p>
                            <DatePickerInput
                                name="birth_date"
                                icon={<FaCalendarAlt/>}
                                onChange={(val: any) => {
                                    setBirthDate(moment(val._d).format("DD.MM.YYYY"))
                                }}
                                value={birthDate === null ? null : moment(birthDate,"DD.MM.YYYY")}
                                bordered={false}
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Induction Date</p>
                            <DatePickerInput
                                name="induction_date"
                                icon={<FaCalendarAlt/>}
                                onChange={(val: any) => {
                                    setInductionDate(moment(val._d).format("DD.MM.YYYY"))
                                }}
                                value={inductionDate === null ? null : moment(inductionDate,"DD.MM.YYYY")}
                                bordered={false}
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Gender</p>
                            <SelectInput
                                icon={<BsPersonFill/>}
                                options={genderOptions.map((genderOption: any) => {
                                    return {
                                        value: genderOption.toLowerCase(),
                                        label: genderOption
                                    }
                                })}
                                bordered={false}
                                onChange={(value) => {
                                    setGender(value)
                                }}
                                value={gender}
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Specialty</p>
                            <SelectInput
                                icon={<RiBriefcase4Fill/>}
                                options={specialtyOptions.map((specialtyOption: any) => {
                                    return {
                                        value: specialtyOption.toLowerCase(),
                                        label: specialtyOption
                                    }
                                })}
                                bordered={false}
                                onChange={(value) => {
                                    setSpecialty(value)
                                }}
                                value={specialty}
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Country</p>
                            <SelectInput
                                icon={<IoMdMap/>}
                                options={countryOptions.map((countryOption: any) => {
                                    return {
                                        value: countryOption.toLowerCase(),
                                        label: countryOption
                                    }
                                })}
                                bordered={false}
                                onChange={(value) => {
                                    setCountry(value)
                                }}
                                value={country}
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>City</p>
                            <SelectInput
                                icon={<MdLocationOn/>}
                                options={cityOptions.map((cityOption: any) => {
                                    return {
                                        value: cityOption.toLowerCase(),
                                        label: cityOption
                                    }
                                })}
                                bordered={false}
                                onChange={(value) => {
                                    setCity(value)
                                }}
                                value={city}
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Place of work</p>
                            <EditFormInput
                                icon={<FaHospitalAlt/>}
                                type="text"
                                name="place_of_work"
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Educational background (Optional)</p>
                            <EditFormInput
                                icon={<HiAcademicCap/>}
                                type="text"
                                name="education"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Higher education (Optional)</p>
                            <EditFormInput
                                icon={<HiAcademicCap/>}
                                type="text"
                                name="higher_education"
                            />
                        </div>
                    </div>


                    <div className={styles.field_section}>

                        <div className={styles.form_field}>
                            <p className={styles.label}>Specializations</p>
                            <EditFormInput
                                icon={<RiBriefcase4Fill/>}
                                type="text"
                                name="specializations"
                            />
                        </div>
                        &nbsp; &nbsp;
                        <div className={styles.button_group}>
                            <Button
                                variant="ghost"
                                style={{
                                    border: "1.5px solid #007AFF",
                                }}
                                type="submit"
                                disabled={
                                    Object.keys(errors).length > 0 ||
                                    isSubmitting
                                        ? true
                                        : false
                                }
                            >
                                {isSubmitting ? <Spin/> : "Save"}
                            </Button>
                            <Button
                                variant="ash"
                                type="reset"
                                onClick={() => {
                                    resetForm();
                                    resetExtras();
                                }}
                            >
                                Cancel
                            </Button>
                        </div>

                    </div>

                </Form>
            )}
        </Formik>
    );
};

export default EditDoctorForm;
