import React from "react";

const Hamburger = ({
	height = "43px",
	width = "43px",
	color = "black",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 45 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6.75 33.75H38.25V29.25H6.75V33.75ZM6.75 11.25V15.75H38.25V11.25H6.75ZM6.75 24.75H38.25V20.25H6.75V24.75Z"
				fill={color}
			/>
		</svg>
	);
};

export default Hamburger;
