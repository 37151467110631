import React from 'react';
import ModalContainer from "./ModalContainer"
import { Button } from "../../atoms"
import { BiErrorCircle } from "react-icons/bi"

interface Props {
		isModalVisible: boolean;
		action?: any;
      setIsModalVisible:any;
}

const UnauthorizedModal = ({isModalVisible, setIsModalVisible, action}:Props) => {
    return (
        <ModalContainer isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
            <BiErrorCircle style={{color:"#FD4C06",fontSize:"100px", margin:"20px auto"}}/>
            <p style={{
               fontWeight: "700",
               fontSize: "24px",
            }}>Sorry! This action is unauthorized</p>
            <p style={{
               fontWeight: "400",
               fontSize: "14px",
               marginTop:"20px",
               color:"#A7A7A7"
            }}
            >Update your admin permissions in order to perform this action</p>

            <div style={{display:"flex", justifyContent:"space-around", marginTop:"20px"}}>
               <Button 
                  style={{padding:"15px", width:"100%"}} 
                  onClick={()=> {
                     action();
                     setIsModalVisible(false)
                  }}
               >Close</Button>
            </div>
          </ModalContainer>
    );
};

export default UnauthorizedModal;
