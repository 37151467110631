import React from 'react';
import styles from "./DoctorProfile.module.css"
import { Avatar } from "antd"
import { DoctorType } from "../../types/doctor"

const DoctorProfile = ({doctor}: {doctor:DoctorType}) => {
	const profile = [
		{
			'title':'Name',
			'desc':doctor?.firstName + " " + doctor?.lastName || "NA"
		},
		{
			'title':'Date of Birth',
			'desc':doctor?.birthDate || "NA"
		},
		{
			'title':'Gender',
			'desc':doctor.gender || "NA"
		},
		{
			'title':'Phone',
			'desc':doctor?.phone || "NA"
		},
		{
			'title':'Email',
			'desc':doctor?.email || "NA"
		},
		{
			'title':'Address',
			'desc':doctor?.city + ", " + doctor?.country || "NA"
		},
		{
			'title':'Specialty',
			'desc':doctor?.speciality || "NA"
		},
		{
			'title':'Department',
			'desc': doctor?.department || "NA"
		},
		{
			'title':'Place of work',
			'desc':doctor?.placeOfWork || "NA"
		},
		{
			'title':'# Consultations',
			'desc':'NA'
		},
		{
			'title':'Date of Induction',
			'desc':doctor?.inductionDate || "NA"
		},
		{
			'title':'National ID Number',
			'desc':doctor?.nin || "NA"
		},
		{
			'title':'Educational Background',
			'desc':doctor?.educationalBackground || "NA"
		},
		{
			'title':'Licenses/Certificates',
			'desc':'NA'
		}
	]
    return (
        <div className={styles.container}>
            <div className={styles.img}>
        		<Avatar src={doctor?.pictureUrl?.url} size={166}/>
        	</div>
        	<div className={styles.info}>
        		{profile.map((info:any) => (
        			<div className={styles.info_item}>
	        			<p className={styles.title}>{info.title}</p>
	        			<p className={styles.desc}>{info.desc}</p>
	        		</div>
        		))}       		
        	</div>

        </div>
    );
};

export default DoctorProfile;
