import React, {useState, useEffect, useContext} from 'react';
import styles from "./AllReviews.module.css"
import {Space, Table, Rate, Avatar} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {FaEllipsisH} from "react-icons/fa"
import {ApprovalTag, ReviewCard} from "../../molecules"
import Pagination from "../Pagination"
import {loadReviewsData, fetchAllReviews} from "../../utils"
import { UnauthorizedModal } from "../../organisms"
import UserContext from "../../contexts/UserContext"

export interface DataType {
    key: number | any;
    photo: string | any;
    postedBy: string | any;
    email: string | any;
    rating: number | any;
    comment: string | any;
    provider: string | any;
    submission: string | any;
    status: any;
    userId: string | any;
    date: string | any;
}

type Props = React.ComponentPropsWithoutRef<"div"> & {
    currentKey: string
}


const AllReviewsTable = ({currentKey, ...props}: Props) => {

    const columns: ColumnsType<DataType> = [
        {
            title: 'PHOTO',
            dataIndex: 'photo',
            key: 'photo',
            width: '8%',
            render: (_, {photo}) => (
                <Avatar
                    src={photo}
                    alt="provider"
                    size={50}
                    className={styles.providerImage}
                />
            ),
        },
        {
            title: 'POSTED BY',
            dataIndex: 'postedBy',
            key: 'postedBy',
            width: '14%',
            render: (_, {postedBy}) => (
                <p style={{textTransform: "capitalize"}}>{postedBy}</p>
            )
        },
        {
            title: 'EMAIL',
            dataIndex: 'email',
            key: 'email',
            width: '14%'
        },
        {
            title: 'RATING',
            key: 'rating',
            dataIndex: 'rating',
            width: '12%',
            render: (_, {rating}) => (
                <Rate disabled defaultValue={rating} style={{fontSize: "14px"}}/>
            ),
        },
        {
            title: 'COMMENT',
            key: 'comment',
            dataIndex: 'comment',
            width: '13%',
            ellipsis: true,
        },
        {
            title: 'PROVIDER',
            key: 'provider',
            dataIndex: 'provider',
            width: '13%',
        },
        {
            title: 'SUBMITTED ON',
            key: 'submission',
            dataIndex: 'submission',
            width: '12%'
        },
        {
            title: 'STATUS',
            key: 'status',
            dataIndex: 'status',
            width: '11%',
            render: (_, {status}) => (
                <Space size="middle">
                    <ApprovalTag status={status.toLowerCase()}/>
                </Space>
            ),
        },
        {
            title: '',
            key: 'expand',
            dataIndex: '',
            width: '3%'
        }
    ];
    const { user } = useContext(UserContext)
    const [data, setData] = useState<DataType[]>([])
    const [ isModalVisible, setIsModalVisible] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [reviews, setReviews] = useState([] as any[])
    const [current, setCurrent] = useState<number>(1)
    const [paginationDetails, setPaginationDetails] = useState({
        isFirstPage: true,
        isLastPage: false,
        currentPageNumber: 0,
        totalNumberOfPages: 0,
        totalNumberOfResults: 0
    })

    //fetch reviews
    useEffect(() => {
        setLoading(true)
        fetchAllReviews((current - 1), currentKey).then((res: any) => {
            // console.log(res)
            setReviews(res.content)
            setPaginationDetails({
                isFirstPage: res.first,
                isLastPage: res.last,
                currentPageNumber: res.number,
                totalNumberOfPages: res.totalPages,
                totalNumberOfResults: res.totalElements
            })
            setLoading(false)
        })

        return () => {}
    }, [current, currentKey])

    useEffect(() => {
        loadReviewsData(reviews).then((res: DataType[]) => {
            setData(res)
        })

    }, [reviews])

    const onCurrentPage = (page: number) => {
        setCurrent(page)
        setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1
        })
    }

    return (
        <div {...props} style={{padding: "0px 10px 20px 10px", borderRadius: "15px", backgroundColor: "#ffffff"}}>
            <Table
                columns={columns}
                dataSource={data}
                className={styles.adminTable}
                scroll={{x: "130%", y: "100%"}}
                pagination={false}
                loading={loading}
                expandIconColumnIndex={8}
                expandable={{
                    expandedRowRender: record =>
                        <ReviewCard
                            photo={record.photo}
                            name={record.postedBy}
                            email={record.email}
                            userId={record.userId}
                            date={record.date}
                            provider={record.provider}
                            comment={record.comment}
                            rating={record.rating}
                            status={record.status}
                            id={record.key}
                            user={user}
                        />,
                    expandIcon: () => <FaEllipsisH style={{margin: "auto"}}/>,
                    onExpand:() => (user.permissions)?.includes("SEARCH.REVIEW.READ.ALL") ? null : setIsModalVisible(true),
                    expandRowByClick: (user.permissions)?.includes("SEARCH.REVIEW.READ.ALL") ? true : false,
                    fixed: false,
                    columnWidth: '5%',
                    expandedRowClassName: () => styles.extra_info
                }}
            />
             <UnauthorizedModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={()=> null}
            />

            <Pagination paginationDetails={paginationDetails} onCurrentPage={onCurrentPage}/>
        </div>
    )
}

export default AllReviewsTable;