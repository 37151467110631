import React, {InputHTMLAttributes, ClassAttributes, TextareaHTMLAttributes, RefAttributes} from 'react';
import styles from "./EditFormInput.module.css"
import {Alert, TimePicker, DatePicker, Select, Popover} from "antd"
import type { TimeRangePickerProps, DatePickerProps, SelectProps } from "antd"
import { useField, FieldHookConfig } from "formik";
import {BsInfoCircle} from "react-icons/bs";

interface Props{
	icon:React.ReactNode,
}

const EditFormInput  = ({icon,...props}: Props &
    InputHTMLAttributes<HTMLInputElement> & 
    FieldHookConfig<string> &
    ClassAttributes<HTMLInputElement>) => {
    const [field, meta] = useField(props);
    return (
        <>
            <div className={styles.container}>
            	<span className={styles.icon_button}>{icon}</span>
            	<input className={styles.input} {...field} />
            </div>
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}
        </>
    );
};


export default EditFormInput;

export const EditFormInputForNin  = ({icon,...props}: Props &
    InputHTMLAttributes<HTMLInputElement> &
    FieldHookConfig<string> &
    ClassAttributes<HTMLInputElement>) => {
    const [field, meta, helpers] = useField(props);
    return (
        <>
            <div className={styles.container}>
                <span className={styles.icon_button}>{icon}</span>
                <input
                    className={styles.input}
                    {...field}
                    {...props}
                    onChange={e => helpers.setValue(e.target.value.toUpperCase())}
                />
            </div>
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}
        </>
    );
};



export const EditFormTextarea  = ({...props}: TextareaHTMLAttributes<HTMLTextAreaElement> &
    FieldHookConfig<string> &
    ClassAttributes<HTMLTextAreaElement>) => {
    const [field, meta] = useField(props);
    return (
        <>
            <div className={styles.container}>
            	<textarea className={styles.textarea} {...field} {...props}/>   
            </div>
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}
        </>
    );
};
interface LabelProps{
    label:string;
    className:string;
}
export const EditFormCheckbox  = ({label,className, ...props}:  LabelProps & InputHTMLAttributes<HTMLInputElement> & 
    FieldHookConfig<string> &
    ClassAttributes<HTMLInputElement>) => {
    const [field, meta] = useField(props);
    return (
        <>
            <div className={styles.check_container}>
                <input type="checkbox" className={styles.checkbox} {...field} {...props}/> 
                <label htmlFor={field.name} className={className}>{label}</label>  
            </div>
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}
        </>
    );
};


export const TimePickerInput  = ({icon, ...props}: Props & TimeRangePickerProps &
    RefAttributes<any>) => {
    return (
        <>
            <div className={styles.container}>
                <span className={styles.icon_button}>{icon}</span>
                <TimePicker.RangePicker
                    {...props} 
                />   
            </div>

        </>
    );
};

export const DatePickerInput = ({icon, ...props}: Props & DatePickerProps & RefAttributes<any>) => {
    return (
        <>
            <div className={styles.container}>
                <span className={styles.icon_button}>{icon}</span>
                <DatePicker 
                    className={styles.picker}
                    {...props}
               /> 
            </div>
        </>
    )
}

export const SelectInput = ({icon, ...props}: 
    Props &  RefAttributes<any> & SelectProps) => {
    return (
        <>
            <div className={styles.container}>
                <span className={styles.icon_button}>{icon}</span>
                <Select {...props} className={styles.select}/> 
            </div>
        </>
    )
}
interface infoIconProps {
    contentTitle:string,
    content:string
}

export const PopoverWithInfo  = ({contentTitle,content}: infoIconProps) => {
    return (
        <>
                <Popover content={content} title={contentTitle}>
                    <span className ={styles.icon_info}>{<BsInfoCircle/>}</span>
                </Popover>
        </>
    );
};
