import React, { createContext, useState, useContext } from 'react';

const FilterContext = createContext({} as any);

export const FilterUsersProvider = ({children}) => {
    const[selectedRole, setSelectedRole]= useState<string>("");

    const value:any = {
        selectedRole,
        setSelectedRole
    }

    return (
       <FilterContext.Provider value={value}>
       	{children} 
       </FilterContext.Provider>
    );
};

export const useUsersFilter = () => useContext(FilterContext);

