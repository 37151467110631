import React,{ useEffect, useState } from 'react';
import { PromocodesListTop, PromocodesTable, UnauthorizedModal } from "../../organisms"
import { Props as DataProps } from "../../organisms/PromocodesTable"
import { fetchAllPromocodes, loadPromocodesData } from "../../utils"
import { PromocodesType } from "../../types/promocodes"
import {useSearchParams} from "react-router-dom"

const PromocodesPage = () => {
    const [param, setParam] = useSearchParams();
   const modal = param.get("modal")
   const [ isModalVisible, setIsModalVisible] = useState(modal === null ? false : true)
    const [data, setData] = useState<DataProps[]>([])
    const [ promocodes, setPromocodes ]= useState<PromocodesType[]>([])
    const [ reload, setReload ] = useState<boolean>(false)
    const [ current, setCurrent ] = useState<number>(1)
   const [ loading, setLoading ] = useState<boolean>(false)
   const [ paginationDetails, setPaginationDetails ] = useState({
     isFirstPage: true,
     isLastPage: false,
     currentPageNumber: 0,
     totalNumberOfPages: 0,
     totalNumberOfResults: 0
   })

    useEffect(()=> {
        setLoading(true)
       fetchAllPromocodes((current - 1)).then((res:any) => {
         setPromocodes(res.content)
         setPaginationDetails({
           isFirstPage: res.first,
           isLastPage: res.last,
           currentPageNumber: res.number,
           totalNumberOfPages: res.totalPages,
           totalNumberOfResults: res.totalElements
         })
         setLoading(false)
      })  
   },[current,reload])

    useEffect(()=> {
        setLoading(true)
        loadPromocodesData(promocodes).then((res:any) => {
            setData(res)
            setLoading(false)
        })
    },[promocodes])

    const onCurrentPage = (page: number) => {
      setCurrent(page)
      setPaginationDetails({
            ...paginationDetails, currentPageNumber: page - 1 
      })
   }

    return (
        <div>
        	<PromocodesListTop data={data}/>
            <PromocodesTable 
                data={data} 
                reload={reload} 
                setReload={setReload}
                paginationDetails={paginationDetails}
                tableLoading={loading}
                onCurrentPage={onCurrentPage}
            />
            <UnauthorizedModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={()=> setParam({})}
            />
        </div>
    );
};


export default PromocodesPage;
