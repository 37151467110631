import React from 'react';
import styles from './roleprofile.module.css'
import { RoleType} from "../../types/role"

const Role = ({role}: {role:RoleType }) => {

    const profile = [
        {
            'title':'Name',
            'desc':role?.name || 'NA'
        },
        {
            'title':'NumberOfUsers',
            'desc':role?.numberOfUsers || 'NA'
        },
        {
            'title':'Description',
            'desc':role?.description || 'NA'
        }
    ]
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                {profile.map((info:any) => (
                    <div className={styles.info_item}>
                        <p className={styles.title}>{info.title}</p>
                        <p className={styles.desc}>{info.desc}</p>
                    </div>
                ))}
            </div>
            <div>
                <div className={styles.title}>Role Users</div>
                {role && role?.users?.map((user:any) =>(
                    <div key={user.id}>
                        <p>{user.firstName}&nbsp;{user.lastName}</p>
                        </div>
                ))}
            </div>

        </div>
    );
};

export default Role ;

