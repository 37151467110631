import React, {useState} from "react";
import styles from "./ehd.module.css";
import {Spin} from "antd";
import {Modal} from 'antd';
import AddHospitalDoctors from "../AddHospitalDoctors"
import {editHospitalDoctors, openNotification} from "../../utils";
import {HospitalType} from "../../types/hospital";
import {EditStaffForm} from "../../molecules";


const EditHospitalDoctor = ({
                                hospitalDoctor,
                                loading,
                                reloadHospitalDetails
                            }: { hospitalDoctor: any, loading: boolean, reloadHospitalDetails: (callback?: (hospital: HospitalType) => void) => void }) => {
    const [submitting, setSubmitting] = useState(false);
    const [ids, setIds] = useState<Set<string>>(new Set(hospitalDoctor?.staff?.doctors?.map(doctor => doctor.id) ?? []));
    const [openModal, setOpenModal] = useState<boolean>(false);

    const showModal = () => {
        setOpenModal(true);
    };
    const handleCancel = () => {
        setOpenModal(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if(hospitalDoctor.id){
        editHospitalDoctors(hospitalDoctor.id, Array.from(ids))
            .then((res: any) => {
                setSubmitting(false);
                if (res.error) {
                    setSubmitting(false);
                } else {
                    openNotification(
                        "success",
                        "bottomRight",
                        "Hospital Doctor updated successfully"
                    );
                    setOpenModal(false)
                }
            })
            .catch((err: any) => {
                setSubmitting(false);
                openNotification(
                    "error",
                    "bottomRight",
                    err ?? "An error occured. Please try again later"
                );
            })
            .finally(() => {
                resetIdsBased();
            });}
    };

    const resetIdsBased = () => {
        reloadHospitalDetails((hospital) => {
            setIds(new Set(hospital?.staff?.doctors?.map(doctor => doctor.id) ?? []))
        });
    }

    return (loading ?
            <div className={styles.loader}>
                <Spin/>
            </div> :
            <>
                <EditStaffForm user={'Doctor'} staffs={hospitalDoctor?.staff?.doctors} ids={ids} setIds={setIds} showModal={showModal} submitting={submitting} submit={onSubmit}/>
                <Modal title={""} open={openModal} centered footer={""} destroyOnClose={true} bodyStyle={{
                    padding: "0em"
                }} style={{borderRadius: "15px"}} onCancel={handleCancel}>
                    <AddHospitalDoctors data={hospitalDoctor} cancel={handleCancel}
                                        reloadHospitalDetails={resetIdsBased}/>
                </Modal>
            </>
    )
};
export default EditHospitalDoctor;
