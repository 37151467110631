import React from 'react';
import {Dropdown, Menu } from "antd";
import { TickIcon, DropdownIcon } from "../../svg"
import styles from "./Filter.module.css"

const statusList : string[] = ["Active", "Inactive"]
type StatusFilterProps = {
	selectedStatus: string,
	setSelectedStatus: any,
    text:string,
}

const 	StatusFilter = ({selectedStatus, setSelectedStatus, text}: StatusFilterProps) => {

	  const statusOptionsMenu = (
        <Menu>
            {statusList?.map((status) => {
                    return (<Menu.Item key={status}>
                        <div className={styles.menu_items} onClick={() => setSelectedStatus(status)}>
                            <span>{status}</span>
                            <span className={styles.items_tick}><TickIcon/></span>
                        </div>
                    </Menu.Item>)
                }
            )
            }
        </Menu>
    );

    return (
        <div className={styles.filterMenu_items}>
            <div className={styles.filter_items}>

                <p>Status</p>
                <Dropdown overlay={statusOptionsMenu} trigger={['click']}>
                    <div onClick={e=> e.preventDefault()} className={styles.flex}>
                    <span>{selectedStatus || text}</span>
                   <DropdownIcon/>
                   </div>
               </Dropdown>
                
            </div>
        </div>
    );
};

	
export default 	StatusFilter;
