import React,{ useState } from 'react';
import ModalContainer from "./ModalContainer"
import { Button } from "../../atoms"
import { activateProvider, openNotification } from "../../utils"
import { LoadingOutlined } from "@ant-design/icons"

interface Props {
		isModalVisible: boolean;
		setIsModalVisible: any;
      ids: string[];
      callback:any;
      type:string;
}

const ApprovalModal = ({isModalVisible, setIsModalVisible, ids, callback, type}: Props) => {
   const [submitting, setSubmitting] = useState(false)

   const handleSubmit = async() => {
      setSubmitting(true)
      for(let i=0; i<ids.length; i++){
         let body = {
            id: ids[i]
         }
         await activateProvider(type, body.id).then(()=>{
            openNotification(
              "success",
              "bottomRight",
              `${type} activated successfully`
            );
            setSubmitting(false)
              setIsModalVisible(false)
              callback()
         })
         .catch((err:any)=> {
            openNotification(
             "error",
             "bottomRight",
             "An error occured. Please try again later"
           );
            setSubmitting(false)
              setIsModalVisible(false)
              callback()
         })
        
      }

   }

    return (
        <ModalContainer isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible}>
            <p style={{
               fontWeight: "700",
               fontSize: "28px",
            }}> Are you sure you want to activate this {type}?</p>
            <p style={{
               fontWeight: "400",
               fontSize: "16px",
               marginTop:"20px"
            }}
            >If you activate your this {type}, it will be automatically added to the list.</p>

            <div style={{display:"flex", justifyContent:"space-around", marginTop:"20px"}}>
               <Button style={{padding:"15px", width:"40%"}} onClick={()=> setIsModalVisible(false)}>Cancel</Button>
               <Button 
                  variant="secondary" 
                  style={{padding:"15px", width:"40%", backgroundColor:"#12B803"}} 
                  onClick={handleSubmit}
               >
               {submitting ? <LoadingOutlined/> : "Confirm"}</Button>
            </div>
          </ModalContainer>
    );
};

export default ApprovalModal;
