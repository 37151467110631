import React from 'react';
import styles from "./roletop.module.css"
import { Button } from "../../atoms"
import { useNavigate } from "react-router"
import { PageHeader } from "antd"
import { MdEdit } from "react-icons/md"
import { AiFillDelete } from "react-icons/ai"

type Props = {
    handleDelete?:any,
    name:string,
    id:string | any,
    backUrl:any,
    title:string,
    showDelete:boolean,
    editUrl:string,
}

const RoleTop = ({handleDelete, name, id, backUrl, title, showDelete, editUrl}:Props) => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <PageHeader
                className={styles.back}
                onBack={()=> navigate(backUrl)}
                title={title}
            />
            <div className={styles.sub_bar}>
                <p className={styles.p_name}>{name || "NA"}</p>
                <div className={styles.buttonGroup}>
                    <Button onClick={()=> navigate(editUrl)}> <MdEdit style={{fontSize:"27px"}}/> &nbsp;Modify Membership</Button>
                    {showDelete ? <Button variant="secondary" onClick={handleDelete}> <AiFillDelete style={{fontSize:"32px"}} />&nbsp; Delete</Button> : null}
                </div>
            </div>
        </div>
    );
};

export default RoleTop;
