import React, { useState, useContext, useEffect } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Providers from "./pages/Providers";
import Doctors from "./pages/Doctors";
import ProviderDetailsPage from "./pages/Providers/Provider";
import EditProvider from "./pages/Providers/EditProvider";
import AddProvider from "./pages/Providers/AddProvider";
import AddDoctor from "./pages/Doctors/AddDoctor";
import Reviews from "./pages/Reviews";
import ProvidersPage from "./pages/Providers/AllProviders";
import DoctorsPage from "./pages/Doctors/AllDoctors";
import UserContext from "./contexts/UserContext";
import Hospitals from "./pages/Hospitals";
import AddHospitals from "./pages/Hospitals/AddHospitals";
import HospitalPage from "./pages/Hospitals/AllHospitals";
import EditHospitals from "./pages/Hospitals/EditHospital";
import Doctor from "./pages/Doctors/Doctor";
import EditDoctor from "./pages/Doctors/EditDoctor";
import PromocodesPage from "./pages/Promocodes/AllPromocodes";
import Promocodes from "./pages/Promocodes";
import AddPromocode from "./pages/Promocodes/AddPromocode";
import EditPromocode from "./pages/Promocodes/EditPromocode";
import Promocode from "./pages/Promocodes/Promocode";
import Hospital from "./pages/Hospitals/Hospital";
import UserPage from "./pages/Users/AllUsers";
import Users from "./pages/Users";
import AddUser from "./pages/Users/AddUser";
import UnauthorizedPage from "./pages/Unauthorized";
import { Sidebar, Header, Appointments } from "./organisms";
import User from "./pages/Users/User";
import EditUser from "./pages/Users/EditUser";
import ProviderReview from "./pages/Providers/providerReview";
import Overview from "./pages/Overview";
import AllRoles from "./pages/Roles/AllRoles";
import Roles from "./pages/Roles/index";
import UpdateUserToRole from "./pages/Roles/UpdateUserToRole";
import Role from "./pages/Roles/Role";
import AddRole from "./pages/Roles/AddRole";
import EditHospitalAdmin from "./pages/Hospitals/EditHospitalAdmin";
import EditHospitalDoctor from "./pages/Hospitals/EditHospitalDoctor";
import EditFrontdeskManagers from "./pages/Hospitals/EditFrontdeskManagers";
import AddFrontDeskManager from "./pages/Hospitals/AddFrontDeskManager";
import Signin from "./pages/Signin";
import { renewAuthToken } from "./utils";
import ForgotPassword from "./pages/ForgotPassword";

function App() {
  const { user, authenticated, setAuthenticated } = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const width = collapsed ? "calc(100% - 10%)" : "calc(100% - 20%)";
  const minW = collapsed ? "calc(100% - 120px)" : "calc(100% - 318px)";

  useEffect(() => {
      const interval = setInterval(() => {
        renewAuthToken().then((res) => {
          if (!res) {
            setAuthenticated(false);
          }
        });
      }, 300000);
      return () => clearInterval(interval);
  }, []);

  return authenticated ? (
    <div style={{ display: "flex", backgroundColor: "#F1F3F8" }}>
      <Sidebar collapsed={collapsed} />
      <div style={{ width: width, minWidth: minW, minHeight: "100vh" }}>
        <Header
          toggleCollapse={() => setCollapsed(!collapsed)}
          collapsed={collapsed}
        />
        <div style={{ minHeight: "90vh", width: "98%" }}>
          <Routes>
            <Route path="/dashboard/providers" element={<Providers />}>
              <Route index element={<ProvidersPage />} />
              <Route
                path=":id"
                element={
                  user.permissions?.includes("SEARCH.PROVIDERS.READ.ALL") ? (
                    <ProviderDetailsPage />
                  ) : (
                    <Navigate to="/dashboard/providers?modal=true" />
                  )
                }
              />
              <Route
                path=":id/reviews"
                element={
                  user.permissions?.includes("SEARCH.PROVIDERS.READ.ALL") ? (
                    <ProviderReview />
                  ) : (
                    <Navigate to="/dashboard/providers?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit"
                element={
                  user.permissions?.includes("SEARCH.PROVIDERS.UPDATE.ALL") ? (
                    <EditProvider />
                  ) : (
                    <Navigate to="/dashboard/providers?modal=true" />
                  )
                }
              />
              <Route
                path="add"
                element={
                  user.permissions?.includes("SEARCH.PROVIDERS.CREATE.ALL") ? (
                    <AddProvider />
                  ) : (
                    <Navigate to="/dashboard/providers?modal=true" />
                  )
                }
              />
            </Route>
            <Route path="/dashboard/doctors" element={<Doctors />}>
              <Route index element={<DoctorsPage />} />
              <Route
                path=":id"
                element={
                  user.permissions?.includes("DOCTOR.READ.ALL") ? (
                    <Doctor />
                  ) : (
                    <Navigate to="/dashboard/doctors?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit"
                element={
                  user.permissions?.includes("DOCTOR.UPDATE.ALL") ? (
                    <EditDoctor />
                  ) : (
                    <Navigate to="/dashboard/doctors?modal=true" />
                  )
                }
              />
              <Route
                path="add"
                element={
                  user.permissions?.includes("DOCTOR.CREATE.ALL") ? (
                    <AddDoctor />
                  ) : (
                    <Navigate to="/dashboard/doctors?modal=true" />
                  )
                }
              />
            </Route>
            <Route path="/dashboard/promocodes" element={<Promocodes />}>
              <Route index element={<PromocodesPage />} />
              <Route
                path=":id"
                element={
                  user.permissions?.includes("PROMO_CODE_ADMIN.READ.ALL") ? (
                    <Promocode />
                  ) : (
                    <Navigate to="/dashboard/promocodes?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit"
                element={
                  user.permissions?.includes("PROMO_CODE_ADMIN.UPDATE.ALL") ? (
                    <EditPromocode />
                  ) : (
                    <Navigate to="/dashboard/promocodes?modal=true" />
                  )
                }
              />
              <Route
                path="add"
                element={
                  user.permissions?.includes("PROMO_CODE_ADMIN.CREATE.ALL") ? (
                    <AddPromocode />
                  ) : (
                    <Navigate to="/dashboard/promocodes?modal=true" />
                  )
                }
              />
            </Route>
            <Route path="/dashboard/hospitals" element={<Hospitals />}>
              <Route index element={<HospitalPage />} />
              <Route path="add" element={<AddHospitals />} />
              <Route
                path="add/front-desk-manager"
                element={
                  user.permissions?.includes("HOSPITAL.CREATE.ALL") ||  user.permissions?.includes("APPOINTMENT.READ.SELF") ? (
                    <AddFrontDeskManager />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />

              <Route
                path=":id"
                element={
                  user.permissions?.includes(
                    "HOSPITAL.READ.ALL") ||  user.permissions?.includes("APPOINTMENT.READ.SELF") ?
                    (
                    <Hospital />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit"
                element={
                  user.permissions?.includes("HOSPITAL.UPDATE.ALL") || user.permissions?.includes("APPOINTMENT.READ.SELF") ? (
                    <EditHospitals />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit/hospitalAdmin"
                element={
                  user.permissions?.includes("HOSPITAL.UPDATE.ALL") ||  user.permissions?.includes("APPOINTMENT.READ.SELF") ? (
                    <EditHospitalAdmin />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit/hospitalManagers"
                element={
                  user.permissions?.includes("HOSPITAL.UPDATE.ALL") ||  user.permissions?.includes("APPOINTMENT.READ.SELF") ? (
                    <EditFrontdeskManagers />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit/hospitalDoctors"
                element={
                  user.permissions?.includes("HOSPITAL.UPDATE.ALL") || user.permissions?.includes("APPOINTMENT.READ.SELF") ? (
                    <EditHospitalDoctor />
                  ) : (
                    <Navigate to="/dashboard/hospitals?modal=true" />
                  )
                }
              />
            </Route>
            <Route path="/dashboard/roles" element={<Roles />}>
              <Route index element={<AllRoles />} />
              <Route path=":id" element={<Role />} />
              <Route
                path=":id/modify-membership"
                element={<UpdateUserToRole />}
              />
              <Route path="add" element={<AddRole />} />
            </Route>
            <Route path="/dashboard/admin-users" element={<Users />}>
              <Route index element={<UserPage />} />
              <Route
                path="add"
                element={
                  user.permissions?.includes("ADMIN_USER.CREATE.ALL") ||
                  user.permissions?.includes("ADMIN_USER.CREATE.SELF") ? (
                    <AddUser />
                  ) : (
                    <Navigate to="/dashboard/users?modal=true" />
                  )
                }
              />
              <Route
                path=":id"
                element={
                  user.permissions?.includes("ADMIN_USER.READ.ALL") ||
                  user.permissions?.includes("ADMIN_USER.READ.SELF") ? (
                    <User />
                  ) : (
                    <Navigate to="/dashboard/users?modal=true" />
                  )
                }
              />
              <Route
                path=":id/edit"
                element={
                  user.permissions?.includes("ADMIN_USER.UPDATE.ALL") ||
                  user.permissions?.includes("ADMIN_USER.UPDATE.SELF") ? (
                    <EditUser />
                  ) : (
                    <Navigate to="/dashboard/users?modal=true" />
                  )
                }
              />
            </Route>
            <Route
              path="/dashboard/reviews"
              element={
                user?.permissions?.includes("SEARCH.REVIEW.READ.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.CREATE.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.UPDATE.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.DELETE.ALL") ? (
                  <Reviews />
                ) : (
                  <UnauthorizedPage />
                )
              }
            />

            <Route path="/dashboard/overview" element={<Overview />} />

            <Route path="*" element={<Navigate to="/dashboard/overview" />} />
            <Route path="/login" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <Routes>
        <Route path="*" element={<Signin />} />
        <Route path="/login" element={<Signin />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Routes>
    </div>
  );
}

export default App;
