import React,{ useEffect, useState, useContext } from 'react';
import { EditPageTop, EditPromoForm, ConfirmModal, DeleteModal, UnauthorizedModal } from "../../organisms"
import { useNavigate } from "react-router"
import { fetchSinglePromocode } from "../../utils"
import { useParams } from "react-router-dom"
import UserContext from '../../contexts/UserContext';

const EditPromocode = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const { user } = useContext(UserContext)
    const [ data, setData ] = useState<any>({} as any)
    const [ isModalVisible, setIsModalVisible] = useState(false)
    const [ isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)

    const handleShowDelete = () => {
      setIsModalVisible(false);
      setIsDeleteModalVisible(true)
    }   

    const checkUserRole = () => {
        user?.permissions?.includes("PROMO_CODE_ADMIN.DELETE.ALL") 
        ? setIsModalVisible(true) : setIsUnauthorizedModalVisible(true)
    }

    useEffect(()=> {
         id && fetchSinglePromocode(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
            <EditPageTop 
                handleDelete={checkUserRole}
                name={data?.name}
                backUrl="/dashboard/promocodes"
                title="Back to promo codes list"
                type="promo code"
            />
            <EditPromoForm 
                promocode={data}

            />

            {/*confirm modal*/}
            <ConfirmModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={handleShowDelete}
                type="promocode"
            />

           {/*delete modal*/}
           <DeleteModal 
                isModalVisible={isDeleteModalVisible} 
                setIsModalVisible={setIsDeleteModalVisible} 
                ids={[data?.id]}
                callback={()=> navigate("/dashboard/promocodes")}
                type="promocode"
            />

            <UnauthorizedModal 
                isModalVisible={isUnauthorizedModalVisible} 
                setIsModalVisible={setIsUnauthorizedModalVisible} 
                action={()=> {}}
            />
        </div>
    );
};

export default EditPromocode;
