import React from 'react';
import styles from "./Unauthorized.module.css"
// import { Button } from "../../atoms"
// import { useNavigate } from "react-router-dom"
import Unauthorized from "../../assets/unauthorized.svg"

const UnauthorizedPage = () => {
    return (
        <div className={styles.container}>
        	<div className={styles.hero_img}>
        		<img src={Unauthorized} alt="locked" />
        	</div>
        	<div className={styles.hero_cont}>
                <p className={styles.heading}>You are not authorized to view this page!</p>
                <p className={styles.info}>Please update your admin permissions or go back to an authorized page.</p>
            </div>
        </div>
    );
};

export default UnauthorizedPage;
