import React from 'react';
import { Progress } from "antd"
import { GrStar } from "react-icons/gr"
import styles from "./RatingBar.module.css"

interface Props {
	value:string;
	percent:number;
	amount:string;
}

const RatingBar:React.FC<Props> = ({value, percent, amount}) => {
    return (
        <div className={styles.rating_bar}>	
			<p style={{marginTop:"2px"}}>	{value}<GrStar style={{margin:"3px 20px 0px 5px"}}/></p>
			<Progress percent={percent} status="active" format={()=>amount } strokeColor="#FFA500"/>
		</div>
    );
};

export default RatingBar;
