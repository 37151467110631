import React, {useState} from 'react';
import {Tabs} from 'antd';
import { AllReviews} from "../../organisms"
import {useSearchParams} from "react-router-dom"
import {Helmet} from "react-helmet";

const {TabPane} = Tabs;

const Reviews = () => {

    const [param, setParam] = useSearchParams()
    const tab = param.get("tab")
    const [currentKey, setCurrentKey] = useState(tab === null ? "" : tab)
    const onChange = async (key: string) => {
        if (key === "all") {
            await setCurrentKey("");
            setParam({})
        } else {
            await setCurrentKey(key);
            setParam({"tab": key})
        }

    };

    // const applyFilters = () => {
    //     console.log("filter")
    // }

    // const resetFilters = () => {
    //     console.log("filter")
    // }

    const style = {
        backgroundColor: "#fff",
        borderRadius: "15px",
        padding: "10px 20px",
        fontSize: "16px",
        fontWeight: "700",
        color: "#000000"
    }

    // const extra = <FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters}/>
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Reviews || Dokitari Admin Dashboard</title>
                <link rel="canonical" href="/reviews" />
            </Helmet>
            <Tabs 
                defaultActiveKey={currentKey} 
                onChange={onChange} 
                // tabBarExtraContent={extra} 
                tabBarStyle={style}
            >
                <TabPane tab="ALL" key="all">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
                <TabPane tab="APPROVED" key="Approved">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
                <TabPane tab="UNAPPROVED" key="Unapproved">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
                <TabPane tab="WAITING" key="Waiting">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
                <TabPane tab="TRASH" key="Trash">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
                <TabPane tab="SPAM" key="Spam">
                    <AllReviews
                        key={currentKey}
                        currentKey={currentKey}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};


export default Reviews;
