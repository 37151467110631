import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom"
import {AddPageTop, EditHospitalDoctor} from "../../organisms";
import {fetchSingleHospital} from "../../utils"
import styles from "../../organisms/AddUserToRole/AUTR.module.css";
import {Spin} from "antd";
import {useNavigate} from "react-router"
import {HospitalType} from "../../types/hospital";


const EditHospitalDoctors = () => {
    const {id} = useParams()
    const [hospital, setHospital] = useState<HospitalType>()
    const [loading, setLoading] = useState<boolean>(true)
    const navigate = useNavigate()

    useEffect(() => {
        id && fetchSingleHospital(id).then((hospital: HospitalType) => {
            setHospital(hospital)
            setLoading(false)
        })
    }, [id])

    function reloadHospitalDetails(callback?: (hospital: HospitalType) => void) {
        if (id) {
            fetchSingleHospital(id).then((hospital: HospitalType) => {
                setHospital(hospital)
                if (callback) {
                    callback(hospital);
                }
            }).catch(e => {
                console.error(e)
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    return (<>
            <AddPageTop
                onBack={() => navigate(-1)}
                title="Back to Hospital"
                heading="Edit Doctor"
            />
            {loading ?
                <div className={styles.loader}>
                    <Spin/>
                </div> :
                <div style={{borderRadius: "15px", width: "100%", backgroundColor: "#ffffff"}}>
                    <EditHospitalDoctor hospitalDoctor={hospital} loading={loading}
                                        reloadHospitalDetails={reloadHospitalDetails}/>
                </div>
            }
        </>
    );
};

export default EditHospitalDoctors
