import React from "react";

const Upload = ({
	height = "56px",
	width = "80px",
	color = "#007AFF",
	...props
}: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 80 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46 5.22673e-06C48.455 -0.000632851 50.8841 0.50103 53.138 1.47413C55.3918 2.44723 57.4228 3.87119 59.1058 5.65841C60.7889 7.44563 62.0885 9.55831 62.9246 11.8665C63.7608 14.1747 64.1159 16.6295 63.968 19.08H64L63.996 23.988C68.101 23.9869 72.0494 25.5637 75.0245 28.3921C77.9996 31.2205 79.7737 35.0842 79.98 39.184L80 39.984C79.9969 44.0869 78.4178 48.0318 75.589 51.0036C72.7601 53.9754 68.8978 55.7469 64.8 55.952L64 55.972H63.996L64 56H43.996V40.308H53.852L40 24.012L26.184 40.312H36L35.996 56H16C11.7563 55.9894 7.68978 54.2976 4.69091 51.295C1.69205 48.2923 0.00527819 44.2237 0 39.98C0.000713999 36.294 1.27408 32.7213 3.60485 29.8658C5.93563 27.0103 9.18085 25.047 12.792 24.308C11.904 21.9882 11.7578 19.45 12.3736 17.0435C12.9893 14.6371 14.3367 12.481 16.2298 10.8728C18.1229 9.26459 20.4684 8.28347 22.9427 8.06481C25.417 7.84615 27.8982 8.40071 30.044 9.65201C31.5679 6.73892 33.8609 4.29908 36.6739 2.59746C39.4869 0.89584 42.7124 -0.0025003 46 5.22673e-06Z"
				fill={color}
			/>
		</svg>
	);
};

export default Upload;
