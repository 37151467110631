import React, { useContext } from 'react';
import { FilterPromocodesProvider } from "../../contexts/FilterPromocodesContext"
import { Outlet } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import UnauthorizedPage from "../Unauthorized";


const Promocodes = () => {
  const { user } = useContext(UserContext);

    return user.permissions?.includes("PROMO_CODE_ADMIN.READ.ALL") ||
        user.permissions?.includes("PROMO_CODE_ADMIN.CREATE.ALL") ||
        user.permissions?.includes("PROMO_CODE_ADMIN.UPDATE.ALL") ||
        user.permissions?.includes("PROMO_CODE_ADMIN.DELETE.ALL") ? (
        <FilterPromocodesProvider>
            <Outlet />
        </FilterPromocodesProvider>
    ) : (
        <UnauthorizedPage />
    );
}

export default Promocodes;
