import React, { useState } from "react";
import styles from "./AHF.module.css";
import { Button } from "../../atoms";
import * as yup from "yup";
import { Spin, Steps, UploadProps } from "antd";
import { uploadImage } from "../../utils";
import { useNavigate } from "react-router-dom";
import StepOne from "./stepOne";
import StepTwo from "./stepTwo";
import Details from "./details";
import { fetchNin } from "../../utils";
import { addHospital, addUser, openNotification } from "../../utils";
import { Formik, Form } from "formik";
import { AdminUserType } from "../../types/adminUser";
import { HospitalType } from "../../types/hospital";
import { Moment } from "moment";

const AddHospitalForm = () => {
  const [url, setUrl] = useState<any>({} as any);
  const [uploading, setUploading] = useState<boolean>(false);
  const [nin, setNin] = useState<string>("");
  const [logo, setLogo] = useState<any>({} as any);
  const [logoUpload, setLogoUploading] = useState(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [adminEmail, setAdminEmail] = useState<string>("");
  const [adminNumber, setAdminNumber] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isNinValidated, setIsNinValidated] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [ninError, setNinError] = useState<string>("");
  const [current, setCurrent] = useState<number>(0);
  const [addressLocation, setAddressLocation] = useState("");
  const [longitude, setLongitude] = useState<number>();
  const [latitude, setLatitude] = useState<number>();
  const [plusCode, setPlusCode] = useState<string>("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState<
    Moment | null | string | undefined
  >(null);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const navigate = useNavigate();

  const handleNinChange = (e: any) => {
    setNin(e.target.value.toUpperCase());
  };
  const dummyRequest = async (options) => {
    const { onSuccess } = options;
    setTimeout(() => {
      onSuccess("OK");
    }, 0);
  };
  const props: UploadProps = {
    accept: "image/*",
    name: "file",
    multiple: true,
    customRequest: dummyRequest,
    beforeUpload(file) {
      setUploading(true);
      uploadImage(file).then((res: any) => {
        setUrl({
          id: res?.asset_id,
          uid: res?.public_id,
          name: res.original_filename,
          url: res?.secure_url,
          status: "success",
          type: res?.type,
          size: res?.bytes,
        });
        setUploading(false);
        console.log(res);
      });
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleAdminNameChange = (e: any) => {
    setFirstName(e.target.value);
  };
  const handleAdminLastNameChange = (e: any) => {
    setLastName(e.target.value);
  };
  const handleAdminEmailChange = (e: any) => {
    setAdminEmail(e.target.value);
  };
  const handleAdminPhoneChange = (e: any) => {
    setAdminNumber(e.target.value);
  };
  const handleNin = (e: any) => {
    e.preventDefault();
    setId("");
    setFirstName("");
    setLastName("");
    setAdminEmail("");
    setAdminNumber("");
    setNinError("");
    setIsNinValidated(false);
    console.log(nin);
    console.log("bbbbnnnn");
    if (nin) {
      console.log(nin, "96");
      fetchNin(nin)
        .then((res: any) => {
          console.log("hfffff");
          console.log(res, "ress");
          setLoading(true);
          if (res?.errorMessage) {
            console.log(res);
            console.log("checking if this works");
            setNinError(res?.errorMessage);
            setIsNinValidated(false);
          } else {
            setFirstName(res?.firstName);
            setLastName(res?.lastName);
            setAdminEmail(res?.email);
            setAdminNumber(res?.phone);
            setId(res?.id);
            setBirthDate(res?.birthDate);
            setGender(res?.gender);
            setIsNinValidated(true);
            setNinError("");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching NIN:", error);
          setNinError("No users by the passed NIN");
          setIsNinValidated(false);
        });
    }
  };

  const prop: UploadProps = {
    accept: "image/*",
    name: "file",
    multiple: true,
    customRequest: dummyRequest,
    beforeUpload(file) {
      setLogoUploading(true);
      uploadImage(file).then((res: any) => {
        setLogo({
          id: res?.asset_id,
          uid: res?.public_id,
          name: res.original_filename,
          url: res?.secure_url,
          status: "success",
          type: res?.type,
          size: res?.bytes,
        });
        setLogoUploading(false);
        console.log(res);
      });
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Hospital name is required"),
    telephone: yup
      .string()
      .matches(/^\+?[1-9]\d{1,14}$/, "Invalid phone number")
      .required("Phone number is required"),
    email: yup.string().email("Email is invalid").required("Email is required"),
    licenseInformation: yup
      .string()
      .required("License Information is required"),
    address: yup.string().required("Hospital Address is required"),
  });

  const resetExtras = () => {
    setUrl({});
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const next = () => {
    setCurrent(current + 1);
  };

  return (
    <Formik
      initialValues={{
        name: "",
        telephone: "",
        email: "",
        licenseInformation: "",
        firstName: "",
        lastName: "",
        adminEmail: "",
        adminNumber: "",
        address: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const userdata: AdminUserType = {
          nin: nin,
          firstName: firstName,
          lastName: lastName,
          email: adminEmail,
          phone: adminNumber,
          gender: gender,
          birthDate: birthDate,
        };
        const data: HospitalType = {
          name: values.name,
          contactPhone: values.telephone,
          contactEmail: values.email,
          address: values.address,
          longitude: longitude,
          country: country,
          city: city,
          latitude: latitude,
          locationPlusCode: plusCode,
          licenseInformation: values.licenseInformation,
          operatingLicense: {
            id: url?.id,
            uid: url?.uid,
            name: url.name,
            url: url?.url,
            status: "success",
            type: url?.type,
            size: url?.size,
          },
          hospitalLogo: {
            id: logo?.id,
            uid: logo?.uid,
            name: logo.name,
            url: logo?.url,
            status: "success",
            type: logo?.type,
            size: logo?.size,
          },
          superAdminId: id,
        };
        if (!id) {
          await addUser(userdata).then((response: any) => {
            setId(response?.id);
            addHospital({ ...data, superAdminId: response?.id })
              .then((res: any) => {
                if (res?.errorMessage || res?.error) {
                  setSubmitting(false);
                  openNotification(
                    "error",
                    "bottomRight",
                    res?.errorMessage || res?.error
                  );
                } else {
                  openNotification(
                    "success",
                    "bottomRight",
                    "Hospital added successfully"
                  );
                  resetForm();
                  resetExtras();
                  navigate("/dashboard/hospitals");
                }
              })
              .catch(() => {
                setSubmitting(false);
                openNotification(
                  "error",
                  "bottomRight",
                  "An error occurred. Please try again later"
                );
              });
          });
        } else {
          addHospital(data)
            .then((res: any) => {
              console.log(res);
              if (res?.errorMessage || res?.error) {
                setSubmitting(false);
                openNotification(
                  "error",
                  "bottomRight",
                  res?.errorMessage || res?.error
                );
              } else {
                openNotification(
                  "success",
                  "bottomRight",
                  "Hospital added successfully"
                );
                resetForm();
                resetExtras();
                navigate("/dashboard/hospitals");
              }
            })
            .catch((err: any) => {
              console.log(err);
              setSubmitting(false);
              openNotification(
                "error",
                "bottomRight",
                "An error occured. Please try again later"
              );
            });
        }
      }}
    >
      {({ values, errors, isSubmitting, resetForm }) => (
        <Form className={styles.form}>
          <div>
            <Steps
              current={current}
              size="small"
              items={[
                {
                  title: "Hospital Form",
                },
                {
                  title: "SuperAdmin Form",
                },
                {
                  title: "Submit",
                },
              ].map((item) => ({ key: item.title, title: item.title }))}
            />
            {current === 0 && (
              <StepOne
                uploading={uploading}
                url={url}
                logo={logo}
                logoUpload={logoUpload}
                props={props}
                prop={prop}
                errors={errors}
                next={next}
                resetForm={resetForm}
                addressLocation={addressLocation}
                setAddressLocation={setAddressLocation}
                setLongitude={setLongitude}
                setLatitude={setLatitude}
                setPlusCode={setPlusCode}
                country={country}
                setCountry={setCountry}
                setCity={setCity}
              />
            )}
            {current === 1 && (
              <StepTwo
                handleAdminNameChange={handleAdminNameChange}
                handleAdminLastNameChange={handleAdminLastNameChange}
                handleAdminPhoneChange={handleAdminPhoneChange}
                handleNin={handleNin}
                handleNinChange={handleNinChange}
                handleAdminEmailChange={handleAdminEmailChange}
                nin={nin}
                firstName={firstName}
                lastName={lastName}
                adminNumber={adminNumber}
                loading={loading}
                isNinValidated={isNinValidated}
                adminEmail={adminEmail}
                id={id}
                ninError={ninError}
                next={next}
                prev={prev}
                setGender={setGender}
                gender={gender}
                birthDate={birthDate}
                setBirthDate={setBirthDate}
              />
            )}
            {current === 2 && (
              <Details
                firstName={firstName}
                lastName={lastName}
                adminNumber={adminNumber}
                adminEmail={adminEmail}
                HospitalName={values.name}
                contactPhone={values.telephone}
                contactEmail={values.email}
                addressLocation={addressLocation}
                address={values.address}
                licenseInformation={values.licenseInformation}
              />
            )}
          </div>

          <div className={styles.field_section_full}>
            <div className={styles.form_field}>
              &nbsp; &nbsp;
              <div className={styles.button_group}>
                {current === 2 && (
                  <Button type="button" variant="ash" onClick={() => prev()}>
                    Previous
                  </Button>
                )}
                {current === 2 && (
                  <Button
                    variant="ghost"
                    style={{
                      border: "1.5px solid #007AFF",
                    }}
                    type="submit"
                    disabled={Object.keys(errors).length > 0 || !url?.url}
                  >
                    {isSubmitting ? <Spin /> : "Submit"}
                  </Button>
                )}
                {console.log(Object.keys(errors))}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddHospitalForm;
