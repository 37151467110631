import React from 'react';
import styles from "./arut.module.css"
import { useNavigate } from "react-router"
import { PageHeader } from "antd"

type Props = {
    name:string;
    backUrl:string;
    title:string;
    text:string
}
const EditPageTop = ({ name, backUrl, title, text}: Props) => {
    const navigate = useNavigate()
    return (
        <div className={styles.container}>
            <PageHeader
                className={styles.back}
                onBack={()=> navigate(backUrl)}
                title={title}
            />
            <div className={styles.sub_bar}>
                <p className={styles.p_name}>{text} {name}</p>
                <div className={styles.buttonGroup}>
                </div>
            </div>
            <hr style={{border: "1px solid rgba(31, 31, 31, 0.4)"}}/>
        </div>
    );
};

export default EditPageTop;
