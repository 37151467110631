import React from 'react';
import styles from "./AppointmentsTable.module.css"
import { Table, Space } from 'antd';
import { AiOutlineDelete } from "react-icons/ai"
import { Edit } from "../../svg";
import type { ColumnsType } from "antd/es/table"

export interface DataType {
	key: any;
	name:string;
	email:string;
	phone:string;
	appointment:string,
	gender:string;
	dob:string;
}

const AppointmentsTable = ({appointments}:{appointments:any[]}) => {



	const columns: ColumnsType<DataType> = [
		{
			title: 'Full Name',
			dataIndex: 'patient',
			key: 'name',
			width: "20%",
			render: (patient) => {
				return `${patient.firstName} ${patient.lastName}`;
			},
		},

		{
		    title: 'Email',
			dataIndex: ['patient', 'email'],
		    key: 'email',
		    width:"18%",
		},
		{
		    title: 'Phone number',
			dataIndex: ['patient', 'phone'],
		    key: 'phone',
		    width:"15%",
		},
		{
		    title: 'Appointment',
			dataIndex: ['selectedDate'],
		    key: 'appointment',
		    width:"14%",
		},
		{
		    title: 'Gender',
			dataIndex: ['patient', 'gender'],
		    key: 'gender',
		    width:"10%",
		},
		{
		    title: 'Date of birth',
			dataIndex: ['patient', 'birthDate'],
		    key: 'dob',
		    width:"14%",
		},
		{
		    title: '',
		    dataIndex: 'action',
		    key: 'action',
		    width:"9%",
		    render: (_, {key}) => (
		      <Space size="middle">
		        <Edit 
		          width="20px" 
		          height="20px" 
		          style={{cursor:"pointer"}} 
		          // onClick={()=> navigate(`/dashboard/appointments/${key}/edit`)}
		        />
		        <AiOutlineDelete 
		          style={{
		            color:"#FD4C06", 
		            fontSize:"20px", 
		            cursor:"pointer"
		          }} 
		          // onClick={()=> {
		          //   setIsModalVisible(true)
		          //   setIds([key])
		          // }}
		        />
		      </Space>
		    ),
		}
	]

    return (
        <div className={styles.container}>
        	<Table 
        		columns={columns} 
        		dataSource={appointments}
        		size="middle" 
        		pagination={{ pageSize: 10 }}
        		className={styles.table}
        		scroll={{x:"max-content", y:"100%"}}
        	/>
        </div>
    );
};

export default AppointmentsTable;
