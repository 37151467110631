import React, { useState } from "react";
import styles from "./AddForm.module.css";
import {
	EditFormInput,
	EditFormTextarea,
	ImageUpload,
	TimePickerInput,
	EditFormCheckbox,
	ImageCard,
} from "../../molecules";
import { Home, Filter } from "../../svg";
import { Button } from "../../atoms";
import {
	AiTwotoneMail,
	AiOutlineTwitter,
	AiFillInstagram,
} from "react-icons/ai";
import { BsPhoneFill, BsGridFill } from "react-icons/bs";
import { IoMdMap } from "react-icons/io";
import { FaCompass, FaGlobe } from "react-icons/fa";
import { RiMapPinFill, RiWhatsappFill, RiTimeFill } from "react-icons/ri";
import { GrFacebookOption, GrLinkedinOption } from "react-icons/gr";
import { TbWorldLongitude, TbWorldLatitude } from "react-icons/tb";
import {Formik, Form} from "formik";
import * as yup from "yup";
import {
	addProvider,
	uploadImage,
	hoursCheck,
	openNotification,
} from "../../utils";
import type { UploadProps } from "antd";
import { Spin } from "antd";
import {useNavigate} from 'react-router-dom';

const AddForm = () => {
	const [urls, setUrls] = useState<string[]>([]);
	const [sundayHours, setSundayHours] = useState([]);
	const [weekdayHours, setWeekdayHours] = useState([]);
	const [saturdayHours, setSaturdayHours] = useState([]);
	const [uploading, setUploading] = useState(false);
	const navigate = useNavigate();

	const dummyRequest = async (options) => {
		const { onSuccess } = options;
		setTimeout(() => {
			onSuccess("OK");
		}, 0);
	};
	const props: UploadProps = {
		accept: "image/*",
		name: "file",
		multiple: true,
		customRequest: dummyRequest,
		beforeUpload(file) {
			setUploading(true);
			uploadImage(file).then((res: any) => {
				setUrls([...urls, res?.secure_url]);
				setUploading(false);
			});
			return false;
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};
	// const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const re =
		/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

	const validationSchema = yup.object().shape({
		name: yup.string().required("Prvider name is required"),
		address: yup.string().required("Provider address is required"),
		telephone: yup
			.string()
			.matches(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number')
			.required("Phone number is required"),
		email: yup
			.string()
			.email("Email is invalid")
			// .matches(emailRegex,"Provider email is not valid")
			.required("Provider email is required"),
		type: yup.string().required("Provider type is required"),
		organisation_name: yup.string().required("Organisation name is required"),
		organisation_type: yup.string().required("Organisation type is required"),
		location_plus_code: yup.string().required("Location plus code is required"),
		region: yup.string().required("Provider region is required"),
		district: yup.string().required("Provider district is required"),
		country: yup.string().required("Provider country is required"),
		website: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Provider website is required"),
		services_offered: yup.string().required("Provider services is required"),
		special_services: yup
			.string()
			.required("Provider special services is required"),
		description: yup.string().required("Provider description is required"),
		facebook: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Facebook link is required"),
		instagram: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Instagram link is required"),
		twitter: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Twitter link is required"),
		linkedin: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Linkedin link is required"),
		whatsapp: yup
			.string()
			.matches(re, "URL is not valid")
			.required("Whatsapp link is required"),
		longitude: yup
			.number()
			.typeError("Longitude must be a number")
			.required("Longitude is required"),
		latitude: yup
			.number()
			.typeError("Latitude must be a number")
			.required("Latitude is required"),
		promoted: yup.boolean().required("Specify if provider will be promoted"),
		key_person_ids: yup.string().required("Key person ids are required"),
	});

	const resetExtras = () => {
		setUrls([]);
		setSundayHours([]);
		setSaturdayHours([]);
		setWeekdayHours([]);
	};






	return (
		<Formik
			initialValues={{
				name: "",
				address: "",
				telephone: "",
				email: "",
				type: "",
				location_plus_code: "",
				region: "",
				district: "",
				country: "",
				website: "",
				description: "",
				facebook: "",
				instagram: "",
				twitter: "",
				linkedin: "",
				whatsapp: "",
				photo_urls: [],
				services_offered: "",
				organisation_name: "",
				organisation_type: "",
				special_services: "",
				sunday: "",
				monday_friday: "",
				saturday: "",
				promoted: false,
				longitude: "",
				latitude: "",
				key_person_ids: "",
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				// console.log(values)
				const data = {
					address: values.address,
					averageRating: null,
					country: values.country,
					description: values.description,
					district: values.district,
					dokitari_id: null,
					email: values.email,
					location_plus_code: values.location_plus_code,
					name: values.name,
					numberOfRatings: null,
					open_hours: {
						sunday: hoursCheck(sundayHours),
						monday_friday: hoursCheck(weekdayHours),
						saturday: hoursCheck(saturdayHours),
					},
					organisation_name: values.organisation_name,
					organisation_type: values.organisation_type,
					photo_urls: urls,
					region: values.region,
					services_offered: values.services_offered.split(","),
					social_media_links: {
						facebook: values.facebook,
						whatsapp: values.whatsapp,
						linkedin: values.linkedin,
						twitter: values.twitter,
						instagram: values.instagram,
					},
					special_services: values.special_services.split(","),
					telephone: values.telephone,
					type: values.type,
					website: values.website,
					promoted: values.promoted,
					longitude: +values.longitude,
					latitude: +values.latitude,
					deactivated: false,
					key_person_ids: values.key_person_ids.split(","),
				};

				addProvider(data)
					.then((res: any) => {

						if(res?.errorMessage !== "" || res?.error){
							setSubmitting(false);
							openNotification(
								"error",
								"bottomRight",
								res?.errorMessage || res?.errorr
							);
						} else{
							setSubmitting(false);
							openNotification(
								"success",
								"bottomRight",
								"Provider added successfully"
							);
							resetForm();
							resetExtras();
							navigate("/dashboard/providers")
						}
					})
					.catch((err: any) => {
						setSubmitting(false);
						openNotification(
							"error",
							"bottomRight",
							"An error occured. Please try again later"
						);
					});
			}}
		>
			{({ errors, isSubmitting, resetForm }) => (
				<Form className={styles.form}>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Provider name</p>
							<EditFormInput
								icon={<Home color="#007AFF" />}
								type="text"
								name="name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Provider type</p>
							<EditFormInput
								icon={<Filter color="#007AFF" fillOpacity="1" />}
								type="text"
								name="type"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Organisation name</p>
							<EditFormInput
								icon={<Home color="#007AFF" />}
								type="text"
								name="organisation_name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Organisation type</p>
							<EditFormInput
								icon={<Filter color="#007AFF" fillOpacity="1" />}
								type="text"
								name="organisation_type"
							/>
						</div>
					</div>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Address</p>
							<EditFormInput
								icon={<RiMapPinFill />}
								type="text"
								name="address"
							/>
						</div>
					</div>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Location plus code</p>
							<EditFormInput
								icon={<BsGridFill />}
								type="text"
								name="location_plus_code"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Country</p>
							<EditFormInput icon={<IoMdMap />} type="text" name="country" />
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Region</p>
							<EditFormInput icon={<IoMdMap />} type="text" name="region" />
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>District</p>
							<EditFormInput icon={<FaCompass />} type="text" name="district" />
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Website</p>
							<EditFormInput icon={<FaGlobe />} type="url" name="website" />
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Email</p>
							<EditFormInput
								icon={<AiTwotoneMail />}
								type="email"
								name="email"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Telephone</p>
							<EditFormInput
								icon={<BsPhoneFill />}
								type="tel"
								name="telephone"
							/>
						</div>
					</div>
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Longitude</p>
							<EditFormInput icon={<TbWorldLongitude />} name="longitude" />
						</div>
						&nbsp; &nbsp;
						<div className={styles.form_field}>
							<p className={styles.label}>Latitude</p>
							<EditFormInput icon={<TbWorldLatitude />} name="latitude" />
						</div>
						&nbsp; &nbsp;
						<div className={styles.form_field}>
							<EditFormCheckbox
								className={styles.checkbox}
								name="promoted"
								id="promoted"
								label="Promotion on Dokitari Search?"
							/>
						</div>
					</div>
					<hr
						style={{
							border: "1px solid rgba(31, 31, 31, 0.4)",
							marginBottom: "20px",
						}}
					/>
					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Key persons ids(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="key_person_ids"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Services offered(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="services_offered"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>
								Special services(separated by comma)
							</p>
							<EditFormTextarea
								rows={2}
								name="special_services"
							></EditFormTextarea>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<div className={styles.form_field_full}>
							<p className={styles.label}>Description</p>
							<EditFormTextarea rows={6} name="description"></EditFormTextarea>
						</div>
					</div>

					<hr
						style={{
							border: "1px solid rgba(31, 31, 31, 0.4)",
							marginBottom: "20px",
						}}
					/>
					<div className={styles.field_section_gallery}>
						<ImageUpload
							props={props}
							disabled={urls.length === 4 || uploading ? true : false}
							loading={uploading}
						/>
						<div className={styles.image_gallery}>
							{urls.length === 0
								? null
								: urls.map((url: string, id: any) => (
										<ImageCard
											key={id}
											url={url}
											deleteImage={() => setUrls(urls.filter((x) => x !== url))}
										/>
								  ))}
						</div>
					</div>

					<div className={styles.field_section_full}>
						<p className={styles.label}>Open hours</p>
						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<p className={styles.label}>Sunday</p>
								<TimePickerInput
									name="sunday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									onChange={(times: any, timeStrings: any) => {
										setSundayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<p className={styles.label}>Monday - Friday</p>
								<TimePickerInput
									name="monday_friday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									onChange={(times: any, timeStrings: any) => {
										setWeekdayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<p className={styles.label}>Saturday</p>
								<TimePickerInput
									name="saturday"
									format="HH:mm"
									bordered={false}
									icon={<RiTimeFill />}
									onChange={(times: any, timeStrings: any) => {
										setSaturdayHours(timeStrings);
										//console.log(timeStrings)
									}}
								/>
							</div>
						</div>
					</div>

					<div className={styles.field_section_full}>
						<p className={styles.label}>Social media links</p>
						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<GrFacebookOption />}
									type="url"
									name="facebook"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<EditFormInput
									icon={<AiOutlineTwitter />}
									type="url"
									name="twitter"
								/>
							</div>
						</div>

						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<AiFillInstagram />}
									type="url"
									name="instagram"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.form_field}>
								<EditFormInput
									icon={<GrLinkedinOption />}
									type="url"
									name="linkedin"
								/>
							</div>
						</div>

						<div className={styles.open_hours}>
							<div className={styles.form_field}>
								<EditFormInput
									icon={<RiWhatsappFill />}
									type="url"
									name="whatsapp"
								/>
							</div>
							&nbsp; &nbsp;
							<div className={styles.button_group}>
								<Button
									variant="ghost"
									style={{
										border: "1.5px solid #007AFF",
									}}
									type="submit"
									disabled={
										Object.keys(errors).length > 0 ||
										isSubmitting ||
										urls.length === 0 ||
										sundayHours.length < 2 ||
										weekdayHours.length < 2 ||
										saturdayHours.length < 2
											? true
											: false
									}
								>
									{isSubmitting ? <Spin /> : "Save"}
								</Button>
								<Button 
									variant="ash" 
									type="reset" 
									onClick={() => {
										resetForm();
										resetExtras();
									}}>
									Cancel
								</Button>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default AddForm;
