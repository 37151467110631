import React, { useState } from "react";
import styles from "./adf.module.css";
import {
	EditFormInput,
	DatePickerInput,
	SelectInput
} from "../../molecules";
import { Button } from "../../atoms";
import { MdOutlineMeetingRoom } from "react-icons/md"
import { TbDiscount } from "react-icons/tb"
import { Formik, Form } from "formik";
import moment, { Moment } from "moment"
import * as yup from "yup";
import { Spin } from "antd";
import { FiPercent } from 'react-icons/fi';
import { FcExpired } from 'react-icons/fc';
import { addPromocode, openNotification } from "../../utils"
import { useNavigate } from "react-router-dom"
import {PromocodesType} from "../../types/promocodes";

const AddPromoForm = () => {
	const [endDate, setEndDate] = useState<Moment | null | string | undefined>(null);
	const [consultation, setConsultation] = useState("")
	const navigate = useNavigate();
	window.document.title = "Promo Codes || Dokitari Admin Dashboard"

	const validationSchema = yup.object().shape({
		name: yup.string().required("Promocode name is required"),
		percentage: yup.number()
					   .typeError("Percentage must be a number")
					   .positive("Percentage must be a positive number")
					   .integer("Percentage must be an integer")
					   .required("Percentage is required"),
	});

	const resetExtras = () => {
		setEndDate(null)
		setConsultation("")
	}

	const consultationOptions=["STANDARD", "EXPRESS", "SUPER_EXPRESS"];

	return (
		<Formik
			initialValues={{
				name:"",
				percentage:0
			}}
			validationSchema={validationSchema}
			onSubmit={(values, { setSubmitting, resetForm }) => {
				const data:PromocodesType = {
					name:values.name,
					consultationType:consultation,
					percentage:(values.percentage).toString(),
					endDate:endDate,
					numberOfUsage:0	
				};
				addPromocode(data)
					.then((res: any) => {
						console.log(res);
						setSubmitting(false);
						if(res?.errorMessage !== "" || res?.error){
                            setSubmitting(false);
                            openNotification(
                                "error",
                                "bottomRight",
                                res?.errorMessage || res?.errorr
                            );
                        } else{
							openNotification(
								"success",
								"bottomRight",
								"Promocode added successfully"
							);
							resetForm();
							resetExtras();
							navigate("/dashboard/promocodes")
						}
					})
					.catch((err: any) => {
						setSubmitting(false);
						openNotification(
							"error",
							"bottomRight",
							"An error occured. Please try again later"
						);
					});
					console.log(data)
			}}
		>
			{({ errors, isSubmitting, resetForm }) => (
				<Form className={styles.form}>
				    
					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Promo name</p>
							<EditFormInput
								icon={<TbDiscount/>}
								type="text"
								name="name"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>Consultation type</p>
							<SelectInput
								icon={<MdOutlineMeetingRoom/>}
								options={consultationOptions.map((consultationOption:any) => {
			                          return  {value:consultationOption,
			                              label:consultationOption}
			                        } )}
								bordered={false}
								onChange={(value) => {
									setConsultation(value)
									console.log(value)
								}} 
							/>
						</div>
					</div>


					<div className={styles.field_section}>
						<div className={styles.form_field}>
							<p className={styles.label}>Percentage</p>
							<EditFormInput
								icon={<FiPercent/>}
								type="text"
								name="percentage"
							/>
						</div>
						<div className={styles.form_field}>
							<p className={styles.label}>End Date</p>
							<DatePickerInput
								name="end_date"
								icon={<FcExpired/>}
								onChange={(val: any) => {
									setEndDate(moment(val._d).format("YYYY-MM-DD"))
								}} 
								value={endDate === null ? null : moment(endDate,"YYYY-MM-DD")}
			                    bordered={false} 
							/>
						</div>
					</div>
					
					<div className={styles.field_section}>
						<div className={styles.button_group}>
							<Button
								variant="ghost"
								style={{
									border: "1.5px solid #007AFF",
								}}
								type="submit"
								disabled={
									Object.keys(errors).length > 0 ||
									isSubmitting ||
									 endDate===null||
									 consultation===""
										? true
										: false
								}
							>
								{isSubmitting ? <Spin /> : "Save"}
							</Button>
							<Button 
								variant="ash" 
								type="reset" 
								onClick={() => {
									resetForm();
									resetExtras();
								}}
							>
								Cancel
							</Button>
						</div>
					  
					</div>
					
				</Form>
			)}
		</Formik>
	);
};

export default AddPromoForm;
