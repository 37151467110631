import React from 'react'
import {Descriptions} from 'antd';
import styles from "./AHF.module.css";

const Details = ({
                     firstName,
                     lastName,
                     adminNumber,
                     adminEmail,
                     HospitalName,
                     contactPhone,
                     contactEmail,
                     addressLocation,
                     licenseInformation,
                     address
                 }) => {
    return (
        <div className={styles.description}>
            <Descriptions title="Hospital Info">
                <Descriptions.Item label="HospitalName">{HospitalName}</Descriptions.Item>
                <Descriptions.Item label="HospitalEmail">{contactEmail}</Descriptions.Item>
                <Descriptions.Item label="ContactPhone">{contactPhone}</Descriptions.Item>
                <Descriptions.Item label="Address">{address}</Descriptions.Item>
                <Descriptions.Item label="Hospital Location">{addressLocation}</Descriptions.Item>
                <Descriptions.Item label="LincenseInformation">
                    {licenseInformation}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions title="Admin Info">
                <Descriptions.Item label="FirstName">{firstName}</Descriptions.Item>
                <Descriptions.Item label="LastName">{lastName}</Descriptions.Item>
                <Descriptions.Item label="AdminEmail">{adminEmail}</Descriptions.Item>
                <Descriptions.Item label="AdminNumber">
                    {adminNumber}
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}
export default Details