import React from 'react';
import styles from "./hospitalProfile.module.css"
import {HospitalType} from "../../types/hospital"
import {Collapse} from 'antd';
import {Edit} from "../../svg";
import {useNavigate} from "react-router-dom";

const HospitalProfile = ({hospital}: { hospital: HospitalType }) => {
    const {Panel} = Collapse;
    const navigate = useNavigate()
    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const profile = [
        {
            'title': 'Name',
            'desc': hospital?.name || 'NA'
        },
        {
            'title': 'Contact',
            'desc': hospital?.contactPhone || "NA"
        },
        {
            'title': 'Email',
            'desc': hospital?.contactEmail || "NA"
        },
        {
            'title': 'Address',
            'desc': hospital?.address || "NA"
        },
        {
            'title': 'Lincense',
            'desc': hospital?.licenseInformation || "NA"
        }
    ]

    return (
        <Collapse defaultActiveKey={['1']} onChange={onChange}>
            <Panel header="Hospital Info" key="1">
                <div className={styles.container}>
                    <div className={styles.img}>
                        <img src={hospital?.operatingLicense?.url} alt={'operating license'}/>
                    </div>
                    <div className={styles.info}>
                        {profile.map((info: any) => (
                            <div className={styles.info_item}>
                                <p className={styles.title}>{info.title}</p>
                                <p className={styles.desc}>{info.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </Panel>
            <Panel header="Super Admin" key="2">
                <div className={styles.admin_container}>
                    {!hospital?.staff?.super_admins.length ?
                        <div className={styles.nodata}>
                            <p style={{paddingRight:'10px'}}>No Admin Available</p>
                            <Edit
                                width="20px"
                                height="20px"
                                style={{cursor: "pointer"}}
                                onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalAdmin`)}
                            />
                        </div>
                        :
                        <>
                        <div className={styles.info_admin}>
                            <p className={styles.title_admin}>Name</p>
                            <Edit
                                width="20px"
                                height="20px"
                                style={{cursor: "pointer"}}
                                onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalAdmin`)}
                            />
                        </div>
                    {hospital?.staff?.super_admins.map((superAdmin) => (
                        <div className={styles.info_admin}>
                        <p className={styles.desc}>{superAdmin?.firstName}&nbsp;{superAdmin?.lastName}</p>
                        </div>
                        ))}
                        </>
                    }
                </div>
            </Panel>
            <Panel header="Doctors" key="3">
                <div className={styles.profile_container}>
                    {!hospital?.staff?.doctors.length ?
                        <div className={styles.nodata}>
                            <p style={{paddingRight:'10px'}}>No Doctor Available yet</p>
                            <Edit
                                width="20px"
                                height="20px"
                                style={{cursor: "pointer"}}
                                onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalDoctors`)}
                            /></div>
                        :
                        <table className={styles.row_table}>
                            <tr>
                                <th className={styles.table_row}>Name</th>
                                <th className={styles.table_row}>Email</th>
                                <th className={styles.table_row}><Edit
                                    width="20px"
                                    height="20px"
                                    style={{cursor: "pointer"}}
                                    onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalDoctors`)}
                                /></th>
                            </tr>
                            {hospital?.staff?.doctors.map((doctor) => (
                                <tr>
                                    <td className={styles.table_data}>{doctor?.firstName}&nbsp;{doctor?.lastName}</td>
                                    <td className={styles.table_email}>{doctor?.email}</td>
                                </tr>
                            ))}
                        </table>
                    }
                </div>
            </Panel>
            <Panel header="Frontdesk Manager" key="4">
                <div className={styles.profile_container}>
                    {!hospital?.staff?.frontdesk_managers.length ?
                        <div className={styles.nodata}>
                            <p style={{paddingRight:'10px'}}>No Frontdesk manager Available</p>
                            <Edit
                                width="20px"
                                height="20px"
                                style={{cursor: "pointer"}}
                                onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalManagers`)}
                            />
                        </div>
                        :
                        <table className={styles.row_table}>
                            <tr>
                                <th className={styles.table_row}>Name</th>
                                <th className={styles.table_row}>Email</th>
                                <th className={styles.table_row}><Edit
                                    width="20px"
                                    height="20px"
                                    style={{cursor: "pointer"}}
                                    onClick={() => navigate(`/dashboard/hospitals/${hospital.id}/edit/hospitalManagers`)}
                                /></th>
                            </tr>
                            {hospital?.staff?.frontdesk_managers.map((frontdesk) => (
                                <tr>
                                    <td className={styles.table_data}>{frontdesk?.firstName}&nbsp;{frontdesk?.lastName}</td>
                                    <td className={styles.table_email}>{frontdesk?.email}</td>
                                </tr>
                            ))}
                        </table>
                    }
                </div>
            </Panel>
        </Collapse>
    );
};

export default HospitalProfile;

