import React,{ InputHTMLAttributes, ClassAttributes, useState } from 'react';
import styles from "./Input.module.css"
import { Alert } from "antd"
import { useField, FieldHookConfig } from "formik";
import { AiFillEyeInvisible, AiOutlineEye } from "react-icons/ai"

interface InputProps{
	label: string;
    variant?: "normal" | "center";
}

const Input = ({label,variant="normal", ...props}: InputProps & 
    InputHTMLAttributes<HTMLInputElement> & 
    FieldHookConfig<string> &
    ClassAttributes<HTMLInputElement>) => {
    const [field, meta] = useField(props);
    
    return (
        <>
            <div className={styles.input_wrapper} >    
                <input className={variant === "normal" ? styles.input : styles.center_input} {...field} {...props}/>
                <span className={variant === "normal" ? styles.label : styles.center_label}>{label}</span>
            </div>  
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}   
        </>    
    );
};


export const PasswordInput = ({label,variant="normal", ...props}: InputProps & 
    InputHTMLAttributes<HTMLInputElement> & 
    FieldHookConfig<string> &
    ClassAttributes<HTMLInputElement>) => {
    const [field, meta] = useField(props);
    const [ type, setType ] = useState("password")

    const handlePasswordToggle = () => {
        type === "password" ? setType("text") : setType("password")
    }
    return (
        <>
            <div className={styles.input_wrapper}>    
                <input type={type} className={variant === "normal" ? styles.input : styles.center_input} {...field} {...props}/>
                <span className={variant === "normal" ? styles.label : styles.center_label}>{label}</span>
                <span className={styles.password_icon} onClick={handlePasswordToggle}>
                    {type === "password" ? 
                    <AiFillEyeInvisible style={{opacity: "0.35", fontSize: "26px"}}/>: 
                    <AiOutlineEye style={{opacity: "0.35", fontSize: "26px"}}/>}
                </span>
            </div>  
            {meta.touched && meta.error ? (
                <Alert message={meta.error} type="error" showIcon/>
            ) : null}   
        </>    
    );
};




export default Input;
