import React from 'react';
import styles from "./pclt.module.css"
import { Button } from "../../atoms"
import FilterPromocodes  from"../FilterDropdown/PromocodesFilter"
import { MdCloudDownload } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from "react-router"
import { CSVLink } from "react-csv"
import { Props as DataProps } from "../PromocodesTable"
import { promocodesHeaders } from "../../utils"

const PromocodesListTop = ({data}:{data:DataProps[]}) => {
const navigate = useNavigate();

const applyFilters = () => {
        console.log("filter")
    }

    const resetFilters = () => {
        console.log("filter")
    }
  
    return (
        <div className={styles.container}>
        	<div className={styles.left}>
	        	<p className={styles.title}>Promocodes List</p>

	        	<div className={styles.buttonGroup}>
	        		<Button onClick={()=> navigate("/dashboard/promocodes/add")}><AiOutlinePlus style={{fontSize:"32px"}}/> &nbsp; Add new promo code</Button>
	        		
	        		<CSVLink data={data} filename={"Dokitari Promo codes"} headers={promocodesHeaders}>
	        			<Button variant="outline"><MdCloudDownload style={{fontSize:"32px"}}/> &nbsp; Download Excel</Button>
	        		</CSVLink>
	        	</div>
        	</div>

        	<FilterPromocodes applyFilters={applyFilters} resetFilters={resetFilters}/>
        </div>

    );
};

export default PromocodesListTop;
