import React,{ useState, useEffect, useContext } from 'react';
import { EditPageTop, EditForm, ConfirmModal, DeleteModal, UnauthorizedModal } from "../../organisms"
import { useParams, useNavigate } from "react-router-dom"
import { fetchSingleProvider } from "../../utils"
import { ProviderType } from "../../types/provider"
import UserContext from '../../contexts/UserContext';

const EditProvider = () => {
    const navigate= useNavigate()
    const { id } = useParams()
    const { user } = useContext(UserContext)
    const [ isModalVisible, setIsModalVisible] = useState(false)
    const [ isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [ data, setData ] = useState<ProviderType>({} as ProviderType)
     const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)

    const handleShowDelete = () => {
      setIsModalVisible(false);
      setIsDeleteModalVisible(true)
    }   

    const checkUserRole = () => {
        user?.permissions?.includes("SEARCH.PROVIDERS.DELETE.ALL") 
        ? setIsModalVisible(true) : setIsUnauthorizedModalVisible(true)
    }

    useEffect(()=> {    
        id && fetchSingleProvider(id).then((res:any)=> {
            setData(res)
        })
    },[id])

    return (
        <div style={{borderRadius:"15px", width:"100%", backgroundColor:"#ffffff"}}>
        	<EditPageTop 
                handleDelete={checkUserRole} 
                name={data.name}
                backUrl="/dashboard/providers"
                title="Back to provider list"
                type="provider"
            />
        	<EditForm provider={data}/>
            {/*confirm modal*/}
              <ConfirmModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={handleShowDelete}
                type="provider"
            />

           {/*delete modal*/}
           <DeleteModal 
                isModalVisible={isDeleteModalVisible} 
                setIsModalVisible={setIsDeleteModalVisible} 
                ids={[data.id as string]}
                callback={()=> navigate("/")}
                type="provider"
            />

            <UnauthorizedModal 
                isModalVisible={isUnauthorizedModalVisible} 
                setIsModalVisible={setIsUnauthorizedModalVisible} 
                action={()=> {}}
            />
                  
        </div>
    );
};

export default EditProvider;
