import React from 'react';
import styles from "./upt.module.css"
import { Button } from "../../atoms"
import UsersFilter  from"../FilterDropdown/UsersFilter"
import { MdCloudDownload } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from "react-router"
import {CSVLink} from "react-csv";
import { UserHeaders} from "../../utils";

const UserPageTop = ({data}: any) => {
    const navigate = useNavigate();

    const applyFilters = () => {
        console.log("filter")
    }

    const resetFilters = () => {
        console.log("filter")
    }

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <p className={styles.title}>User List</p>

                <div className={styles.buttonGroup}>
                    <Button onClick={()=> navigate("/dashboard/admin-users/add")}><AiOutlinePlus style={{fontSize:"32px"}}/> &nbsp; Add new User</Button>
                    <CSVLink data={data} filename={"Dokitari User"} headers={UserHeaders}>
                        <Button variant="outline"><MdCloudDownload style={{fontSize:"32px"}}/> &nbsp; Download Excel</Button>
                    </CSVLink>
                </div>
            </div>
            <UsersFilter applyFilters={applyFilters} resetFilters={resetFilters}/>
        </div>

    );
};

export default UserPageTop;
