import React, { createContext, useState, useContext } from 'react';

const FilterContext = createContext({} as any);

export const FilterDoctorsProvider = ({children}) => {
    const [selectedGender, setSelectedGender] = useState<string>("");
    const [selectedSpecialty, setSelectedSpecialty] = useState<string>("");
    const[selectedStatus, setSelectedStatus]= useState<string>("");

    const value:any = {
        selectedGender,
        setSelectedGender,
        selectedSpecialty,
        setSelectedSpecialty,
        selectedStatus,
        setSelectedStatus
    }

    return (
       <FilterContext.Provider value={value}>
       	{children} 
       </FilterContext.Provider>
    );
};

export const useDoctorsFilter = () => useContext(FilterContext);

