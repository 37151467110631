import React from 'react';
import styles from "./plt.module.css"
import { Button } from "../../atoms"
import FilterDropdown  from"../FilterDropdown"
import { MdCloudDownload } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from "react-router"
import { CSVLink } from "react-csv"
import { Props as DataProps } from "../ProviderTable"
import { headers } from "../../utils"

const ProviderListTop = ({data, applyFilters, resetFilters}:{data:DataProps[], applyFilters:any, resetFilters:any}) => {
const navigate = useNavigate();
  
    return (
        <div className={styles.container}>
        	<div className={styles.left}>
	        	<p className={styles.title}>Providers List</p>

	        	<div className={styles.buttonGroup}>
	        		<Button onClick={()=> navigate("/dashboard/providers/add")}><AiOutlinePlus style={{fontSize:"32px"}}/> &nbsp; Add new provider</Button>
	        		
	        		<CSVLink data={data} filename={"Dokitari Providers"} headers={headers}>
	        			<Button variant="outline"><MdCloudDownload style={{fontSize:"32px"}}/> &nbsp; Download Excel</Button>
	        		</CSVLink>
	        	</div>
        	</div>

        	<FilterDropdown applyFilters={applyFilters} resetFilters={resetFilters}/>
        </div>

    );
};

export default ProviderListTop;
