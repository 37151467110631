import React, { useState, useEffect, useContext } from "react";
import { SidebarLink } from "../../molecules";
import styles from "./Sidebar.module.css";
import { AiOutlineMedicineBox } from "react-icons/ai";
import { MdOutlineDashboard } from "react-icons/md";
import { HiOutlineUser } from "react-icons/hi";
import {MdEngineering} from "react-icons/md"
// import { FiSettings, FiActivity } from "react-icons/fi";
// import { BsListCheck } from "react-icons/bs";
import { TbShoppingCartDiscount } from "react-icons/tb";
import {
  // Statistics,
  People,
  Review,
  // Ads,
  // Document,
  Home,
  Doctor,
} from "../../svg";
import { Button, Logo } from "../../atoms";
import { Menu, Layout } from "antd";
import { useLocation } from "react-router-dom";
import UserContext from "../../contexts/UserContext";

const { Sider } = Layout;

const Sidebar = ({ collapsed }: { collapsed: boolean }) => {
  const { user } = useContext(UserContext);

  let location = useLocation();

  const urlCheck = (url: string) => {
    if (url.includes("/dashboard/providers")) {
      return "/dashboard/providers";
    } else if (url.includes("/dashboard/reviews")) {
      return "/dashboard/reviews";
    } else if (url.includes("/dashboard/overview")) {
      return "/dashboard/overview";
    }else if (url.includes("/dashboard/doctors")) {
      return "/dashboard/doctors";
    } else if (url.includes("/dashboard/promocodes")) {
      return "/dashboard/promocodes";
    } else if (url.includes("/dashboard/hospitals")) {
      return "/dashboard/hospitals";
    } else if (url.includes("/dashboard/admin-users")) {
      return "/dashboard/users";
    }else if(url.includes("/dashboard/roles")){
      return "/dashboard/roles"
    }
    else {
      return url;
    }
  };

  const [current, setCurrent] = useState(urlCheck(location.pathname));

  const handleClick = (e: any) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (location) {
      if (urlCheck(location.pathname) !== current) {
        setCurrent(urlCheck(location.pathname));
      }
    }
  }, [location, current]);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      className={collapsed ? styles.sidebar_collapsed : styles.sidebar}
    >
      {collapsed ? null : (
        <>
          <SidebarLink
            href="/"
            style={{ padding: "25px 0px 25px 40px" }}
            id="logo"
          >
            <Logo />
          </SidebarLink>
          <hr className={styles.line} />
        </>
      )}
      <div className={styles.links}>
        <Menu
          mode="inline"
          defaultSelectedKeys={["3"]}
          selectedKeys={[current]}
          onClick={handleClick}
          items={[
            {
              key: "/dashboard/overview",
              icon: <MdOutlineDashboard style={{ fontSize: "35px" }} />,
              label: <SidebarLink href="/dashboard/overview">Overview</SidebarLink>,
            },
            // {
            //   key: "2",
            //   icon: <Statistics />,
            //   label: <SidebarLink href="/">Statistics</SidebarLink>,
            //   disabled: true,
            // },
            {
              key: "/dashboard/hospitals",
              icon: <AiOutlineMedicineBox style={{ fontSize: "35px" }} />,
              label: (
                <SidebarLink href="/dashboard/hospitals">Hospitals</SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("HOSPITAL.READ.ALL") ||
                user?.permissions?.includes("HOSPITAL.UPDATE.ALL") ||
                user?.permissions?.includes("HOSPITAL.DELETE.ALL") ||
                user?.permissions?.includes("HOSPITAL.CREATE.ALL") ||
                user?.permissions?.includes("APPOINTMENT.READ.SELF") ||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/doctors",
              icon: <Doctor />,
              label: (
                <SidebarLink href="/dashboard/doctors">Doctors</SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("DOCTOR.READ.ALL") ||
                user?.permissions?.includes("DOCTOR.UPDATE.ALL") ||
                user?.permissions?.includes("DOCTOR.CREATE.ALL") ||
                user?.permissions?.includes("DOCTOR.DELETE.ALL") ||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/providers",
              icon: <People />,
              label: (
                <SidebarLink href="/dashboard/providers">Providers</SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("SEARCH.PROVIDERS.READ.ALL") ||
                user?.permissions?.includes("SEARCH.PROVIDERS.CREATE.ALL") ||
                user?.permissions?.includes("SEARCH.PROVIDERS.UPDATE.ALL") ||
                user?.permissions?.includes("SEARCH.PROVIDERS.DELETE.ALL") ||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/reviews",
              icon: <Review />,
              label: (
                <SidebarLink href="/dashboard/reviews">Reviews</SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("SEARCH.REVIEW.READ.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.CREATE.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.DELETE.ALL") ||
                user?.permissions?.includes("SEARCH.REVIEW.UPDATE.ALL")||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/promocodes",
              icon: <TbShoppingCartDiscount style={{ fontSize: "35px" }} />,
              label: (
                <SidebarLink href="/dashboard/promocodes">
                  Promo codes
                </SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("PROMO_CODE_ADMIN.READ.ALL") ||
                user?.permissions?.includes("PROMO_CODE_ADMIN.CREATE.ALL") ||
                user?.permissions?.includes("PROMO_CODE_ADMIN.DELETE.ALL") ||
                user?.permissions?.includes("PROMO_CODE_ADMIN.UPDATE.ALL")||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/users",
              icon: <HiOutlineUser style={{ fontSize: "35px" }} />,
              label: (
                <SidebarLink href="/dashboard/admin-users">Admin Users</SidebarLink>
              ),
              disabled:
                user?.permissions?.includes("ADMIN_USER.READ.ALL") ||
                user?.permissions?.includes("ADMIN_USER.CREATE.ALL") ||
                user?.permissions?.includes("ADMIN_USER.UPDATE.ALL") ||
                user?.permissions?.includes("ADMIN_USER.DELETE.ALL") ||
                user?.permissions?.includes("ADMIN_USER.READ.SELF") ||
                user?.permissions?.includes("ADMIN_USER.CREATE.SELF") ||
                user?.permissions?.includes("ADMIN_USER.UPDATE.SELF") ||
                user?.permissions?.includes("ADMIN_USER.DELETE.SELF")||
                user.roles?.includes("SUPER_ADMIN")
                  ? false
                  : true,
            },
            {
              key: "/dashboard/roles",
              icon: <MdEngineering style={{ fontSize: "35px" }} />,
              label: (
                  <SidebarLink href="/dashboard/roles">Roles</SidebarLink>
              )}
            // {
            //   key: "6",
            //   icon: <FiSettings style={{ fontSize: "35px" }} />,
            //   label: <SidebarLink href="/">Settings</SidebarLink>,
            //   disabled: true,
            // },
            // {
            //   key: "7",
            //   icon: <Ads />,
            //   label: <SidebarLink href="/">Ad management</SidebarLink>,
            //   disabled: true,
            // },
            // {
            //   key: "8",
            //   icon: <BsListCheck style={{ fontSize: "35px" }} />,
            //   label: <SidebarLink href="/">Todo list</SidebarLink>,
            //   disabled: true,
            // },
            // {
            //   key: "9",
            //   icon: <FiActivity style={{ fontSize: "35px" }} />,
            //   label: <SidebarLink href="/">My activities</SidebarLink>,
            //   disabled: true,
            // },
            // {
            //   key: "10",
            //   icon: <Document />,
            //   label: <SidebarLink href="/">Documentation</SidebarLink>,
            //   disabled: true,
            // },
          ]}
        />

        {collapsed ? (
          <Button>
            <Home />
          </Button>
        ) : (
          <Button>
            <Home /> &nbsp; &nbsp;Home Search
          </Button>
        )}
      </div>
    </Sider>
  );
};

export default Sidebar;
