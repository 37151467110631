import React,{ useEffect, useState } from 'react';
import { PageTop, DoctorProfile, Appointments } from "../../organisms";
import { useParams } from "react-router-dom"
import { fetchSingleDoctor } from "../../utils"
import { DoctorType } from "../../types/doctor"

const Doctor = () => {

    const { id } = useParams()
    const [ data, setData ] = useState<DoctorType>({} as DoctorType)

    useEffect(()=> {   
	    id && fetchSingleDoctor(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])

    return (
        <div>	
        	<PageTop  
                name={data?.firstName + " " + data?.lastName}
                id={data?.id}
                backUrl="/dashboard/doctors"
                title={data?.firstName + " " + data?.lastName}
                showDelete={false}
                editUrl={`/dashboard/doctors/${data?.id}/edit`}
                pageType="doctor"
            />
            <DoctorProfile doctor={data}/>
            <Appointments doctorId={data.id}/>
        </div>
    );
};

export default Doctor;
