import React, { useState, useEffect } from 'react';
import { PageTop, ProviderFeedback,ProviderReviews, ProviderInfo,ConfirmModal, DeleteModal } from "../../organisms"
import { useParams, useNavigate } from "react-router-dom"
import { fetchSingleProvider } from "../../utils"
import { ProviderType } from "../../types/provider"

const 	ProviderDetailsPage = () => {
    const navigate= useNavigate()
    const { id } = useParams()
    const [ isModalVisible, setIsModalVisible] = useState(false)
    const [ isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [ data, setData ] = useState<ProviderType>({} as ProviderType)

    const handleShowDelete = () => {
      console.log("here")
      setIsModalVisible(false);
      setIsDeleteModalVisible(true)
    }

    useEffect(()=> {    
        id && fetchSingleProvider(id).then((res:any)=> {
            setData(res)
            console.log(res)
        })
    },[id])

    const ids = [data.id as string]

    return (
        <div>	
        	<PageTop 
                handleDelete={()=> setIsModalVisible(true)} 
                name={data.name} 
                id={data.id}
                backUrl="/dashboard/providers"
                title="Back to provider list"
                showDelete={true}
                editUrl={`/dashboard/providers/${data.id}/edit`}
                pageType="provider"
            />
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <ProviderFeedback id={data.id}/>
                <ProviderReviews provider={data}/>
            </div>
            <ProviderInfo provider={data}/>
            {/*confirm modal*/}
            <ConfirmModal 
                isModalVisible={isModalVisible} 
                setIsModalVisible={setIsModalVisible} 
                action={handleShowDelete}
                type="provider"
            />

           {/*delete modal*/}
           <DeleteModal 
                isModalVisible={isDeleteModalVisible} 
                setIsModalVisible={setIsDeleteModalVisible} 
                ids={ids}
                callback={()=> navigate("/")}
                type="provider"
            />
        
        </div>	
    );
};


export default 	ProviderDetailsPage;
