import React, { createContext, useState, useContext } from 'react';

const FilterContext = createContext({} as any);

export const FilterPromocodesProvider = ({children}) => {
    const[selectedStatus, setSelectedStatus]= useState<string>("");
    const[selectedConsultation, setSelectedConsultation]= useState<string>("");

    const value:any = {
        selectedConsultation,
        setSelectedConsultation,
        selectedStatus,
        setSelectedStatus
    }

    return (
       <FilterContext.Provider value={value}>
       	{children} 
       </FilterContext.Provider>
    );
};

export const usePromocodesFilter = () => useContext(FilterContext);

