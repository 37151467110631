import React,{ useState, useEffect } from 'react';
import styles from "./ReviewCard.module.css"
import { Avatar, Rate } from "antd"
import { FaEllipsisH } from "react-icons/fa"
import { Button } from "../../atoms"
import { openNotification, editReviewStatus, fetchUserReviewCount } from "../../utils"
import { UnauthorizedModal } from "../../organisms"
import { UserType } from '../../types/provider';

interface Props {
	photo:string | any;
	name: string;
	email: string;
	userId: string;
	date: string;
	provider: string;
	comment: string;
	rating:number;
	status:any;
	id: any;
	user?:UserType;
}

const ReviewCard = ({id,photo,name,email,userId,date,provider,comment, rating, status, user}: Props) => {
   const [ reviewStatus, setReviewStatus] = useState(status.toLowerCase())
   const [ count, setCount] = useState(0)
   const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)

   useEffect(()=> {
   	fetchUserReviewCount(userId).then((res:any) => {
   		setCount(res)
   	})
   	.catch((err:any) => {
   		console.log(err)
   	})
   })

	const handleStatusChange = (x) => {
		editReviewStatus(id, x).then((res:any)=> {
			setReviewStatus(x.toLowerCase())
			openNotification(
	              "success",
	              "bottomRight",
	              "Review status updated successfully"
	            );
	         })
	         .catch((err:any)=> {
	            openNotification(
	             "error",
	             "bottomRight",
	             "An error occured. Please try again later"
	           );
	         })
	}
    return (
        <div className={styles.container}>
        	<div className={styles.reviewer_img}>
        	<Avatar
	            src={photo}
	            alt="provider"
	            size={50}
	            className={styles.providerImage}
	        />
	        </div>

	        <div className={styles.reviewer_info}>
		        <p style={{fontWeight:600}}>{name}</p>
		        <p>Email: {email}</p>
		        <br/>
		        <p style={{fontWeight:600}}>TOTAL REVIEWS</p>
		        <p style={{color:"#12B803"}}>{count} total reviews</p>
		        <br/>
		        <p style={{fontWeight:600}}>DATE</p>
		        <p>{date}</p>	
	        </div>

	        <div className={styles.review_details}>
	        	<p className={styles.provider_name}>{provider}</p>
	        	<br/>
	        	<p>{comment}</p>
	        	<br/>
	        	<Rate disabled defaultValue={rating} style={{fontSize:"25px"}}/>
	        </div>

	        <div>
	        	<FaEllipsisH/>
	        </div>

	        <div className={styles.button_group}>
	        	{reviewStatus === "approved" ? null : <Button 
	        		        		variant="outline" 
	        		        		style={{
	        		        			color:"#12B803",
	        		        			padding:"0px 10px",
	        		        			fontSize:"14px",
	        		        			marginRight:"20px"
	        		        		}}
	        		        		onClick={() => (user?.permissions)?.includes("SEARCH.REVIEW.UPDATE.ALL") ? handleStatusChange("Approved") : setIsUnauthorizedModalVisible(true)}

	        		        	>Approve</Button>}
	        	{reviewStatus === "unapproved" ? null : <Button 
	        		variant="outline" 
	        		style={{
	        			color:"#FD4C06",
	        			padding:"0px 10px",
	        			fontSize:"14px",
	        			marginRight:"20px"
	        		}}
	        		onClick={() => (user?.permissions)?.includes("SEARCH.REVIEW.UPDATE.ALL") ? handleStatusChange("Unapproved") : setIsUnauthorizedModalVisible(true)}
	        	>Unapprove</Button>}
	        	{reviewStatus === "waiting" ? null : <Button 
	        		variant="outline" 
	        		style={{
	        			color:"#007AFF",
	        			padding:"0px 10px",
	        			fontSize:"14px"
	        		}}
	        		onClick={() => (user?.permissions)?.includes("SEARCH.REVIEW.UPDATE.ALL") ? handleStatusChange("Waiting") : setIsUnauthorizedModalVisible(true)}
	        	>Mark as waiting</Button>}
	        </div>

	        <UnauthorizedModal 
                isModalVisible={isUnauthorizedModalVisible} 
                setIsModalVisible={setIsUnauthorizedModalVisible} 
                action={()=> {}}
            />
        	
        </div>
    );
};

export default ReviewCard;
