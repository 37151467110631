import React, {useState} from "react";
import styles from "./addrole.module.css";
import {
    EditFormInput
} from "../../molecules";
import {Button} from "../../atoms";
import {AiTwotoneMail} from "react-icons/ai";
import {BsPersonFill} from "react-icons/bs";
import {Formik, Form} from "formik";
import * as yup from "yup";
import {Spin} from "antd";
import {addNewRole, openNotification} from "../../utils";
import {useNavigate} from 'react-router-dom';
import Checkbox from "./checkbox";
// import {UserType} from "../../types/user";
import {roleUserType} from "../../types/role";

const AddRoleForm = ({role}) => {
    const navigate = useNavigate();
    const [ids, setIds] = useState<roleUserType[]>([])
    const [error, setError] = useState<any>()
    const [userIds] = useState<string[]>([]);
    const validationSchema = yup.object().shape({
        name: yup.string().required("name is required"),
        displayName: yup.string().required("display name is required"),
        description:yup.string().required("decription for this role is required")
    });

    return (
        <Formik
            initialValues={{
                name: "",
                displayName:"",
                description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                ids.map((id) => (
                    userIds.push(id.id)
                ))
                const data = {
                    name: values.name,
                    displayName: values.displayName,
                    description: values.description,
                    users:userIds
                };
                addNewRole(data)
                    .then((res: any) => {
                        console.log(res);
                        setSubmitting(false);
                        if (res.error) {
                            setSubmitting(false);
                            setError(res.error)
                        } else {
                            openNotification(
                                "success",
                                "bottomRight",
                                "Role added successfully"
                            );
                            resetForm();
                            navigate('/dashboard/roles')
                        }
                    })
                    .catch((err: any) => {
                        console.log(err)
                        setSubmitting(false);
                        openNotification(
                            "error",
                            "bottomRight",
                            "An error occured. Please try again later"
                        );
                    });
            }}
        >
            {({errors, isSubmitting, resetForm}) => (
                <Form className={styles.form} autoComplete={"off"}>
                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Role Name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="name"
                            />
                        </div>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Display Name</p>
                            <EditFormInput
                                icon={<BsPersonFill/>}
                                type="text"
                                name="displayName"
                            />
                        </div>
                    </div>

                    <div className={styles.field_section}>
                        <div className={styles.form_field}>
                            <p className={styles.label}>Role Description</p>
                            <EditFormInput
                                icon={<AiTwotoneMail/>}
                                type="text"
                                name="description"
                            />
                        </div>
                    </div>
                        <div className={styles.form_table}>
                            <p className={styles.role_member}>Select Role Member</p>
                                <table className={styles.row_table}>
                                    <tr>
                                        <th className={styles.table_row}></th>
                                        <th className={styles.table_row}>Name</th>
                                        <th className={styles.table_row}>Email Id</th>
                                    </tr>
                            {role.map((user: any) =>
                                <Checkbox
                                    user={user}
                                    ids={ids}
                                    setIds={setIds}
                                />)}
                                </table>
                    </div>
                    {error && <p style={{color:'red'}}>{error}</p> }
                    <div className={styles.field_section_full}>
                        <div className={styles.form_field}>

                            &nbsp; &nbsp;
                            <div className={styles.button_group}>
                                <Button
                                    variant="ghost"
                                    style={{
                                        border: "1.5px solid #007AFF",
                                    }}
                                    type="submit"
                                    disabled={
                                        Object.keys(errors).length > 0 ||
                                        isSubmitting
                                            ? true
                                            : false
                                    }
                                >
                                    {isSubmitting ? <Spin/> : "Save"}
                                </Button>
                                <Button
                                    variant="ash"
                                    type="reset"
                                    onClick={() => {
                                        resetForm();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>

                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AddRoleForm;
