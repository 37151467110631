import React from 'react';
import styles from "./st.module.css"
import { BsCircleFill } from "react-icons/bs"

interface Props {
	status:any;
}

interface DoubleStatusProps{
  status:any;
  handleActivate:any;
  handleDelete:any;
}

const StatusTag:React.FC<Props> = ({ status}) => {
    const color = (status === "active") ? "#12B803" : "#A7A7A7" 
    const text = (status === "active") ? "#000000" : "#A7A7A7" 
    return (
      <div className={styles.tag}>
        <BsCircleFill style={{color:color, fontSize:"10px"}}/>
        &nbsp;
      	 <p style={{color:text}}>{status}</p>
      </div>  
    );
};

export default StatusTag;


export const ApprovalTag:React.FC<Props> = ({ status}) => {
    return (
      <div className={styles.tag} style={{justifyContent:"center"}}>
         <p className={styles[status]}>{status}</p>
      </div>  
    );
};

export const DoubleStatusTag:React.FC<DoubleStatusProps> = ({ status, handleActivate, handleDelete}) => {
    return (
      <div className={styles.double_tag}>
        {(status === "active") ? 
          <div className={styles.tag_slice}>
             <BsCircleFill style={{color:"#12B803", fontSize:"10px"}}/>
              &nbsp;
            <p style={{color:"#000000"}}>Active</p>
          </div> : 
          <div className={styles.tag_slice} onClick={handleActivate}>
             <BsCircleFill style={{color:"#A7A7A7", fontSize:"10px"}}/>
              &nbsp;
            <p style={{color:"#A7A7A7"}}>Active</p>
          </div>
        }
        <hr/>
        {(status === "inactive") ? 
          <div className={styles.tag_slice}>
             <BsCircleFill style={{color:"#FD4C06", fontSize:"10px"}}/>
              &nbsp;
            <p style={{color:"#000000"}}>Inactive</p>
          </div> : 
          <div className={styles.tag_slice} onClick={handleDelete}>
             <BsCircleFill style={{color:"#A7A7A7", fontSize:"10px"}}/>
              &nbsp;
            <p style={{color:"#A7A7A7"}}>Inactive</p>
          </div>
        }
       
      </div>  
    );
};