import React, { useState} from 'react';
import styles from "./SearchBar.module.css"
import {SearchOutlined} from "@ant-design/icons";




const HospitalPageTopSearchBar = ({onSearch}: { onSearch: (searchTerm: string) => void }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const handleChange = e => {
        if (!e.target.value){
            setSearchTerm(e.target.value);
            onSearch(e.target.value);
        }else {
            setSearchTerm(e.target.value);
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        onSearch(searchTerm)
    };





    return (
        <form className={styles.form_configuration} onSubmit={handleSubmit}>
            <div className={styles.input_parent}>
                <input className={styles.input_configuration} type="text"
                       value={searchTerm}
                       onChange={handleChange}
                       placeholder="Search..."/>
                <button className={styles.button_configuration} type="submit"><SearchOutlined/></button>
            </div>

        </form>


    );
};


export default HospitalPageTopSearchBar;