import React, {useEffect, useState} from 'react';
import styles from "./pf.module.css"
import { GrStar } from "react-icons/gr"
import { Progress } from "antd"
import { RatingBar } from "../../molecules";
import {fetchProviderRatingsOverview} from "../../utils";

const ProviderFeedback = ({id}) => {
	const [data, setData] = useState<any>([])
	useEffect(() => {
		if(id){
			fetchProviderRatingsOverview(id).then((res: any) => {
				// console.log(res)
				setData(res)
			})
		}
	}, [id])

	const format = () => {
		return(
			<div style={{textAlign:"center"}}>
				<GrStar style={{color:"#FFA500", margin:"-15px auto 15px auto"}}/>
				<p className={styles.rating}>{data?.overallRating}</p>
				<p className={styles.rating_text}>Overall rating</p>
			</div>
		)
	}
    return (
        <div className={styles.container}>
        	<p className={styles.title}>Customer feedback</p>
        	<div style={{display:"flex", marginTop:"20px", justifyContent:"space-between", alignItems:"center"}}>
        		<Progress strokeLinecap="butt" type="circle" format={format} strokeColor="#FFA500" width={225} percent={80}/>
        		<div className="rating-bars" style={{width:"40%"}}>	

        			<p className={styles.ratings_stats_text}>Based on {data?.totalReviews}</p>
        			<RatingBar value="5" percent={(data?.total5StarReviews * 100)/data?.totalReviews} amount={data?.total5StarReviews}/>
        			<RatingBar value="4" percent={(data?.total4StarReviews * 100)/data?.totalReviews} amount={data?.total4StarReviews}/>
        			<RatingBar value="3" percent={(data?.total3StarReviews * 100)/data?.totalReviews} amount={data?.total3StarReviews}/>
        			<RatingBar value="2" percent={(data?.total2StarReviews * 100)/data?.totalReviews} amount={data?.total2StarReviews}/>
        			<RatingBar value="1" percent={(data?.total1StarReviews * 100)/data?.totalReviews} amount={data?.total1StarReviews}/>

        		</div>	
        	</div>
        	
        </div>
    );
};

export default ProviderFeedback;
