import React, { useState } from 'react';
import styles from './ForgotPassword.module.css';
import { Button, Input } from '../../atoms';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Spin } from 'antd';
import Logo from '../../assets/Group 744.svg';
import { requestNewPassword } from '../../utils';
import { useNavigate } from 'react-router';


interface Notification {
	message: string;
	type: 'success' | 'error';
}

const ForgotPassword = () => {
	const validationSchema = yup.object().shape({
		email: yup.string().email('Email is invalid').required('Email is required'),
	});
	const navigate = useNavigate();
	const [notification, setNotification] = useState<Notification | null>(null);

	const showNotification = (message: string, type: 'success' | 'error') => {
		setNotification({ message, type });
		setTimeout(() => {
			setNotification(null);
		}, 20000);
	};

	return (
		<div className={styles.container}>
			<div className={styles.hero}>
				<div className={styles.logo}>
					<img src={Logo} alt="logo" />
				</div>
				<div className={styles.hero_cont}>
					<p className={styles.heading}>Welcome Back to Dokitari Admin Dashboard!</p>
					<p className={styles.info}>Please sign up with your admin dashboard login details.</p>
				</div>

				<p className={styles.sub}>https://search-dev.dokitari.com/</p>
			</div>
			<div className={styles.form}>
				<Formik
					initialValues={{
						email: '',
					}}
					validationSchema={validationSchema}
					onSubmit={async ({ email }, { setSubmitting }) => {
						setSubmitting(false);
						try {
							await requestNewPassword(email);
							showNotification('Email sent successfully!', 'success');
						} catch (error) {
							console.log(error);
							showNotification('Failed to send email. Please try again later.', 'error');
						}
					}}
				>
					{({ errors, isSubmitting, setFieldValue }) => (
						<Form className={styles.form_cont}>
							{notification && (
								<div className={`notification ${notification.type}`}>
									{notification.message}
								</div>
							)}
							<p className={styles.form_heading}>Send a confirmation mail to reset your password</p>
							<Input
								type="email"
								label="Email address"
								name="email"
							/>
							<div style={{ marginTop: '20px' }}>
								{Object.keys(errors).length > 0 ? (
									<Button
										variant="ash"
										style={{
											padding: '32px',
											width: '100%',
											margin: 'auto',
											fontWeight: '500',
											fontSize: '16px',
										}}
										type="button"
										disabled
									>
										Continue
									</Button>
								) : (
									<Button
										variant="primary"
										style={{
											padding: '32px',
											width: '100%',
											margin: 'auto',
											fontWeight: '500',
											fontSize: '16px',
										}}
										type="submit"
									>
										{isSubmitting ? <Spin /> : 'Continue'}
									</Button>
								)}
							</div>
						</Form>
					)}
				</Formik>
				<p className={styles.form_sub}>Terms of use Privacy policy</p>
			</div>
		</div>
	);
};

export default ForgotPassword;
