import React, { useState, useContext } from 'react';
import { Button, Table, Space, Checkbox } from 'antd';
import { StatusTag } from "../../molecules"
import { AiOutlineDelete } from "react-icons/ai"
import { MdOutlineCloudDownload } from "react-icons/md"
import styles from "./pct.module.css"
import { Edit } from "../../svg";
import type { ColumnsType } from "antd/es/table"
import type { CheckboxChangeEvent } from "antd/es/checkbox"
import { useNavigate } from "react-router-dom"
import Pagination from "../Pagination"
import { Props as PaginationProps } from "../Pagination"
import ConfirmModal from "../Modal/ConfirmModal"
import DeleteModal from "../Modal/DeleteModal"
import { promocodesHeaders } from "../../utils"
import { CSVLink } from "react-csv"
import UserContext from '../../contexts/UserContext';
import UnauthorizedModal from '../Modal/UnauthorizedModal';

export interface Props{ 
  key: any; 
  name:string | any;
  endDate: string | any;
  numberOfUsage: number | any;
  status: any ;
  consultationType: any;
  percentage: string | any;
} 

interface PromocodesTableProps{
  paginationDetails: PaginationProps['paginationDetails'],
  onCurrentPage: PaginationProps['onCurrentPage'],
  tableLoading: boolean,
  reload:boolean,
  setReload:any,
  data: Props[],
}

const PromocodesTable = ({data,reload, setReload, onCurrentPage, paginationDetails, tableLoading}:PromocodesTableProps) => {
  const navigate = useNavigate()  
  const { user } = useContext(UserContext)
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked ] = useState(true)
  const [ isModalVisible, setIsModalVisible ] = useState<boolean>(false)
  const [ isDeleteModalVisible, setIsDeleteModalVisible ] = useState<boolean>(false)
  const [ isUnauthorizedModalVisible, setIsUnauthorizedModalVisible] = useState(false)
  const [ ids, setIds ] = useState<string[]>([])

  const columns: ColumnsType<Props> = [
  {
    title: 'PROMO NAME',
    dataIndex: 'name',
    key: 'name',
    width:"20%",
    render: (_, {name, key}) => (
      <p 
        onClick={()=> navigate(`/dashboard/promocodes/${key}`)} 
        style={{
          cursor:"pointer", 
          textTransform:"uppercase"
        }}
      >{name}</p>
    ),
  },
  {
    title: 'CONSULTATION',
    dataIndex: 'consultationType',
    key: 'consultationType',
    width:"15%",
  },
  {
    title: 'USAGE',
    dataIndex: 'numberOfUsage',
    key: 'numberofUsage',
    width:"10%",
  },
  {
    title: 'PERCENTAGE',
    dataIndex: 'percentage',
    key: 'percentage',
    width:"14%",
  },
  {
    title: 'END DATE',
    dataIndex: 'endDate',
    key: 'endDate',
    width:"15%",
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    width:"12%",
    render: (_, {status}) => (
      <Space size="middle">
        <StatusTag status={status} />
      </Space>
    ),
  }, 
  {
    title: 'ACTION',
    dataIndex: 'action',
    key: 'action',
    width:"12%",
    render: (_, {key}) => (
      <Space size="middle">
        <Edit 
          width="20px" 
          height="20px" 
          style={{cursor:"pointer"}} 
          onClick={()=> navigate(`/dashboard/promocodes/${key}/edit`)}
        />
        <AiOutlineDelete 
          style={{
            color:"#FD4C06", 
            fontSize:"20px", 
            cursor:"pointer"
          }} 
          onClick={()=> {
            if(user?.permissions?.includes("PROMO_CODE_ADMIN.DELETE.ALL")){
                setIsModalVisible(true)
                setIds([key])
            } else{
                setIsUnauthorizedModalVisible(true)
            }
          }}
        />
      </Space>
    ),
  }
];

const handleShowDelete = () => {
  setIsModalVisible(false);
  setIsDeleteModalVisible(true)
}
  
  

  const start = () => {
    setLoading(true); // ajax request after empty completing

    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked)
    setSelectedRowKeys([])

  }

  const selectedData = () => {
    let arr = [] as Props[]
    for(let i=0; i<selectedRowKeys.length; i++){
      const r = data.filter(x => x.key === selectedRowKeys[i])
      arr= arr.concat(r)
    }
    return arr
  }

  return (
    <div>
     { hasSelected ?  <div
        style={{
          marginBottom: 16,
          display:"flex",
          alignItems:"center"
        }}
      >
        <Button onClick={start} loading={loading} className={styles.topBtns} >
          <Checkbox checked={checked} onChange={onChange}> 
          {selectedRowKeys.length} promocodes selected

          </Checkbox>
        </Button>
      {/*delete selected providers*/}
        <Button 
          loading={loading} 
          className={styles.topBtns} 
          onClick={()=> {
            if(user?.permissions?.includes("PROMO_CODE_ADMIN.DELETE.ALL")){
                setIsModalVisible(true)
                setIds(selectedRowKeys)
            } else{
                setIsUnauthorizedModalVisible(true)
            }
          }}
        >
          <AiOutlineDelete style={{color:"#1f1f1f", fontSize:"20px"}}/>
        </Button>

      {/*download selected providers*/}
        <CSVLink data={selectedData()} filename={"Dokitari Promocodes"} headers={promocodesHeaders}>
          <Button  loading={loading} className={styles.topBtns}>
            <MdOutlineCloudDownload style={{color:"#1f1f1f", fontSize:"20px"}}/>
          </Button>
        </CSVLink>
      </div> :null }
      <div style={{padding:"0px 10px 20px 10px", borderRadius:"15px", backgroundColor:"#ffffff"}}>
        <Table 
            rowSelection={rowSelection} 
            columns={columns} 
            dataSource={data} 
            className={styles.adminTable} 
            pagination={false} 
            scroll={{x:"max-content", y:"100%"}}
            loading={tableLoading}
        />

        <Pagination paginationDetails={paginationDetails} onCurrentPage={onCurrentPage}/>
        {/*confirm modal*/}
        <ConfirmModal 
          isModalVisible={isModalVisible} 
          setIsModalVisible={setIsModalVisible} 
          action={handleShowDelete}
          type="promocode"
        />

        {/*delete modal*/}
        <DeleteModal 
          isModalVisible={isDeleteModalVisible} 
          setIsModalVisible={setIsDeleteModalVisible} 
          ids={ids}
          callback={() => setReload(!reload)}
          type="promocode"
        />

        <UnauthorizedModal 
            isModalVisible={isUnauthorizedModalVisible} 
            setIsModalVisible={setIsUnauthorizedModalVisible} 
            action={()=> {}}
        />
      </div>
    </div>

    );
};

export default PromocodesTable;
