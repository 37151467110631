import { useState } from "react"
import {Dropdown, Menu} from "antd";
import { RiEqualizerLine } from 'react-icons/ri';
import RolesFilter from "./RolesFilter";
import { Button } from "../../atoms"
import styles from "./Filter.module.css"
import {useUsersFilter} from "../../contexts/FilterUsersContext"

export default function UsersFilterDropdown({applyFilters, resetFilters}:{applyFilters:any, resetFilters:any}) {
    const [visible, setVisible] = useState<boolean>(false);
    
    const {
        selectedRole,
        setSelectedRole
    } = useUsersFilter();

    const userRoles = ["ADMIN","SEARCH_REVIEWS_ADMIN","SEARCH_PROVIDERS_ADMIN","DOCTOR_ADMIN","HOSPITAL_ADMIN","PROMO_CODE_ADMIN","SUPER_ADMIN"]

    const filter = (
        <Menu className={styles.filter_menu}>
            <Button 
                variant="ghost" 
                onClick={resetFilters}
                style={{
                  fontSize:"16px",
                  fontWeight:"500",
                  margin:"0px 20px 0px auto",
                  height:"auto"
                }}
            >Reset</Button>

            <RolesFilter types={userRoles} selectedRole={selectedRole} setSelectedRole={setSelectedRole}/>
            <Button 
                style={{
                    borderRadius:"5px",
                    width:"80%",
                    margin:"16px auto 0px auto",
                    padding:"10px 0px",
                    fontSize:"16px",
                    fontWeight:"500"
                }} 
                onClick={()=> {
                    applyFilters()
                    setVisible(false)
                }}
            >Apply filter</Button>
        </Menu>
    )


    return (
        <div className={"select_filter"}>
            <Dropdown overlay={filter} placement={"bottomRight"} arrow trigger={['click']}
                      onVisibleChange={(r) => setVisible(r)} visible={visible}>
                <p className={styles.filter}><RiEqualizerLine style={{fontSize:"32px"}}/> &nbsp; Filter</p>
            </Dropdown>
        </div>

    )
}